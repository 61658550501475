"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcEconomicsSwansonsMean = exports.calcCashflowMetricProperties = void 0;
var R = __importStar(require("ramda"));
var arrayUtil_1 = require("./arrayUtil");
var calcSwansonsMean_1 = require("./calcSwansonsMean");
var createResultsObject_1 = require("./createResultsObject");
var cumSum_1 = require("./cumSum");
var trimToEconomicLife_1 = require("./trimToEconomicLife");
var calcCashflowMetricProperties = function (cashflow) { return (__assign(__assign({}, cashflow), { yearNumber: (0, arrayUtil_1.createYearArray)(cashflow.boeNet.length) })); };
exports.calcCashflowMetricProperties = calcCashflowMetricProperties;
var calcEconomicsSwansonsMean = function (configMaxYears, major, p90Cashflow, p50Cashflow, p10Cashflow) {
    var swansonsMeanCashflowRaw = R.compose(
    // addDailyAndCumSumPropsToCashflow, // KTE, 10/8/2022, commented out b/c daily and cum sum props are calculated later in createResultsObject // cum sums need to be re-calculated after blending cases
    // addCumSumPropsToCashflow, // cum sums need to be re-calculated after blending cases
    exports.calcCashflowMetricProperties // items like yearNumber need to be recalculated after blending cases
    )((0, calcSwansonsMean_1.calcSwansonsMeanForCashflowObject)(p90Cashflow, p50Cashflow, p10Cashflow));
    // objectAddWithDefault(0, objectMultiplyScalarWithDefault(0, 0.3, p10Cashflow)),
    // objectAddWithDefault(0, objectMultiplyScalarWithDefault(0, 0.4, p50Cashflow))
    // )(objectMultiplyScalarWithDefault(0, 0.3, p90Cashflow))
    return (0, createResultsObject_1.createResultsObject)(major, (0, trimToEconomicLife_1.trimToEconomicLife)(configMaxYears, (0, cumSum_1.cumSum)(swansonsMeanCashflowRaw.netOperatingIncome), swansonsMeanCashflowRaw.capitalGross, swansonsMeanCashflowRaw));
};
exports.calcEconomicsSwansonsMean = calcEconomicsSwansonsMean;
