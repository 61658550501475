"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.vMultiply = void 0;
var vOperator_1 = require("./vOperator");
var R = __importStar(require("ramda"));
/**
 * Vectorized Multiply
 *
 * Returns an array that contains the element-wise product of the two input arrays
 * (or array and scalar value).
 *
 * Keith Elliott, 12/13/2019, updated 7/6/2022
 *
 * @func
 * @param {Numeric Array} x An array or scalar value
 * @param {Numeric Array or Number} y A second array or scalar value
 * @returns {Numeric Array} An array containing the element-wise product of the x & y values
 * @seeAlso vOperator
 *
 * @example
 *
 *   vMultiply([1,2], [3,4]);  //=> [3,8]
 *   vMultiply([1,2], 2);  //=> [2,4]
 */
exports.vMultiply = (0, vOperator_1.vOperator)(R.multiply);
