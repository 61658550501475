"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareCashflowInput = exports.shapeRawCashflowParameters = exports.prepareCapital = exports.prepareOpex = exports.preparePrices = exports.prepareOwnership = exports.prepareTax = void 0;
var arrayUtil_1 = require("./arrayUtil");
var R = __importStar(require("ramda"));
var prepareProduction_1 = require("./prepareProduction");
var shiftDelayStart_1 = require("./shiftDelayStart");
var vAdd_1 = require("./vAdd");
var prepareTax = function (configMaxYears, _a) {
    var adValTaxRatePercent = _a.adValTaxRatePercent, gasSevTaxRatePercent = _a.gasSevTaxRatePercent, gasProdTaxRate = _a.gasProdTaxRate, oilSevTaxRatePercent = _a.oilSevTaxRatePercent, oilProdTaxRate = _a.oilProdTaxRate, nglSevTaxRatePercent = _a.nglSevTaxRatePercent, nglProdTaxRate = _a.nglProdTaxRate;
    return ({
        adValTaxRatePercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(adValTaxRatePercent),
        gasSevTaxRatePercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(gasSevTaxRatePercent),
        gasProdTaxRate: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(gasProdTaxRate),
        oilSevTaxRatePercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(oilSevTaxRatePercent),
        oilProdTaxRate: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(oilProdTaxRate),
        nglSevTaxRatePercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(nglSevTaxRatePercent),
        nglProdTaxRate: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(nglProdTaxRate),
    });
};
exports.prepareTax = prepareTax;
var prepareOwnership = function (configMaxYears, _a) {
    var workingInterestPercent = _a.workingInterestPercent, netRevenueInterestPercent = _a.netRevenueInterestPercent;
    return ({
        workingInterestPercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(workingInterestPercent),
        netRevenueInterestPercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(netRevenueInterestPercent),
    });
};
exports.prepareOwnership = prepareOwnership;
var preparePrices = function (configMaxYears, _a, _b) {
    var priceBaseOil = _a.priceBaseOil, priceBaseGas = _a.priceBaseGas;
    var nglPricePercent = _b.nglPricePercent, priceDiffOil = _b.priceDiffOil, priceDiffGas = _b.priceDiffGas;
    return ({
        priceBaseOil: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(priceBaseOil),
        priceBaseGas: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(priceBaseGas),
        nglPricePercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(nglPricePercent),
        priceDiffOil: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(priceDiffOil),
        priceDiffGas: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(priceDiffGas),
    });
};
exports.preparePrices = preparePrices;
/**
 * Logic Note:  opexPerWellGross an overheadGross are only applied if production volume is not zero.
 *
 * Therefore, in dry hole cases, no OPEX is ever applied.
 *
 * @param configMaxYears
 * @param oilGross Array of oilGross produced by prepareProduction.  Used to determine if
 * opexPerWellGross and overheadGross should be applied (no prod, no opex or overhead).
 * @param gasGross See above note for oilGross.  At this point in the code, I don't know the Major
 * phase.  I don't need to know.  I just need to know if either oilGross or gasGross are not zero.
 * The major phase and associated products should both be defined; I simply sum them together to check
 * for the presence of production by month.
 * @param opexPerWellGross $/month gross fixed per well opex
 * @param overheadGross $/month gross overhead
 * @param opexVariableOilGross Metric representing $/BO opex
 * @param opexVariableGasGross Metric representing $/MCF opex
 * @author Keith Elliott, 9/16/2020
 */
var prepareOpex = function (configMaxYears, oilGross, gasGross, shrinkPercent, _a) {
    var opexPerWellGross = _a.opexPerWellGross, opexVariableOilGross = _a.opexVariableOilGross, opexVariableGasGross = _a.opexVariableGasGross, overheadGross = _a.overheadGross;
    return {
        opexPerWellGross: R.compose((0, arrayUtil_1.replaceWhenZeroOrUndefined)(0)((0, vAdd_1.vAdd)(oilGross)(gasGross)), // I add oil and gas together, just to get a non-zero number to show production exists (or not) by month
        (0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(opexPerWellGross),
        overheadGross: R.compose((0, arrayUtil_1.replaceWhenZeroOrUndefined)(0)((0, vAdd_1.vAdd)(oilGross)(gasGross)), // I add oil and gas together, just to get a non-zero number to show production exists (or not) by month
        (0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(overheadGross),
        opexVariableOilGross: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(opexVariableOilGross),
        opexVariableGasGross: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(opexVariableGasGross),
        shrinkPercent: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(shrinkPercent),
        // opexYearlyExpenseAFEGross/12), // annual value converted to monthly // KTE, 9/9/2020:  I decided to leave the above out of the refactored version.  Not needed.
    };
};
exports.prepareOpex = prepareOpex;
/**
 * Adds zeros to the provided capitalGross array, to fill the array to the max years of analysis.
 *
 * @param {Number} maxLifeYears A number representing the max life used for economic analysis
 * @param {Number[]} capitalGross An array containing the project capital that will be used in the
 * economic analysis
 * @returns {Object} An object containing a capitalGross property.  The value is equal to the array
 * that is created.  I expect that this result will be passed into another function that continues
 * to build up a cashflow object.
 */
var prepareCapital = function (maxLifeYears, capitalGross) { return ({
    capitalGross: (0, arrayUtil_1.appendZerosToMaxLifeYearsIfArray)(maxLifeYears)(capitalGross),
}); };
exports.prepareCapital = prepareCapital;
var shapeRawCashflowParameters = function (priceBaseOil, priceBaseGas, projectInput, caseInput) { return ({
    delayStart: caseInput.delayStart,
    shrinkPercent: projectInput.shrinkPercent,
    prodMajorPhaseParameters: shapeProdMajorPhaseParameters(projectInput.major, caseInput.nglYield, caseInput.gasOilRatio, caseInput.condensateYield),
    prodForecastParameters: shapeProdForecastParameters(caseInput.prodForecastMethod, caseInput.initialDailyRate, caseInput.finalDailyRate, caseInput.annualEffectiveDeclineRatePercent, caseInput.reserves, caseInput.hyperbolicExponent, caseInput.maxLifeYears, caseInput.customProdForecast, caseInput.terminalAnnualEffectiveDeclineRatePercent),
    ownershipParams: {
        netRevenueInterestPercent: projectInput.netRevenueInterestPercent,
        workingInterestPercent: projectInput.workingInterestPercent,
    },
    taxParams: {
        adValTaxRatePercent: projectInput.adValTaxRatePercent,
        gasProdTaxRate: projectInput.gasProdTaxRate,
        gasSevTaxRatePercent: projectInput.gasSevTaxRatePercent,
        nglProdTaxRate: projectInput.nglProdTaxRate,
        nglSevTaxRatePercent: projectInput.nglSevTaxRatePercent,
        oilProdTaxRate: projectInput.oilProdTaxRate,
        oilSevTaxRatePercent: projectInput.oilSevTaxRatePercent,
    },
    priceBase: {
        priceBaseOil: priceBaseOil,
        priceBaseGas: priceBaseGas,
    },
    priceProjectParams: {
        nglPricePercent: projectInput.nglPricePercent,
        priceDiffOil: projectInput.priceDiffOil,
        priceDiffGas: projectInput.priceDiffGas,
    },
    opexParameters: {
        // do I need to shape this for gas vs oil????
        opexPerWellGross: caseInput.opexPerWellGross,
        opexVariableGasGross: caseInput.opexVariableGasGross,
        opexVariableOilGross: caseInput.opexVariableOilGross,
        overheadGross: caseInput.overheadGross,
    },
    cashflowInputCapital: {
        capitalGross: caseInput.capitalGross,
    },
}); };
exports.shapeRawCashflowParameters = shapeRawCashflowParameters;
var shapeProdMajorPhaseParameters = function (major, nglYield, gasOilRatio, condensateYield) {
    if (major === 'Oil' && gasOilRatio !== undefined) {
        return { major: major, nglYield: nglYield, gasOilRatio: gasOilRatio };
    }
    else if (major === 'Gas' && condensateYield !== undefined) {
        return { major: major, nglYield: nglYield, condensateYield: condensateYield };
    }
    else {
        throw new Error("Invalid major phase production input.\n        Major should equal 'Oil' or 'Gas'.\n        nglYield must always be defined.\n        If Oil, then gasOilRatio must be defined.\n        If Gas, then condensateYield must be defined.\n        The provided input follows:\n          major:  ".concat(major, "\n          nglYield: ").concat(nglYield, "\n          gasOilRatio: ").concat(gasOilRatio, ",\n          condensateYield:  ").concat(condensateYield));
    }
};
var shapeProdForecastParameters = function (prodForecastMethod, initialDailyRate, finalDailyRate, annualEffectiveDeclineRatePercent, reserves, hyperbolicExponent, maxLifeYears, customProdForecast, terminalAnnualEffectiveDeclineRatePercent) {
    if (prodForecastMethod === 'Exponential: Calc Reserves and Final Rate' &&
        initialDailyRate !== undefined &&
        annualEffectiveDeclineRatePercent !== undefined &&
        maxLifeYears !== undefined) {
        return {
            prodForecastMethod: prodForecastMethod,
            initialDailyRate: initialDailyRate,
            annualEffectiveDeclineRatePercent: annualEffectiveDeclineRatePercent,
            maxLifeYears: maxLifeYears,
        };
    }
    else if (prodForecastMethod === 'Exponential: Calc Decline and Life' &&
        initialDailyRate !== undefined &&
        finalDailyRate !== undefined &&
        reserves !== undefined) {
        return {
            prodForecastMethod: prodForecastMethod,
            initialDailyRate: initialDailyRate,
            finalDailyRate: finalDailyRate,
            reserves: reserves,
        };
    }
    else if (prodForecastMethod === 'Hyperbolic: Calc Reserves and Final Rate' &&
        initialDailyRate !== undefined &&
        annualEffectiveDeclineRatePercent !== undefined &&
        hyperbolicExponent !== undefined &&
        maxLifeYears !== undefined) {
        return {
            prodForecastMethod: prodForecastMethod,
            initialDailyRate: initialDailyRate,
            annualEffectiveDeclineRatePercent: annualEffectiveDeclineRatePercent,
            hyperbolicExponent: hyperbolicExponent,
            maxLifeYears: maxLifeYears,
        };
    }
    else if (prodForecastMethod === 'Custom Major Phase Forecast' &&
        customProdForecast !== undefined) {
        return {
            prodForecastMethod: prodForecastMethod,
            customProdForecast: customProdForecast,
        };
    }
    else if (prodForecastMethod ===
        'Hyperbolic to Exponential: Calc Reserves and Final Rate' &&
        initialDailyRate !== undefined &&
        annualEffectiveDeclineRatePercent !== undefined &&
        hyperbolicExponent !== undefined &&
        terminalAnnualEffectiveDeclineRatePercent !== undefined &&
        maxLifeYears !== undefined) {
        return {
            prodForecastMethod: prodForecastMethod,
            initialDailyRate: initialDailyRate,
            annualEffectiveDeclineRatePercent: annualEffectiveDeclineRatePercent,
            hyperbolicExponent: hyperbolicExponent,
            terminalAnnualEffectiveDeclineRatePercent: terminalAnnualEffectiveDeclineRatePercent,
            maxLifeYears: maxLifeYears,
        };
    }
    else {
        throw new Error("Invalid production forecast input.\n    prodForecastMethod should equal one of\n      'Exponential: Calc Reserves and Final Rate' or\n      'Exponential: Calc Decline and Life' or\n      'Hyperbolic: Calc Reserves and Final Rate' or\n      'Custom Major Phase Forecast' or\n      'Hyperbolic to Exponential: Calc Reserves and Final Rate'.\n    The input parameters vary for each method.  See the\n    ProdForecastMethod type description for details.\n    The input provided follows:\n      prodForecastMethod:  ".concat(prodForecastMethod, ",\n      initialDailyRate:  ").concat(initialDailyRate, ",\n      finalDailyRate:  ").concat(finalDailyRate, ",\n      annualEffectiveDeclineRatePercent:  ").concat(annualEffectiveDeclineRatePercent, ",\n      reserves:  ").concat(reserves, ",\n      hyperbolicExponent:  ").concat(hyperbolicExponent, ",\n      maxLifeYears:  ").concat(maxLifeYears, ",\n      customProdForecast:  ").concat(customProdForecast, ",\n      terminalAnnualEffectiveDeclineRatePercent: ").concat(terminalAnnualEffectiveDeclineRatePercent));
    }
};
var prepareCashflowInput = function (configMaxYears, configStandardProdShift, rawCashflowParameters
// {
//   priceBaseOil,
//   priceBaseGas,
//   priceDiffOil,
//   priceDiffGas,
//   nglPricePercent,
//   workingInterestPercent,
//   netRevenueInterestPercent,
//   capitalGross,
//   delayStart,
//   major,
//   prodForecastMethod,
//   initialDailyRate,
//   finalDailyRate,
//   annualEffectiveDeclineRatePercent,
//   hyperbolicExponent,
//   reserves,
//   gasOilRatio,
//   condensateYield,
//   nglYield,
//   customProdForecast,
//   maxLifeYears,
//   shrinkPercent,
//   adValTaxRatePercent,
//   gasSevTaxRatePercent,
//   gasProdTaxRate,
//   oilSevTaxRatePercent,
//   oilProdTaxRate,
//   nglSevTaxRatePercent,
//   nglProdTaxRate,
//   opexPerWellGross,
//   opexVariableOilGross,
//   opexVariableGasGross,
//   overheadGross,
// }: CashflowRawParametersAll
) {
    var productionObject = (0, shiftDelayStart_1.shiftDelayStart)(rawCashflowParameters.delayStart + configStandardProdShift, // In addition to the user entered shift, HEConomics applies a standard shift to the start of production
    // prepareProduction(
    //   configMaxYears,
    //   prodMajorPhaseParameters,
    //   prodForecastParameters
    // )
    (0, prepareProduction_1.prepareProduction)(configMaxYears, rawCashflowParameters.prodMajorPhaseParameters, rawCashflowParameters.prodForecastParameters));
    // shapeProdObject(
    //   rawCashflowParameters.major,
    //   rawCashflowParameters.nglYield,
    //   rawCashflowParameters.major === 'Oil'
    //     ? rawCashflowParameters.gasOilRatio
    //     : undefined,
    //   rawCashflowParameters.major === 'Gas'
    //     ? rawCashflowParameters.condensateYield
    //     : undefined
    // ),
    // {
    //   major: rawCashflowParameters.major,
    //   condensateYield:
    //     rawCashflowParameters.major === 'Gas'
    //       ? rawCashflowParameters?.condensateYield
    //       : undefined,
    //   gasOilRatio: rawCashflowParameters?.condensateYield,
    //   nglYield: rawCashflowParameters.nglYield,
    // },
    // {
    //   prodForecastMethod,
    //   initialDailyRate,
    //   annualEffectiveDeclineRatePercent,
    //   hyperbolicExponent,
    //   finalDailyRate,
    //   maxLifeYears,
    //   reserves,
    //   customProdForecast,
    // }
    // prepareProduction(configMaxYears,
    //   {
    //   major,
    //   prodForecastMethod,
    //   initialDailyRate,
    //   finalDailyRate,
    //   annualEffectiveDeclineRatePercent,
    //   hyperbolicExponent,
    //   reserves,
    //   gasOilRatio,
    //   condensateYield,
    //   nglYield,
    //   customProdForecast,
    //   maxLifeYears,
    // })
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (0, exports.preparePrices)(configMaxYears, rawCashflowParameters.priceBase, rawCashflowParameters.priceProjectParams)), (0, exports.prepareOwnership)(configMaxYears, rawCashflowParameters.ownershipParams)), (0, shiftDelayStart_1.shiftDelayStart)(rawCashflowParameters.delayStart, (0, exports.prepareCapital)(configMaxYears, rawCashflowParameters.cashflowInputCapital.capitalGross // KTE Note, 9/18/2022:  refactor this, the capital prob is already in the correct shape
    ))), productionObject), (0, exports.prepareTax)(configMaxYears, rawCashflowParameters.taxParams)), (0, exports.prepareOpex)(configMaxYears, productionObject['oilGross'], productionObject['gasGross'], rawCashflowParameters.shrinkPercent, rawCashflowParameters.opexParameters
    // {
    //   opexPerWellGross,
    //   opexVariableOilGross,
    //   opexVariableGasGross,
    //   overheadGross,
    //   shrinkPercent,
    // }
    ));
};
exports.prepareCashflowInput = prepareCashflowInput;
