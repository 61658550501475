"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.round0UndefinedNaCheck = exports.round1UndefinedNaCheck = exports.round3 = exports.round2 = exports.round1 = exports.round0 = exports.round = void 0;
var R = __importStar(require("ramda"));
function round(decimals) {
    return function (value) {
        if (Array.isArray(value)) {
            return R.map(round(decimals))(value);
        }
        else {
            // if (!isNumeric(value))
            //   throw new Error(`A number must be provided to the round function.
            //   However, ${value} was provided.`)
            // KTE, 10/8/2022:  deprecated the above!
            if (!isFinite(value))
                return value; // NaN, Infinity and -Infinity are simply returned as-is
            return Number("".concat(Math.round("".concat(Number(value).toString(), "e").concat(Number(decimals).toString())), "e-").concat(Number(decimals).toString()));
            // return isNumeric(value)
            //   ? Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)
            //   : value
            // Go-Do, KTE, 9/26/2022:  Consider how the change I made while converting to TS
            //   will affect the program.  Do I ever pass non-numbers?  If so, now I will
            //   get an error (instead of the same value simply being returned)
        }
    };
}
exports.round = round;
exports.round0 = round(0);
exports.round1 = round(1);
exports.round2 = round(2);
exports.round3 = round(3);
var roundUndefinedNaCheck = function (roundFunction) {
    return function (x) {
        return R.ifElse(function (x) {
            return x === undefined || x === 'NA' || x === '>300%';
        }, function (x) { return x; }, roundFunction)(x);
    };
};
exports.round1UndefinedNaCheck = roundUndefinedNaCheck(exports.round1);
exports.round0UndefinedNaCheck = roundUndefinedNaCheck(exports.round0);
// export const round1UndefinedNaCheck = (
//   x: number | undefined | 'NA' | '>300%'
// ) =>
//   R.ifElse<
//     undefined | 'NA' | '>300%' | number,
//     undefined | 'NA' | '>300%',
//     undefined | 'NA' | '>300%',
//     number
//   >(
//     (x): x is undefined | 'NA' =>
//       x === undefined || x === 'NA' || x === '>300%',
//     (x) => x,
//     round1
//   )(x)
