/**
 * Heconomics standard UI input units are MBO for oil reserves.  cashflowjs standard input units are
 * BO for oil reserves.
 */
const convHeconomcsToCashflowReservesInputOil = (reserves: number) =>
  reserves * 1000

/**
 * Heconomics standard UI input units are BCF for gas reserves.  cashflowjs standard input units are
 * MCF for gas reserves.
 */
const convHeconomcsToCashflowReservesInputGas = (reserves: number) =>
  reserves * 1000000

const checkPhaseThenConvert = (major: 'Oil' | 'Gas', reserves) => {
  if (typeof reserves !== 'number') return reserves

  return major === 'Oil'
    ? convHeconomcsToCashflowReservesInputOil(reserves)
    : convHeconomcsToCashflowReservesInputGas(reserves)
}

export const convertHeconomicsReservesToCashflowReserves = (
  major: 'Oil' | 'Gas',
  caseInput: {
    p90: { reserves: any }
    p50: { reserves: any }
    p10: { reserves: any }
    failure: { reserves: any }
    existing: { reserves: any }
  }
) => ({
  p90: {
    ...caseInput.p90,
    reserves: checkPhaseThenConvert(major, caseInput.p90.reserves),
  },
  p50: {
    ...caseInput.p50,
    reserves: checkPhaseThenConvert(major, caseInput.p50.reserves),
  },
  p10: {
    ...caseInput.p10,
    reserves: checkPhaseThenConvert(major, caseInput.p10.reserves),
  },
  failure: {
    ...caseInput.failure,
    reserves: checkPhaseThenConvert(major, caseInput.failure.reserves),
  },
  existing: {
    ...caseInput.existing,
    reserves: checkPhaseThenConvert(major, caseInput.existing.reserves),
  },
})
