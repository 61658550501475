"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcRateHyperbolic = void 0;
function calcRateHyperbolic(initialRate, nominalDeclineRate, b, month) {
    if (b <= 0) {
        throw new Error("Invalid input. The hyperbolic exponent, b, must be greater than zero.\n      The provided value equals ".concat(b));
    }
    if (Array.isArray(month)) {
        return month.map(function (monthElement) { return calcRateHyperbolic(initialRate, nominalDeclineRate, b, monthElement); });
    }
    return initialRate / Math.pow((1 + (b * nominalDeclineRate * month) / 12), (1 / b));
}
exports.calcRateHyperbolic = calcRateHyperbolic;
