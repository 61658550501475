"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prependZerosChop = exports.prependChop = exports.replaceIfNotZero = exports.replaceWhenZeroOrUndefined = exports.appendZerosToMaxLifeYearsForObjectOfArrays = exports.appendLastValueToMaxLifeYearsIfArray = exports.appendZerosToMaxLifeYearsIfArray = exports.appendLastValueIfArray = exports.appendValuesIfArray = exports.appendLastValue = exports.appendValues = exports.convertScalarToConstantValueArray = exports.convertToArray = exports.createYearArray = exports.findLastNonZeroIndex = exports.findMaxCumIndex = exports.trimObjectOfArraysToIndex = exports.trimArrayToIndex = void 0;
var R = __importStar(require("ramda"));
var vMultiply_1 = require("./vMultiply");
/**
 * @param {number} index The index to which the array will be trimmed.
 * @param {any[]} x The array to be trimmed.
 *   NOTE!  I changed the function on 7/20/2022.  Now I only accept an any[].  I don't
 *   think that I use the prior feature of returning the input if it's not an array - but
 *   I'll need to look out for this.
 * @example
 *
 *   trimArrayToIndex(2)([10, 11, 12, 13]) //=> [10, 11, 12]
 *   trimArrayToIndex(2)(1) //=> type bust!  this is not allowed
 */
var trimArrayToIndex = function (index) {
    return function (x) {
        // if (!Array.isArray(x)) return x // Changed this on 7/20/2022.  Now I only accept arrays
        return R.slice(0, index + 1, x);
        // slice's first argument is inclusive and second is exclusive (hence index + 1)
    };
};
exports.trimArrayToIndex = trimArrayToIndex;
var trimObjectOfArraysToIndex = function (objectOfArrays) { return function (index) {
    return R.map((0, exports.trimArrayToIndex)(index), objectOfArrays);
}; };
exports.trimObjectOfArraysToIndex = trimObjectOfArraysToIndex;
// export const trimObjectOfArraysToIndex = (objectOfArrays: {[name: string | number]: any[]}) => (index: number) => {
//   // R.map<
//   return R.map<{[name: string | number]: number[]}, {[name: string | number]: number[]}>(trimArrayToIndex(index), objectOfArrays)
// }
var findMaxCumIndex = function (cumArrayForMaxCheck) {
    return R.indexOf(R.reduce(R.max, -Infinity, cumArrayForMaxCheck), cumArrayForMaxCheck);
};
exports.findMaxCumIndex = findMaxCumIndex;
exports.findLastNonZeroIndex = R.findLastIndex(function (x) { return x != 0; });
var createYearArray = function (lengthInMonths) {
    return (0, vMultiply_1.vMultiply)(R.range(0, lengthInMonths))(1 / 12);
};
exports.createYearArray = createYearArray;
/**
 * Converts the argument to a one-element array.
 *
 * Returns the argument unchanged if it is already an array.
 *
 * @sig [x] | x -> [x]
 */
var convertToArray = function (x) {
    return !Array.isArray(x) ? [x] : x;
};
exports.convertToArray = convertToArray;
var convertScalarToConstantValueArray = function (desiredLength) { return function (x) {
    return Array.isArray(x) ? x : R.repeat(x, desiredLength);
}; };
exports.convertScalarToConstantValueArray = convertScalarToConstantValueArray;
/**
 * Appends values to an array.
 *
 * @param {any} appendValue The new value that will be added to the array
 * @param {Number} numTimesToAppend
 * @param {any[]} x The array that will be appended
 * @returns {any[]} A new array composed of the original array plus appendValue added to it numTimesToAppend times
 * @sig appendValue -> numTimesToAppend -> [x] -> [x]
 * @example
 *
 *   appendValue(0, 10, [100000]);  //=> [100000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
 */
var appendValues = function (appendValue) {
    return function (numTimesToAppend, x) {
        return R.concat(x, R.repeat(appendValue, numTimesToAppend));
    };
};
exports.appendValues = appendValues;
/**
 * Extends an array by appending the last value a certain number of times.
 *
 * @param {Number} numTimesToAppend
 * @param {any[]} x The array that will be appended
 * @returns {any[]} A new array composed of the original array plus the last value appended
 * numTimesToAppend times.
 * @sig (numTimesToAppend, [x]) -> [x]
 * @example
 *
 *  appendLastValue(10, [1,2,3]);  //=> [1,2,3,3,3,3,3,3,3,3,3,3,3]
 */
var appendLastValue = function (numTimesToAppend, x) {
    return R.concat(x, R.repeat(x[x.length - 1], numTimesToAppend));
};
exports.appendLastValue = appendLastValue;
var appendValuesIfArray = function (appendValue) { return function (numTimesToAppend, x) {
    if (Array.isArray(x)) {
        return (0, exports.appendValues)(appendValue)(numTimesToAppend, x);
    }
    else {
        return x;
    }
}; };
exports.appendValuesIfArray = appendValuesIfArray;
var appendLastValueIfArray = function (numTimesToAppend, x) {
    if (Array.isArray(x)) {
        return (0, exports.appendLastValue)(numTimesToAppend, x);
    }
    else {
        return x;
    }
};
exports.appendLastValueIfArray = appendLastValueIfArray;
var appendZerosIfArray = (0, exports.appendValuesIfArray)(0);
var appendZerosToMaxLifeYearsIfArray = function (maxLifeYears) { return function (monthlyArray) {
    if (Array.isArray(monthlyArray)) {
        if (monthlyArray.length > maxLifeYears * 12) {
            return R.slice(0, maxLifeYears * 12, monthlyArray);
        }
        else {
            return appendZerosIfArray(maxLifeYears * 12 - monthlyArray.length, monthlyArray);
        }
    }
    else {
        return monthlyArray;
    }
}; };
exports.appendZerosToMaxLifeYearsIfArray = appendZerosToMaxLifeYearsIfArray;
var appendLastValueToMaxLifeYearsIfArray = function (maxLifeYears) { return function (monthlyArray) {
    if (Array.isArray(monthlyArray)) {
        if (monthlyArray.length > maxLifeYears * 12) {
            return R.slice(0, maxLifeYears * 12, monthlyArray);
        }
        else {
            return (0, exports.appendLastValueIfArray)(maxLifeYears * 12 - monthlyArray.length, monthlyArray);
        }
    }
    else {
        return monthlyArray;
    }
}; };
exports.appendLastValueToMaxLifeYearsIfArray = appendLastValueToMaxLifeYearsIfArray;
//const appendZerosToMaxLifeYearsForObjectOfArrays = R.curry((maxLifeYears, objectOfArrays) => R.map(appendZerosToMaxLifeYears(maxLifeYears), objectOfArrays)); // Go-Do, KTE, 9/27/2020:  Delete this after functional testing
var appendZerosToMaxLifeYearsForObjectOfArrays = function (maxLifeYears) { return function (objectOfArrays) {
    return R.map((0, exports.appendZerosToMaxLifeYearsIfArray)(maxLifeYears), objectOfArrays);
}; };
exports.appendZerosToMaxLifeYearsForObjectOfArrays = appendZerosToMaxLifeYearsForObjectOfArrays;
var replaceWhenZeroOrUndefined = function (replacementValue) {
    return function (arrayToCheck) {
        return function (arrayToChange) {
            if (!Array.isArray(arrayToChange) || !Array.isArray(arrayToCheck)) {
                throw new Error("Invalid input.  arrayToChange and arrayToCheck should both be arrays.\n          arrayToChange value:  ".concat(arrayToChange, "\n          arrayToCheck value:  ").concat(arrayToCheck));
            }
            if (arrayToChange.length < arrayToCheck.length) {
                throw new Error("Invalid input.  arrayToChange length should be equal to or longer than the arrayToCheck length.\n          arrayToChange length:  ".concat(arrayToChange.length, "\n          arrayToCheck length:  ").concat(arrayToCheck.length, "\n          arrayToChange value:  ").concat(arrayToChange, "\n          arrayToCheck value:  ").concat(arrayToCheck));
            }
            return R.addIndex(R.map)(function (element, i) {
                return arrayToCheck[i] === 0 || arrayToCheck[i] === undefined
                    ? replacementValue
                    : element;
            }, arrayToChange);
        };
    };
};
exports.replaceWhenZeroOrUndefined = replaceWhenZeroOrUndefined;
/**
 * Replaces the values of an array if they are not zero.
 *
 * @param {any} replacementValue The value that will be used when replacing
 * @param {Number[]} arrayToCheck The input array
 * @returns {any[]} A new array with non-zero values replaced with replacementVaues
 * @sig replacementValue -> [x] -> [x]
 * @example
 *
 *   replaceIfNotZero(1000)([0,0,1,2,3,4,5,0]);  //=> [0,0,1000,1000,1000,1000,1000,0]
 */
var replaceIfNotZero = function (replacementValue) { return function (arrayToCheck) {
    return R.map(function (x) { return (x != 0 ? replacementValue : x); })(arrayToCheck);
}; };
exports.replaceIfNotZero = replaceIfNotZero;
/**
 * Appends an element to an array a certain amount of times, then drops the last
 * n elements off of the new array.  The new returned array length is the same as the
 * original.
 *
 * @param {any} el The new element that wil be prepended
 * @param {Number} n The number of times to append el
 * @param {any[]} xs The array that will have el prepended to it
 * @returns {any[]} A new prepended and chopped array
 * @sig el -> n -> [x] -> [x]
 * @example
 *
 *   prependChop(0, 3, [1, 2, 3, 4, 5, 6]);  //=> [0, 0, 0, 1, 2, 3]
 */
function prependChop(el) {
    return function (n) {
        return function (xs) {
            return R.compose(R.dropLast(n), R.concat(R.repeat(el, n)))(xs);
        };
    };
}
exports.prependChop = prependChop;
exports.prependZerosChop = prependChop(0);
