"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectSubtractWithDefault = void 0;
var vOperatorWithDefault_1 = require("./vOperatorWithDefault");
var R = __importStar(require("ramda"));
/**
 * Maps over object properties and return a new object, with property values equal to arrays containing
 * the element-wise difference of the objectA value less the objectB value.
 *
 * Keith Elliott, July 1, 2020; converted to TypeScript on Dec. 27, 2021
 *
 * @func
 * @param {number} defaultValue If the arrays are different lengths, this default value will be used
 * in the element-wise function, to extend the resulting array to the length of the longer array.
 * @param {Object} objectA An object of numeric arrays
 * @param {Object} objectB Another object of numeric arrays.  It should contain the same property names
 * as objectA.
 * @returns {Object} An object with the same property names.  The property values equal the element-wise
 * difference of the objectA value less the objectB value.
 *
 * @example
 *
 *   objectSubtractWithDefault(0, {a: [1, 2], b: [3, 4]}, a: [10, 9], b: [8, 7]} ) //=> {a: [-9, -7], b: [-5, -3]}
 *   objectSubtractWithDefault(0, {a: [1, 2, 3], b: [3, 4]}, a: [10, 9], b: [8, 7]} ) //=> {a: [-9, -7, 3], b: [-5, -3]}
 */
function objectSubtractWithDefault(defaultValue, objectA, objectB) {
    return R.mapObjIndexed(function (valueB, key) {
        return (0, vOperatorWithDefault_1.vOperatorWithDefault)(R.subtract)(defaultValue)(objectA[key])(valueB);
    }, objectB);
}
exports.objectSubtractWithDefault = objectSubtractWithDefault;
