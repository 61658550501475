// NPM Module Imports
import axios from 'axios'
import moment from 'moment'

// Peer dependency import
import { calcEconomicsProbRisked } from '../../../cashflowjs/dist/calcEconomicsProbRisked'

import { calcTornadoPvr10 } from '../../../cashflowjs/dist/calcTornado'

import { TornadoRow } from '../../../cashflowjs/dist/calcTornado'

import { round1 } from '../../../cashflowjs/dist/round'

import { appendLastValueToMaxLifeYearsIfArray } from '../../../cashflowjs/dist/arrayUtil'

import {
  defaultProjectInputValues,
  CASE_HEADERS_INPUT_ONLY,
  NUMERIC_VALIDATION_REGEX,
  NUMERIC_PROJECT_INPUTS,
  NUMERIC_PERCENTAGE_INPUTS,
  PROJECT_INPUT_FIELDS,
  TAXES_INPUT_FIELDS,
  SPREADSHEET_FIELD_TRANSLATION,
  STANDARD_API_TIMEOUT_MS,
  STANDARD_ERROR_DISPLAY_TIME_MS,
  STANDARD_SUCCESS_DISPLAY_TIME_MS,
  validateCaseInput,
  validatePricingInput,
  validateProjectInput,
  CaseNameType,
  CaseInputNameType,
} from '../utils'

import { PricingInputsType } from '../components/PrintView'
import { number } from 'prop-types'
import { convertHeconomicsReservesToCashflowReserves } from '../utils/convertHeconomicsReservesToCashflowReserves'

const R = require('ramda')

const XLSX = require('xlsx')
const _ = require('lodash')

// create HTTP request client for API Gateway API calls
const instance = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: STANDARD_API_TIMEOUT_MS,
  headers: { 'x-api-key': process.env.GATSBY_API_KEY },
})

// Store user information in memory in Redux store after auth checks
export const updateUser = (user) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_USER',
    payload: user,
  })
}

// For initial app load, let the app know when all auth checks are complete and app can start rendering content to screen
export const completeLoginCheck = () => async (dispatch) => {
  dispatch({ type: 'LOGIN_CHECK_COMPLETED' })
}

// Opens or closes the Admin screen
export const toggleAdmin = () => async (dispatch) => {
  dispatch({ type: 'TOGGLE_ADMIN' })
}

const bySumAscend = R.ascend(R.sum)
const bySumDescend = R.descend(R.sum)

export const createTornadoHelper = (dispatch, major, cases, results) => {
  // Go-Do:  Check for the necessary results before using them.  Or, ensure cashflow calc will handle junk values gracefully

  try {
    const tornadoRowsReserves =
      major === 'Oil' || major === 'oil'
        ? [
            new TornadoRow(
              'Oil Reserves (Gross MBO)',
              'oilGross',
              results.p10.cashflow.oilGross,
              results.p90.cashflow.oilGross,
              results.p50.cashflow.oilGross,
              R.compose(round1, R.divide(R.__, 1000), R.sum) // KTE, 10/5/2020:  Convert from cashflow units to oneline units
            ),
            new TornadoRow(
              'Gas-Oil Ratio (MCF/BO)',
              'gasOilRatio',
              Math.max(
                cases.p90.gasOilRatio,
                cases.p50.gasOilRatio,
                cases.p10.gasOilRatio
              ),
              Math.min(
                cases.p90.gasOilRatio,
                cases.p50.gasOilRatio,
                cases.p10.gasOilRatio
              ),
              cases.p50.gasOilRatio,
              R.identity
            ),
          ]
        : [
            new TornadoRow(
              'Gas Reserves (Gross BCF)',
              'gasGross',
              results.p10.cashflow.gasGross,
              results.p90.cashflow.gasGross,
              results.p50.cashflow.gasGross,
              R.compose(round1, R.divide(R.__, 1000000), R.sum) // KTE, 10/5/2020:  Convert from cashflow units to oneline units
            ),
            new TornadoRow(
              'Condensate Yield (BO/MMCF)',
              'condensateYield',
              Math.max(
                cases.p90.condensateYield,
                cases.p50.condensateYield,
                cases.p10.condensateYield
              ),
              Math.min(
                cases.p90.condensateYield,
                cases.p50.condensateYield,
                cases.p10.condensateYield
              ),
              cases.p50.condensateYield,
              R.identity
            ),
          ]

    const tornado = calcTornadoPvr10(
      major,
      results.p50.cashflow,
      R.concat(tornadoRowsReserves, [
        new TornadoRow(
          'Investment (Gross $)',
          'capitalGross',
          R.sort(bySumAscend, [
            results.p90.cashflow.capitalGross, // need to pull from results, b/c delayStart may have shifted the array
            results.p50.cashflow.capitalGross,
            results.p10.cashflow.capitalGross,
          ])[0],
          R.sort(bySumDescend, [
            results.p90.cashflow.capitalGross, // need to pull from results, b/c delayStart may have shifted the array
            results.p50.cashflow.capitalGross,
            results.p10.cashflow.capitalGross,
          ])[0],
          results.p50.cashflow.capitalGross,
          R.sum
        ),
        new TornadoRow(
          'NGL Yield (BBL/MMCF)',
          'nglYield',
          Math.max(cases.p90.nglYield, cases.p50.nglYield, cases.p10.nglYield),
          Math.min(cases.p90.nglYield, cases.p50.nglYield, cases.p10.nglYield),
          cases.p50.nglYield,
          R.identity
        ),
        new TornadoRow(
          'Fixed OPEX (Gross $/month)',
          'opexPerWellGross',
          Math.min(
            cases.p90.opexPerWellGross,
            cases.p50.opexPerWellGross,
            cases.p10.opexPerWellGross
          ),
          Math.max(
            cases.p90.opexPerWellGross,
            cases.p50.opexPerWellGross,
            cases.p10.opexPerWellGross
          ),
          cases.p50.opexPerWellGross,
          R.identity
        ),
        new TornadoRow(
          'Variable Gas OPEX (Gross $/MCF)',
          'opexVariableGasGross',
          Math.min(
            cases.p90.opexVariableGasGross,
            cases.p50.opexVariableGasGross,
            cases.p10.opexVariableGasGross
          ),
          Math.max(
            cases.p90.opexVariableGasGross,
            cases.p50.opexVariableGasGross,
            cases.p10.opexVariableGasGross
          ),
          cases.p50.opexVariableGasGross,
          R.identity
        ),
        new TornadoRow(
          'Variable Oil OPEX (Gross $/BO)',
          'opexVariableOilGross',
          Math.min(
            cases.p90.opexVariableOilGross,
            cases.p50.opexVariableOilGross,
            cases.p10.opexVariableOilGross
          ),
          Math.max(
            cases.p90.opexVariableOilGross,
            cases.p50.opexVariableOilGross,
            cases.p10.opexVariableOilGross
          ),
          cases.p50.opexVariableOilGross,
          R.identity
        ),
        new TornadoRow(
          'Overhead (Gross $/month)',
          'overheadGross',
          Math.min(
            cases.p90.overheadGross,
            cases.p50.overheadGross,
            cases.p10.overheadGross
          ),
          Math.max(
            cases.p90.overheadGross,
            cases.p50.overheadGross,
            cases.p10.overheadGross
          ),
          cases.p50.overheadGross,
          R.identity
        ),
      ])
    )
    console.log(
      'In action.createTornado.  Here is the tornado, before dispatch:  ',
      tornado
    )
    dispatch({
      type: 'CALC_TORNADO',
      payload: tornado,
    })
  } catch (err) {
    console.log(
      'Error while calculating the tornado.  This may happen if the input is incomplete.  Here is the error...'
    )
    console.log(err)
  }
}

// calculates economics of all cases by sending to Cashflowjs
export const calcAllCases = (project, caseInputs, pricingInputs) => async (
  dispatch
) => {
  let result

  // Insert default values for case inputs and project values if not specified so that initial calculation will succeed
  // Also finds invalid inputs and displays error messages
  const validatedCaseInput = validateCaseInput(caseInputs)
  const validatedProjectInput = validateProjectInput(project)
  const validatedPricingInput = validatePricingInput(pricingInputs)
  console.log('Validated case input:', validatedCaseInput)
  console.log('Validated project input:', validatedProjectInput)
  console.log('Validated pricing input:', validatedPricingInput)

  if (
    !validatedCaseInput.error &&
    !validatedProjectInput.error &&
    !validatedPricingInput.error
  ) {
    // Update calcComplete to false while calculation is running so user can be notified on screen
    dispatch({ type: 'UPDATE_CALC_STATUS' })

    try {
      result = calcEconomicsProbRisked(
        pricingInputs.Oil,
        pricingInputs.Gas,
        validatedProjectInput,
        convertHeconomicsReservesToCashflowReserves(
          validatedProjectInput.major,
          validatedCaseInput
        )
      )
      console.log('Initial results:', result)
      dispatch({
        type: 'CALC_ALL_CASES',
        payload: result,
      })

      // The two below actions allow green check mark to appear next to Calculate button and then disappear
      dispatch({ type: 'TOGGLE_CALC_SUCCESS' })

      createTornadoHelper(dispatch, project.major, caseInputs, result)

      setTimeout(() => {
        dispatch({ type: 'TOGGLE_CALC_SUCCESS' })
      }, STANDARD_SUCCESS_DISPLAY_TIME_MS)
    } catch (err) {
      console.log(err)
      if (err.message.includes('properties are missing')) {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'cashflow',
            title: 'Cashflow Error',
            message: 'Input to cashflow is missing at least 1 property.',
            show: true,
          },
        })
      } else if (err.message.includes('prodForecastMethod is not recognized')) {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'cashflow',
            title: 'Cashflow Error',
            message: 'Unsupported production forecast method submitted.',
            show: true,
          },
        })
      } else {
        console.log(err)
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'cashflow',
            title: 'Cashflow Error',
            message: err.message || JSON.stringify(err),
            show: true,
          },
        })
      }
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'cashflow',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    } finally {
      // Update calcComplete variable to alert user that calculation is complete and stop progress components from showing
      dispatch({ type: 'UPDATE_CALC_STATUS' })
    }
  } else if (validatedProjectInput.error) {
    console.log(
      'Data Validation Error for Project Input.  Error message:  ',
      validateProjectInput.errors
    )
    dispatch({
      type: 'ADD_VALIDATION_ERRORS',
      payload: validatedProjectInput.errors,
    })
    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'project',
        title: 'Project Input Error',
        message: `The following project inputs have invalid values: ${validatedProjectInput.errors
          .map(
            (item) =>
              `${item.errorKey} ${
                item.errorMessage ? '-' : ''
              } ${item.errorMessage || ''}`
          )
          .join('\n')}`,
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'project',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  } else if (validatedPricingInput.error) {
    console.log(
      'Data Validation Error for Pricing Input.  Error message:  ',
      validatePricingInput.errors
    )
    dispatch({
      type: 'ADD_VALIDATION_ERRORS',
      payload: validatedPricingInput.errors,
    })
    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'pricing',
        title: 'Pricing Input Error',
        message: `The following pricing inputs have invalid values: ${validatedPricingInput.errors
          .map(
            (item) =>
              `${item.errorKey} ${
                item.errorMessage ? '-' : ''
              } ${item.errorMessage || ''}`
          )
          .join('\n')}`,
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'pricing',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  } else if (validatedCaseInput.error) {
    dispatch({
      type: 'ADD_VALIDATION_ERRORS',
      payload: validatedCaseInput.errors,
    })
    console.log(
      'Data Validateion Error for Case Input.  Error messages:',
      validatedCaseInput.errors
        .map((item) => `${item.errorCase}.${item.errorKey}`)
        .join(', ')
    )
    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'caseInputs',
        title: 'Case Input Error',
        message: `The following case inputs have invalid values:
        ${validatedCaseInput.errors
          .map((item) => `${item.errorCase}.${item.errorKey}`)
          .join(', ')}
        ${
          validatedCaseInput.errors.find(
            (item) => item.errorKey === 'hyperbolicExponent'
          )
            ? `. ${
                validatedCaseInput.errors.find(
                  (item) => item.errorKey === 'hyperbolicExponent'
                )?.errorMessage
              }`
            : ''
        }
        ${
          validatedCaseInput.errors.find(
            (item) => item.errorKey === 'customProdForecast'
          )
            ? `. ${
                validatedCaseInput.errors.find(
                  (item) => item.errorKey === 'customProdForecast'
                )?.errorMessage
              }`
            : ''
        }`,
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'caseInputs',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  }
}

// Signifies that user has been validated and enables the app screens across application
export const updateLogin = (value) => async (dispatch) => {
  if (value) {
    dispatch({ type: 'TOGGLE_LOGIN', payload: value })
  }
}

// Updates capital investments object when use updates inputs for capital investments
export const updateInvestments = (group, array) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CAPITAL', payload: { group, array } })
}

export const showCustomForecast = (value, group) => async (dispatch) => {
  dispatch({ type: 'SHOW_CUSTOM_FORECAST', payload: value })
  if (group) {
    dispatch({ type: 'SET_CUSTOM_FORECAST_CASE', payload: group })
  }
}

// Updates capital investments object when use updates inputs for capital investments
export const updateSingleInvestments = (
  group,
  capitalInvestments,
  cases
) => async (dispatch) => {
  const sortedInvestments = _.orderBy(capitalInvestments, ['Month'], ['desc'])
  let newCapitalGrossArray = []

  let blankMonth = sortedInvestments.find((item) => item.Month === '')

  if (
    !blankMonth &&
    !sortedInvestments.find(
      (item) =>
        isNaN(item.Month) ||
        isNaN(Number(item.Investment.toString().replace(/,/g, '')))
    )
  ) {
    if (sortedInvestments.length > 0) {
      for (let i = 0; i < sortedInvestments[0].Month; i++) {
        if (i === 0) {
          newCapitalGrossArray[i] = cases?.[group]?.capitalGross[0] || '0'
        } else if (sortedInvestments.find((item) => item.Month === i + 1)) {
          newCapitalGrossArray[i] = sortedInvestments.find(
            (item) => item.Month === i + 1
          ).Investment
        } else {
          newCapitalGrossArray[i] = '0'
        }
      }
    } else {
      newCapitalGrossArray[0] = cases[group].capitalGross[0]
    }

    dispatch({
      type: 'UPDATE_VALUES',
      payload: { group, input: 'capitalGross', value: newCapitalGrossArray },
    })
  }
}

export const addCapitalRow = (caseItem, capitalInvestments) => async (
  dispatch
) => {
  const maxMonth =
    _.max(capitalInvestments?.[caseItem].map((item) => item.Month)) || 1

  dispatch({
    type: 'ADD_CAPITAL_ROW',
    payload: {
      caseItem,
      newInvestmentObject: { Month: maxMonth + 1, Investment: '0' },
    },
  })
}

export const removeCapitalRow = (caseItem, index) => async (dispatch) => {
  dispatch({
    type: 'REMOVE_CAPITAL_ROW',
    payload: { caseItem, index },
  })
}

// Switches to new pages
export const changePage = (page) => async (dispatch) => {
  dispatch({ type: 'CHANGE_PAGE', payload: page })
}

// Switches to new tabs within pages
export const changeSubPage = (subPage) => async (dispatch) => {
  dispatch({ type: 'CHANGE_SUBPAGE', payload: subPage })
}

// Switches new tabs for capital investments
export const changeTab = (tab) => async (dispatch) => {
  dispatch({ type: 'CHANGE_TAB', payload: tab })
}

export const changeNavigationArray = (array) => async (dispatch) => {
  dispatch({ type: 'CHANGE_ARRAY', payload: array })
}

export const closeDialog = (group) => async (dispatch) => {
  dispatch({ type: 'CLOSE_DIALOG', payload: group })
}

export const updateDefaults = (file) => async (dispatch) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    const data = new Uint8Array(reader.result)
    const workbook = XLSX.read(data, { type: 'array' })
    let defaults = XLSX.utils.sheet_to_json(workbook.Sheets.Defaults)

    defaults.forEach((defaultItem, index) => {
      SPREADSHEET_FIELD_TRANSLATION.forEach((fieldItem) => {
        if (
          defaultItem?.[fieldItem.prettyName] ||
          defaultItem?.[fieldItem.prettyName] === 0
        ) {
          defaults[index][fieldItem.name] = defaultItem[fieldItem.prettyName]
          if (fieldItem.prettyName !== fieldItem.name) {
            delete defaultItem[fieldItem.prettyName]
          }
        }
      })
    })

    if (defaults.find((item) => item.FieldName)) {
      dispatch({ type: 'UPDATE_DEFAULTS', payload: defaults })

      instance
        .put('/differentials', { defaults })
        .then(() => console.log('Updated defaults posted.'))
        .catch((err) => {
          console.log('Error posting update to defaults', err)
          dispatch({
            type: 'UPDATE_ERROR',
            payload: {
              group: 'defaults',
              title: 'Unable to save uploaded values',
              message: 'Defaults could not be saved to the database',
              show: true,
            },
          })
          setTimeout(() => {
            dispatch({
              type: 'UPDATE_ERROR',
              payload: {
                group: 'defaults',
                title: null,
                message: null,
                show: false,
              },
            })
          }, STANDARD_ERROR_DISPLAY_TIME_MS)
        })
    } else {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'defaults',
          title: 'Invalid format',
          message:
            'FieldName column required for diffs and OPEX data. Please verify that you are uploading the correct file.',
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'defaults',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    }
  }

  reader.readAsArrayBuffer(file)
}

export const updatePrices = (file, name) => async (dispatch) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    const data = new Uint8Array(reader.result)
    const workbook = XLSX.read(data, { type: 'array' })
    const pricesData = XLSX.utils.sheet_to_json(workbook.Sheets.Forecast)
    console.log(pricesData)

    let priceObject = {
      PriceName: name,
      Oil: pricesData.map((item) => item.Oil),
      Gas: pricesData.map((item) => item.Gas),
    }

    if (pricesData.length === 600) {
      dispatch({ type: 'LOAD_PRICES', payload: priceObject })
      dispatch({ type: 'UPDATE_PRICE_FILTER', payload: name })

      instance
        .put('/prices', {
          priceForecast: priceObject,
        })
        .then(() => console.log('Updated prices posted.'))
        .catch((err) => {
          console.log('Error updating prices', err)
          dispatch({
            type: 'UPDATE_ERROR',
            payload: {
              group: 'prices',
              title: 'Price Data Update Error',
              message: 'Unable to update price values.',
              show: true,
            },
          })
          setTimeout(() => {
            dispatch({
              type: 'UPDATE_ERROR',
              payload: {
                group: 'prices',
                title: null,
                message: null,
                show: false,
              },
            })
          }, STANDARD_ERROR_DISPLAY_TIME_MS)
        })
    } else if (pricesData.length !== 600) {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'prices',
          title: 'Invalid Length',
          message:
            'This price deck does not have 600 months of data. Please update and resubmit.',
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'prices',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    }
  }

  reader.readAsArrayBuffer(file)
}

export const deletePriceDeck = (priceName, prices) => async (dispatch) => {
  await dispatch({
    type: 'UPDATE_PRICE_FILTER',
    payload: prices.find((item) => item.PriceName !== priceName)?.PriceName,
  })
  dispatch({ type: 'DELETE_PRICE_NAME', payload: priceName })

  const response = await instance
    .delete(`/prices/${encodeURIComponent(priceName)}`)
    .catch((err) => console.log(err))

  console.log(response)
}

export const updatePriceFilter = (value) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PRICE_FILTER', payload: value })
}

export const updateTaxes = (file) => async (dispatch) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    const data = new Uint8Array(reader.result)
    const workbook = XLSX.read(data, { type: 'array' })

    const taxes = XLSX.utils.sheet_to_json(workbook.Sheets.Taxes)

    if (taxes.find((item) => item.State)) {
      dispatch({ type: 'LOAD_TAXES', payload: taxes })
      instance
        .put('/taxes', { taxes })
        .then(() => {
          console.log('Taxes posted.')
          dispatch({ type: 'GO_ONLINE' })
        })
        .catch((err) => {
          console.log(err)
          dispatch({
            type: 'UPDATE_ERROR',
            payload: {
              group: 'taxes',
              title: 'Unable to upload tax data',
              message:
                '404 Error. Could not connect to taxes API to post new tax data.',
              show: true,
            },
          })
          setTimeout(() => {
            dispatch({
              type: 'UPDATE_ERROR',
              payload: {
                group: 'taxes',
                title: null,
                message: null,
                show: false,
              },
            })
          }, STANDARD_ERROR_DISPLAY_TIME_MS)
        })
    } else {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'taxes',
          title: 'Invalid format',
          message:
            'State column required for tax data. Please verify that you are uploading the correct file.',
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'taxes',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    }
  }

  reader.readAsArrayBuffer(file)
}

export const loadProject = ({
  projectInputs,
  cases,
  // pricingInputs,
  taxInfoArray,
}) => async (dispatch) => {
  loadProjectHelper(dispatch, projectInputs, cases, taxInfoArray)
}

export const loadProjectHelper = (
  dispatch,
  projectInputs,
  cases,
  // pricingInputs,
  taxInfoArray
) => {
  const currentMoment = moment()

  const defaultResults = {
    p90: { economicMetrics: {} },
    p50: { economicMetrics: {} },
    p10: { economicMetrics: {} },
    mean: { economicMetrics: {} },
    failure: { economicMetrics: {} },
    risked: { economicMetrics: {} },
    incrementalRisked: { cashflow: {}, economicMetrics: {} },
    incrementalUnrisked: { economicMetrics: {} },
  }

  if (projectInputs) {
    // dispatch({ type: 'OVERWRITE_PROJECT_INPUTS', payload: projectInputs });
    PROJECT_INPUT_FIELDS.forEach((keyName) => {
      // if (!TAXES_INPUT_FIELDS.includes(keyName)) {
      //   dispatch({
      //     type: 'UPDATE_PROJECT_INPUTS',
      //     payload: {
      //       input: keyName,
      //       value:
      //         projectInputs?.[keyName] || defaultProjectInputValues[keyName],
      //     },
      //   })
      // }

      dispatch({
        type: 'UPDATE_PROJECT_INPUTS',
        payload: {
          input: keyName,
          value:
            projectInputs?.[keyName] || defaultProjectInputValues[keyName],
        },
      })

    })

    // loadTaxesHelper(dispatch, projectInputs?.state, taxInfoArray)
  }

  if (cases) {
    dispatch({ type: 'OVERWRITE_PROJECT_CASES', payload: cases })
    dispatch({ type: 'LOAD_CAPITAL', payload: cases })
  }

  dispatch({ type: 'OVERWRITE_PROJECT_RESULTS', payload: defaultResults })

  // if (pricingInputs) {
  //   dispatch({type: 'OVERWRITE_PRICING_INPUTS', payload: pricingInputs})
  // }
  // GODO, KTE, 4/21/2021:  I decided to NOT load prices.  I may change this in the future,
  // but I think it is reasonable that the user select their prices each time
  // they load a project (vs loading the prior).  Storing prices in the project
  // object leads to lots of complexity, and I don't think it's worth it.
  // Reconsider this in the future...

  dispatch({ type: 'CHANGE_PAGE', payload: 'Case Data' })
  dispatch({ type: 'CHANGE_SUBPAGE', payload: 'Cases' })
  dispatch({
    type: 'CHANGE_ARRAY',
    payload: ['Cases', 'Additional Investments'],
  })
  dispatch({ type: 'CLEAR_CALC_EXECUTION' })
}

/**
 * We define tax info for the states where we operate.  However, users may enter
 * a state where we have not previously defined/entered the tax info.
 *
 * This function returns the taxInfo object for the passed-in state.  If there
 * is no tax info for that state, then it returns the 'Not Specified' object.
 *
 * @param {String} state Full name of the state.  e.g. Alaska, Texas, etc.
 * @param {taxInfo[]} taxInfoArray Array of taxInfo objects.  See the
 * loadTaxesHelper comments for a description of this object.
 * @returns taxInfo object
 */
const getTaxInfoForGivenState = (stateInAmerica, taxInfoArray = []) =>
  taxInfoArray.find(
    (taxInfoObject) => taxInfoObject.FullName === stateInAmerica
  ) || taxInfoArray.find((item) => item.FullName === 'Not Specified')

/**
 * This method dispatches the actions that load the various types of taxes
 * into the Redux global state.
 *
 * Keith Elliott 4/22/2021
 *
 * @param {function} dispatch The Redux dispatch function from the action
 * creator that calls this function.
 * @param {String} stateInAmerica Full name of the state.  e.g. Alaska, Texas, etc.
 * @param {taxInfo[]} taxInfoArray An array of taxInfo objects.  These objects
 * contain the oil & gas tax info for a particular state.  They look like this:
 *  { State: "NM",
 *    gasSevTaxRatePercent: 7.1,
 *    oilSevTaxRatePercent: 6,
 *    ...more properties exist...
 *  }
 */
const loadTaxesHelper = (dispatch, stateInAmerica, taxInfoArray) => {
  const taxInfoForSpecificState = getTaxInfoForGivenState(
    stateInAmerica,
    taxInfoArray
  )

  updateProjectInputsHelper(
    dispatch,
    'gasSevTaxRatePercent',
    taxInfoForSpecificState.gasSevTaxRatePercent
  )

  updateProjectInputsHelper(
    dispatch,
    'gasProdTaxRate',
    taxInfoForSpecificState.gasProdTaxRate
  )

  updateProjectInputsHelper(
    dispatch,
    'oilSevTaxRatePercent',
    taxInfoForSpecificState.oilSevTaxRatePercent
  )

  updateProjectInputsHelper(
    dispatch,
    'oilProdTaxRate',
    taxInfoForSpecificState.oilProdTaxRate
  )

  updateProjectInputsHelper(
    dispatch,
    'nglSevTaxRatePercent',
    taxInfoForSpecificState.nglSevTaxRatePercent
  )

  updateProjectInputsHelper(
    dispatch,
    'nglProdTaxRate',
    taxInfoForSpecificState.nglProdTaxRate
  )
}

export const makeProjectPublic = (project) => async (dispatch, getState) => {
  let updatedProject = { ...project, Owner: 'All Users' }
  const existingState = getState()
  let newProjectList = existingState.projects.map((oldProject) => {
    if (
      oldProject.ProjectName === project.ProjectName &&
      oldProject.Owner === project.Owner
    ) {
      return updatedProject
    } else {
      return oldProject
    }
  })

  const response = await instance
    .post('/project', updatedProject)
    .catch((err) => {
      const { message } = err
      console.log('Project save error:', message)
      if (message.includes('Network Error')) {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'project',
            title: 'Project Not Saved.',
            message: 'Network Error. Please check your network connection.',
            show: true,
          },
        })
      } else {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'project',
            title: 'Project Not Saved.',
            message: 'Unable to save project data. Unknown error.',
            show: true,
          },
        })
      }

      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'project',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    })

  if (response?.status === 200) {
    dispatch({ type: 'MAKE_PUBLIC', payload: newProjectList })
  }
}

export const updateSettings = (key, value, user) => async (
  dispatch,
  getState
) => {
  if (key === 'PriceForecasts') {
    dispatch({ type: 'UPDATING_FORECASTS' })
  }
  const response = await instance
    .post('/settings', {
      settings: { key, value, user },
    })
    .catch((err) => console.log(err))

  console.log('Response:', response)

  if (response?.status === 200) {
    dispatch({ type: 'UPDATE_SETTINGS', payload: { key, value, user } })
    dispatch({ type: 'GO_ONLINE' })

    if (key === 'defaultProject') {
      const defaultProject = getState().projects.find(
        (item) => item.projectInputs.projectName === value
      )
      localStorage.setItem('defaultProject', JSON.stringify(defaultProject))
    }
  }

  if (key === 'PriceForecasts') {
    dispatch({ type: 'UPDATING_FORECASTS' })
  }
}

export const validateBrowser = (valid) => async (dispatch) => {
  dispatch({ type: 'UPDATE_BROWSER_VALIDITY', payload: valid })
}

export const createProject = () => async (dispatch) => {
  dispatch({ type: 'LOAD_NEW_PROJECT_VALUES' })
  dispatch({ type: 'CLEAR_RESULTS' })
  dispatch({ type: 'CLEAR_CAPITAL' })
  dispatch({ type: 'CLEAR_PROJECT_INPUTS' })
  dispatch({
    type: 'UPDATE_PRICE_NAME',
    payload: 'Select Forecast',
  })
  dispatch({ type: 'CLEAR_PRICING_INPUTS' })
  dispatch({ type: 'CLEAR_CALC_EXECUTION' })
  CASE_HEADERS_INPUT_ONLY.map((item) => {
    dispatch({
      type: 'UPDATE_VALUES',
      payload: { input: 'capitalGross', value: [0], group: item },
    })
  })
  CASE_HEADERS_INPUT_ONLY.map((item) => {
    dispatch({
      type: 'UPDATE_VALUES',
      payload: { input: 'maxLifeYears', value: 50, group: item },
    })
  })
  dispatch({ type: 'CHANGE_PAGE', payload: 'Case Data' })
  dispatch({ type: 'CHANGE_SUBPAGE', payload: 'Cases' })
}

export const getProjectIds = () => async (dispatch) => {
  dispatch({ type: 'IS_GETTING_PROJECT_IDS', payload: true })

  const response = await instance.get('/projectids').catch((err) => {
    console.log('Error retrieving projectIds:', err)
    if (err.message === 'Network Error') {
      dispatch({ type: 'GO_OFFLINE' })
    }

    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'projectIds',
        title: 'Unable to load project data',
        message:
          '404 Error. Could not connect to taxes API to retrieve project identification data.',
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'projectIds',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
    dispatch({ type: 'IS_GETTING_PROJECT_IDS', payload: false })
  })
  console.log("Project ID response data:", response?.data)
  if (response?.data) {
    dispatch({
      type: 'LOAD_PROJECT_IDS',
      payload: response.data,
    })

    dispatch({ type: 'IS_GETTING_PROJECT_IDS', payload: false })
    return response.data
  }
}

export const getProjectSingle = (
  projectName,
  owner,
  taxInfoArray,
  loadingProjectDetailsMessage
) => async (dispatch) => {
  const encodedProjectName = encodeURIComponent(projectName)
  const encodedOwner = encodeURIComponent(owner)

  dispatch({ type: 'IS_SHOWING_PROJECTS_MODAL', payload: true })
  dispatch({ type: 'IS_LOADING_PROJECT_DETAILS', payload: true })
  dispatch({
    type: 'LOADING_PROJECT_DETAILS_MESSAGE',
    payload: loadingProjectDetailsMessage,
  })

  let response

  response = await instance
    .get(`/projects/${encodedOwner}/${encodedProjectName}`)
    .catch((err) => {
      console.log(err)
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'project',
          title: 'User Projects Not Found',
          message: 'Unable to retrieve user projects',
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'project',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
      dispatch({ type: 'IS_LOADING_PROJECT_DETAILS', payload: false })
      dispatch({ type: 'IS_SHOWING_PROJECTS_MODAL', payload: false })
    })
    console.log("Load Project response:", response);
  loadProjectHelper(
    dispatch,
    response?.data?.projectInputs,
    response?.data?.cases,
    // response?.data?.pricingInputs,
    taxInfoArray
  )

  // updateProjectInputsHelper()
  dispatch({ type: 'IS_LOADING_PROJECT_DETAILS', payload: false })
  dispatch({ type: 'IS_SHOWING_PROJECTS_MODAL', payload: false })
  dispatch({ type: 'LOADING_PROJECT_DETAILS_MESSAGE', payload: '' })
}

export const getProjects = (userName) => async (dispatch) => {
  let response

  if (userName) {
    response = await instance.get(`/projects/${userName}`).catch((err) => {
      console.log(err)
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'project',
          title: 'User Projects Not Found',
          message: 'Unable to retrieve user projects',
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'project',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    })
  } else {
    response = await instance.get('/projects').catch((err) => {
      console.log(err)
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'project',
          title: 'Heconomics project not loaded',
          message: 'Unable to retrieve projects for all users',
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'project',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    })
  }

  dispatch({ type: 'COMPLETE_PROJECTS_LOAD' })
  if (response?.data) {
    console.log("Projects load response:", response.data);
    dispatch({ type: 'LOAD_PROJECTS', payload: response.data })
    dispatch({ type: 'GO_ONLINE' })
    return response.data
  } else {
    const projects = localStorage?.getItem('localState')?.projects
    if (projects?.length > 0) {
      dispatch({ type: 'LOAD_PROJECTS', payload: projects })
    }
  }
}

export const deleteProject = (Owner, ProjectName) => async (dispatch) => {
  const encodedOwner = encodeURIComponent(Owner)
  const encodedProjectName = encodeURIComponent(ProjectName)

  const response = await instance
    .delete(`/projects/${encodedOwner}/${encodedProjectName}`)
    .catch((err) => {
      console.log(err)
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'projectName',
          title: 'Delete Failure',
          message: `Project could not be deleted. Error: ${err.message}`,
          show: true,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_ERROR',
          payload: {
            group: 'projectName',
            title: null,
            message: null,
            show: false,
          },
        })
      }, STANDARD_ERROR_DISPLAY_TIME_MS)
    })

  if (response?.data) {
    dispatch({ type: 'DELETE_PROJECT', payload: { Owner, ProjectName } })
    dispatch({ type: 'DELETE_PROJECT_ID', payload: { Owner, ProjectName } })
    dispatch({ type: 'GO_ONLINE' })
  }
}

export const saveProject = (projectData) => async (dispatch) => {
  const { ProjectName } = projectData
  console.log("Project data:", projectData)
  if (!ProjectName || ProjectName === '') {
    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'projectName',
        title: 'Missing Project Name',
        message:
          'Project could not be saved. Please enter a project name and try again.',
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'projectName',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  } else {
    console.log('in saveProject, about to post...')
    const response = await instance
      .post('/project', projectData)
      .catch((err) => {
        const { message } = err
        console.log('Project save error:', message)
        if (message.includes('Network Error')) {
          dispatch({
            type: 'UPDATE_ERROR',
            payload: {
              group: 'project',
              title: 'Project Not Saved.',
              message: 'Network Error. Please check your network connection.',
              show: true,
            },
          })
          dispatch({ type: 'GO_OFFLINE' })
        } else {
          dispatch({
            type: 'UPDATE_ERROR',
            payload: {
              group: 'project',
              title: 'Project Not Saved.',
              message: 'Unable to save project data. Unknown error.',
              show: true,
            },
          })
        }

        setTimeout(() => {
          dispatch({
            type: 'UPDATE_ERROR',
            payload: {
              group: 'project',
              title: null,
              message: null,
              show: false,
            },
          })
        }, STANDARD_ERROR_DISPLAY_TIME_MS)
      })
    console.log('in saveProject, after the post, here is the response:  ')
    console.log(response)
    if (response?.status === 200) {
      dispatch({ type: 'SAVE_PROJECT', payload: projectData })
      dispatch({
        type: 'SAVE_PROJECT_ID',
        payload: {
          ProjectName: projectData.ProjectName,
          Owner: projectData.Owner,
          LastModified: projectData.LastModified,
          Type: projectData.Type,
        },
      })
      dispatch({ type: 'TOGGLE_SAVE_SUCCESS' })
      dispatch({ type: 'TOGGLE_SAVE_SUCCESS' })
      dispatch({ type: 'GO_ONLINE' })
    }
  }
}

export const isShowingProjectsModal = (isShowingProjectsModal) => async (
  dispatch
) => {
  dispatch({
    type: 'IS_SHOWING_PROJECTS_MODAL',
    payload: isShowingProjectsModal,
  })
}

export const isShowingOPEXModal = (isShowingOPEXModal) => async (
  dispatch
) => {
  dispatch({
    type: 'IS_SHOWING_OPEX_MODAL',
    payload: isShowingOPEXModal,
  })
}

/* Not currently used (until additional project types are developed - REH - 6/25/20) */
export const toggleNewProjectModal = () => async (dispatch) => {
  dispatch({ type: 'TOGGLE_NEW_PROJECTS' })
}

export const getDifferentials = () => async (dispatch) => {
  const response = await instance.get('/differentials').catch((err) => {
    console.log('Error retrieving defaults:', err)
    if (err.message === 'Network Error') {
      dispatch({ type: 'GO_OFFLINE' })
    }

    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'defaults',
        title: 'Load Defaults Error',
        message: 'Unable to retrieve default values',
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'defaults',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  })

  if (response?.data) {
    let sortedArray = response.data.sort((a, b) =>
      a.FieldName.toUpperCase().localeCompare(b.FieldName.toUpperCase())
    )

    dispatch({ type: 'UPDATE_DEFAULTS', payload: sortedArray })
  }
}

export const postUser = (username) => async (dispatch) => {
  const response = await instance
    .put('/settings', { username })
    .catch((err) => console.log(err))
}

export const getSettings = () => async (dispatch) => {
  const response = await instance.get('/settings').catch((err) => {
    console.log('Error retrieving settings:', err)
    if (err.message === 'Network Error') {
      dispatch({ type: 'GO_OFFLINE' })
    }

    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'settings',
        title: 'Unable to load settings data',
        message:
          '404 Error. Could not connect to settings API to retrieve settings data.',
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'settings',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  })

  if (response?.data) {
    dispatch({ type: 'LOAD_SETTINGS', payload: response.data })
    return response.data
  }
}

export const getTaxes = () => async (dispatch) => {
  const response = await instance.get('/taxes').catch((err) => {
    console.log('Error retrieving taxes:', err)
    if (err.message === 'Network Error') {
      dispatch({ type: 'GO_OFFLINE' })
    }

    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'taxes',
        title: 'Unable to load tax data',
        message:
          '404 Error. Could not connect to taxes API to retrieve tax data.',
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'taxes',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  })

  if (response?.data) {
    dispatch({
      type: 'LOAD_TAXES',
      payload: _.orderBy(response.data, ['State'], ['asc']),
    })
    return response.data
  }
}

export const getPrices = () => async (dispatch) => {
  const response = await instance.get('/prices').catch((err) => {
    console.log('Error retrieving prices:', err.message)
    if (err.message === 'Network Error') {
      dispatch({ type: 'GO_OFFLINE' })
    }

    dispatch({
      type: 'UPDATE_ERROR',
      payload: {
        group: 'prices',
        title: 'Unable to load price data',
        message:
          '404 Error. Could not connect to prices API to retrieve price data.',
        show: true,
      },
    })
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_ERROR',
        payload: {
          group: 'prices',
          title: null,
          message: null,
          show: false,
        },
      })
    }, STANDARD_ERROR_DISPLAY_TIME_MS)
  })

  if (response?.data?.length) {
    console.log('In action getPrices.  Here is the response:   ')
    console.log(response)

    let sortedArray = _.orderBy(response.data, ['PriceName'], ['asc'])
    let formattedPricesArray = []

    for (let i = 0; i < sortedArray.length; i++) {
      if (sortedArray[i]?.Gas?.length) {
        for (let k = 0; k < sortedArray[i].Gas.length; k++) {
          formattedPricesArray.push({
            PriceName: sortedArray[i].PriceName,
            Month: k + 1,
            Gas: sortedArray[i].Gas?.[k] || [],
            Oil: sortedArray[i].Oil?.[k] || [],
          })
        }
      } else {
        console.log(
          'Malformed price data encountered.  The following object will NOT be added to the formattedPricesArray:  '
        )
        console.log(sortedArray[i])
      }
    }

    dispatch({ type: 'FETCH_PRICES', payload: response.data })
    dispatch({
      type: 'UPDATE_PRICE_FILTER',
      payload: response.data[0].PriceName,
    })

    return response.data
  }
}

export const overwriteProjectInputs = (inputs) => async (dispatch) => {
  dispatch({ type: 'OVERWRITE_PROJECT_INPUTS', payload: inputs })
}

export const updateProjectInputs = (input, value) => async (dispatch) => {
  updateProjectInputsHelper(dispatch, input, value)
}

const updateProjectInputsHelper = (dispatch, input, value) => {
  dispatch({ type: 'UPDATE_PROJECT_INPUTS', payload: { input, value } })

  if (NUMERIC_PROJECT_INPUTS.includes(input)) {
    if (!value.toString().match(NUMERIC_VALIDATION_REGEX)) {
      if (NUMERIC_PERCENTAGE_INPUTS.includes(input) && Number(value) > 100) {
        dispatch({
          type: 'ADD_INPUT_ERROR',
          payload: {
            errorLocation: 'projectInputs',
            errorKey: input,
            errorValue: value,
          },
        })
      } else {
        dispatch({ type: 'REMOVE_INPUT_ERROR', payload: input })
      }
    } else {
      dispatch({
        type: 'ADD_INPUT_ERROR',
        payload: {
          errorLocation: 'projectInputs',
          errorKey: input,
          errorValue: value,
        },
      })
    }
  }
}

export const updatePricingInputName = (priceName) => async (dispatch) => {
  // if (group === 'PriceName') {
  dispatch({ type: 'UPDATE_PRICE_NAME', payload: priceName })
  // } else {
  //   dispatch({type: 'UPDATE_PRICING_INPUTS', payload: {group, name, value}})
  // }
}
// GoDo, KTE, 9/21/2021:  Remove the following after confirming that group is not used.
// export const updatePricingInputs = (group, name, value) => async dispatch => {
//   if (group === 'PriceName') {
//     dispatch({type: 'UPDATE_PRICE_NAME', payload: {name, value}})
//   } else {
//     dispatch({type: 'UPDATE_PRICING_INPUTS', payload: {group, name, value}})
//   }
// }

const appendLastPriceToMaxLife = (
  maxLifeYears: number,
  pricingInput: PricingInputsType
) => ({
  ...pricingInput,
  Oil: appendLastValueToMaxLifeYearsIfArray(maxLifeYears)(pricingInput.Oil),
  Gas: appendLastValueToMaxLifeYearsIfArray(maxLifeYears)(pricingInput.Gas),
})

export const updatePricingInputsBulk = (
  maxLifeYears: number,
  pricingInput: PricingInputsType
) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_PRICING_INPUTS_BULK',
    payload: appendLastPriceToMaxLife(maxLifeYears, pricingInput),
  })
}

export const updateCustomPricesBulk = (
  maxLifeYears: number,
  pricingInput: PricingInputsType
) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_CUSTOM_PRICES_BULK',
    payload: appendLastPriceToMaxLife(maxLifeYears, pricingInput),
  })
}

export const deletePricingInput = (input) => async (dispatch) => {
  dispatch({ type: 'DELETE_PRICING_INPUT', payload: input })
}

export const clearPricingInputs = () => async (dispatch) => {
  dispatch({ type: 'CLEAR_PRICING_INPUTS' })
}

export const updateValues = (
  group: CaseNameType,
  input: CaseInputNameType,
  value
) => async (dispatch) => {
  dispatch({ type: 'UPDATE_VALUES', payload: { input, value, group } })

  if (input === 'capitalGross') {
    if (value[0].toString().match(NUMERIC_VALIDATION_REGEX)) {
      dispatch({
        type: 'ADD_CASE_INPUT_ERROR',
        payload: {
          errorLocation: 'cases',
          errorCase: group,
          errorKey: input,
          errorValue: value,
        },
      })
    } else {
      dispatch({ type: 'REMOVE_CASE_INPUT_ERROR', payload: { group, input } })
    }
  } else if (input === 'customProdForecast') {
  } else {
    if (!value.toString().match(NUMERIC_VALIDATION_REGEX)) {
      dispatch({ type: 'REMOVE_CASE_INPUT_ERROR', payload: { group, input } })
    } else {
      dispatch({
        type: 'ADD_CASE_INPUT_ERROR',
        payload: {
          errorLocation: 'cases',
          errorCase: group,
          errorKey: input,
          errorValue: value,
        },
      })
    }
  }
}

export const setIsLoadingDataFromCloud = (isLoadingDataFromCloud) => async (
  dispatch
) => {
  dispatch({
    type: 'IS_LOADING_DATA_FROM_CLOUD',
    payload: isLoadingDataFromCloud,
  })
}

export const setLoadingDataFromCloudMessage = (
  loadingDataFromCloudMessage
) => async (dispatch) => {
  dispatch({
    type: 'LOADING_DATA_FROM_CLOUD_MESSAGE',
    payload: loadingDataFromCloudMessage,
  })
}

export const setLoadingProjectDetailsMessage = (
  loadingProjectDetailsMessage
) => async (dispatch) => {
  dispatch({
    type: 'LOADING_PROJECT_DETAILS_MESSAGE',
    payload: loadingProjectDetailsMessage,
  })
}
