import { number } from 'prop-types'

const R = require('ramda')

/* Exported constants used throughout the application */
export const STANDARD_ERROR_DISPLAY_TIME_MS = 200
export const STANDARD_SUCCESS_DISPLAY_TIME_MS = 200
export const STANDARD_API_TIMEOUT_MS = 10000
export const CASE_ROW_HEIGHT = 35
export const CASE_ROW_INPUT_HEIGHT = 30
export const NUMERIC_VALIDATION_REGEX = new RegExp(
  /[a-zA-Z&*%()$!@#^_]|\.\./,
  'gi'
)
export const MAX_CHARACTERS_PRICE_FORECAST_NAME = 47
export const NUMERIC_PERCENTAGE_INPUTS = [
  'workingInterestPercent',
  'netRevenueInterestPercent',
  'successPs',
  'nglPricePercent',
  'shrinkPercent',
  'adValTaxRatePercent',
]
export const NUMERIC_PROJECT_INPUTS = [
  'workingInterestPercent',
  'netRevenueInterestPercent',
  'successPs',
  'nglPricePercent',
  'shrinkPercent',
  'priceDiffGas',
  'priceDiffOil',
  'adValTaxRatePercent',
  'gasSevTaxRatePercent',
  'gasProdTaxRate',
  'oilSevTaxRatePercent',
  'oilProdTaxRate',
  'nglSevTaxRatePercent',
  'nglProdTaxRate',
]
export const PERCENT_PROJECT_INPUTS = [
  'workingInterestPercent',
  'netRevenueInterestPercent',
  'successPs',
  'nglPricePercent',
  'shrinkPercent',
  'adValTaxRatePercent',
  'gasSevTaxRatePercent',
  'gasProdTaxRate',
  'oilSevTaxRatePercent',
  'oilProdTaxRate',
  'nglSevTaxRatePercent',
  'nglProdTaxRate',
]
export const PERCENT_NOT_ZERO_PROJECT_INPUTS = [
  'workingInterestPercent',
  'netRevenueInterestPercent',
  'successPs',
]
export const PROJECT_INPUT_FIELDS = [
  ...NUMERIC_PROJECT_INPUTS,
  'state',
  'field',
  'projectName',
  'description',
  'well',
  'formation',
  'major',
  'folder'
]
export const TAXES_INPUT_FIELDS = [
  'gasSevTaxRatePercent',
  'gasProdTaxRate',
  'oilSevTaxRatePercent',
  'oilProdTaxRate',
  'nglSevTaxRatePercent',
  'nglProdTaxRate',
]

export const CURRENCY_FIELDS = [
  'opexPerWellGross',
  'overheadGross',
  'opexYearlyExpenseAFEGross',
  'opexVariableGasGross',
  'opexVariableOilGross',
  'capitalGross',
  'initialOpexTotalGross',
  'initialNetOperatingIncome',
  'capitalNet',
  'capitalGross',
  'presentValue10',
  'presentValue15',
  'dollarsPerBOE',
]

export const CASE_INPUT_NAMES = [
  'capitalGross',
  'prodForecastMethod',
  'initialDailyRate',
  'finalDailyRate',
  'annualEffectiveDeclineRatePercent',
  'hyperbolicExponent',
  'terminalAnnualEffectiveDeclineRatePercent',
  'maxLifeYears',
  'reserves',
  'gasOilRatio',
  'condensateYield',
  'nglYield',
  'delayStart',
  'opexVariableGasGross',
  'opexVariableOilGross',
  'opexPerWellGross',
  'overheadGross',
  'customProdForecast',
] as const
export type CaseInputNameType = typeof CASE_INPUT_NAMES[number]

export const ADMIN_USERS = [
  'rhanson',
  'kelliott',
  'nbrent',
  'madamson',
  'jnall',
]

export const PRODUCTION_FORECAST_METHODS = [
  'Exponential: Calc Reserves and Final Rate',
  'Exponential: Calc Decline and Life',
  'Hyperbolic: Calc Reserves and Final Rate',
  'Hyperbolic to Exponential: Calc Reserves and Final Rate',
  'Custom Major Phase Forecast',
] as const
export type ProductionForecastMethodType = typeof PRODUCTION_FORECAST_METHODS[number]

export type EconomicMetricsType = {
  initialDailyBoeNet: number
  initialDailyOilGross: number
  initialDailyGasGross: number
  initialDailyNglGross: number
  finalDailyOilGross: number
  finalDailyGasGross: number
  initialNetOperatingIncome: number
  pvr0: number
  pvr10: number
  pvr15: number
  ror: number | '>300' | undefined
  payout: number
  dollarsPerBOEPD: number
  dollarsPerBOE: number
  economicLifeYears: number
  gasGrossBcf: number
  oilGrossMbo: number
  boeGrossMboe: number
  gasNetBcf: number
  oilNetMbo: number
  boeNetMboe: number
  annualEffectiveDeclineRatePercent: number
  initialDailyRate: number
  finalDailyRate: number
  reserves: number
  condensateYield: number
  nglYield: number
  gasOilRatio: number
  opexVariableGasGross: number
  opexVariableOilGross: number
  opexPerWellGross: number
  overheadGross: number
  initialOpexTotalGross: number
  overheadNet: number
  initialOpexOverheadTaxTotalNet: number
  delayStart: number
}
export type EconomicMetricsNamesType = keyof EconomicMetricsType

export type OnelineType = {
  priceBaseOil: number
  priceDiffOil: number
  priceFieldOil: number
  priceBaseGas: number
  priceDiffGas: number
  priceFieldGas: number
  nglPricePercent: number
  priceFieldNgl: number
  workingInterestPercent: number
  netRevenueInterestPercent: number
  capitalGross: number
  capitalNet: number
  oilGross: number
  gasGross: number
  gasOilRatio: number
  condensateYield: number
  nglYield: number
  nglGross: number
  boeGross: number
  oilNet: number
  shrinkPercent: number
  gasGrossLessShrink: number
  gasWorkingInterestShare: number
  gasNet: number
  nglNet: number
  boeNet: number
  revenueOil: number
  revenueGas: number
  revenueNgl: number
  revenueTotal: number
  opexPerWellGross: number
  opexPerWellNet: number
  opexVariableOilGross: number // This is the input metric
  opexVariableOilSumGross: number // This is the output metric times gross oil volume
  opexVariableOilSumNet: number
  opexVariableGasGross: number
  opexVariableGasSumGross: number
  opexVariableGasSumNet: number
  overheadGross: number
  overheadNet: number
  opexTotalGross: number
  opexTotalNet: number
  adValTaxRatePercent: number
  adValTaxNet: number
  gasSevTaxRatePercent: number
  gasSevTaxNet: number
  gasProdTaxRate: number
  gasProdTaxNet: number
  oilSevTaxRatePercent: number
  oilSevTaxNet: number
  oilProdTaxRate: number
  oilProdTaxNet: number
  nglSevTaxRatePercent: number
  nglSevTaxNet: number
  nglProdTaxRate: number
  nglProdTaxNet: number
  sevTaxNet: number
  opexOverheadTaxTotalNet: number
  netOperatingIncome: number
  netIncome: number
  yearNumber: number
  presentValue10: number
  presentValue15: number
  presentValue20: number
  presentValue25: number
  presentValue50: number
  presentValue100: number
  presentValue200: number
  presentValue300: number
  capitalNetDiscounted10: number
  capitalNetDiscounted15: number
}
export type OnelineNamesType = keyof OnelineType

export const DAYS_PER_MONTH = 30.41667
export const STATES = [
  'Select State',
  'Alabama',
  'Alaska',
  'Colorado',
  'Gulf of Mexico',
  'Louisiana',
  'Mississippi',
  'New Mexico',
  'Not Specified',
  'Ohio',
  'Oklahoma',
  'Pennsylvania',
  'Texas',
  'Wyoming',
]
export const MAJOR_PHASES = ['Select Major Phase', 'Oil', 'Gas']
export const CASE_HEADERS_INPUT_ONLY = [
  'p90',
  'p50',
  'p10',
  'failure',
  'existing',
] as const
export type CaseNameInputOnlyType = typeof CASE_HEADERS[number]

export const CASE_HEADERS = [
  'p90',
  'p50',
  'p10',
  'mean',
  'failure',
  'risked',
  'existing',
  'incrementalRisked',
] as const
export type CaseNameType = typeof CASE_HEADERS[number]
export type CaseNameArrayType = typeof CASE_HEADERS

export const CASE_HEADERS_LABELS = [
  'P90 Low Case',
  'P50 Med Case',
  'P10 High Case',
  'Mean',
  'Failure',
  'Risked Outcome',
  'Existing Conditions',
  'Incremental Risked',
]
export const MONTHLY_CASE_DATA_HEADERS = [
  'Month',
  'P90 Low Case',
  'P50 Med Case',
  'P10 High Case',
  'Mean',
  'Failure',
  'Risked Outcome',
  'Existing Conditions',
  'Incremental Risked',
]
export const PRE_CALCULATION_WARNING_MESSAGE =
  "Cashflow for this project has not been generated. Please click 'Calculate' in the main menu prior to viewing results."
export const MIN_PRICE_FORECAST_MONTHS = 1 // Custom price forecast minimum.  The last value will be repeated to the max life.
export const caseNamePrettyArray = [
  'P90 Low Case',
  'P50 Median Case',
  'P10 High Case',
  'Mean Value',
  'Failure Case',
  'Risked Mean',
  'Existing Conditions',
  'Incremental Risked',
]
export const caseObjectNameArray = [
  'p90',
  'p50',
  'p10',
  'mean',
  'failure',
  'risked',
  'existing',
  'incrementalRisked',
]

export type ResultsSubObjectType =
  | 'cashflow'
  | 'oneline'
  | 'economicMetrics'
  | 'caseInput'
  | 'project'

export const defaultProjectInputValues = {
  workingInterestPercent: 0,
  netRevenueInterestPercent: 0,
  successPs: 0,
  major: 'Oil',
  formation: '',
  folder: '',
  state: '',
  well: '',
  shrinkPercent: 0,
  field: '',
  projectName: '',
  gasSevTaxRatePercent: 0,
  gasProdTaxRate: 0,
  oilSevTaxRatePercent: 0,
  oilProdTaxRate: 0,
  nglSevTaxRatePercent: 0,
  nglProdTaxRate: 0,
  //sevTaxRatePercent: 0,
  adValTaxRatePercent: 0,
  description: '',
  type: "Swanson's Rule",
  priceGas: 2,
  priceOil: 30,
  priceDiffGas: 0,
  priceDiffOil: 0,
  nglPricePercent: 20,
}

const defaultPricingInputValues = {
  PriceName: 'Select Forecast',
}

export const defaultCaseInputValues = {
  capitalGross: [1],
  prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
  initialDailyRate: 0,
  finalDailyRate: '',
  annualEffectiveDeclineRatePercent: 0,
  delayStart: 0,
  hyperbolicExponent: '',
  terminalAnnualEffectiveDeclineRatePercent: '',
  gasOilRatio: 0,
  nglYield: 0,
  condensateYield: 0,
  initialGasProductionRate: 0,
  opexVariableGasGross: 0,
  opexVariableOilGross: 0,
  opexPerWellGross: 0,
  overheadGross: 0,
  opexYearlyExpenseAFEGross: 0,
  opExPlusAFE: 0,
  maxLifeYears: 50,
  reserves: '',
  grossOilReserves: '',
  grossOilEquiv: 0,
  netGasReserves: 0,
  netOilReserves: 0,
  netOilEquiv: 0,
  customProdForecast: [],
}

export const SPREADSHEET_FIELD_TRANSLATION = [
  {
    name: 'FieldName',
    prettyName: 'Field Name',
  },
  {
    name: 'gasShrink',
    prettyName: 'Gas Shrink (%)',
  },
  {
    name: 'nglYield',
    prettyName: 'NGL Yield (BBL/MMCF)',
  },
  {
    name: 'varOpexOil',
    prettyName: 'Gross Variable OPEX Oil ($/BBL)',
  },
  {
    name: 'varOpexGas',
    prettyName: 'Gross Variable OPEX Gas ($/MCF)',
  },
  {
    name: 'fixedWellCost',
    prettyName: 'Gross Fixed Well Cost ($/mo)',
  },
  {
    name: 'overheadCost',
    prettyName: 'Gross Overhead Cost ($/mo)',
  },
  {
    name: 'adValTaxRatePercent',
    prettyName: 'Ad Valorem Tax Rate (%)',
  },
  {
    name: 'nglPricePercent',
    prettyName: 'NGL Price (%)',
  },
  {
    name: 'priceDiffGas',
    prettyName: 'Gas Price Diff ($/MCF)',
  },
  {
    name: 'priceDiffOil',
    prettyName: 'Oil Price Diff ($/BBL)',
  },
  {
    name: 'workingInterestPercent',
    prettyName: 'Working Interest (%)',
  },
  {
    name: 'netRevenueInterestPercent',
    prettyName: 'Revenue Interest (%)',
  },
  {
    name: 'state',
    prettyName: 'State',
  },
]

/* Number formatter functions used to display number data */
export const currencyFormatter = (
  price?: string | number | null,
  decimals?: 1 | 2 | 3 | 4 | 5 | 6
): string => {
  if (
    typeof price !== 'number' &&
    typeof price !== 'string' &&
    typeof price !== 'undefined' &&
    price !== null
  )
    throw new Error(
      'Invalid input.  A number or a string that is convertable to a number is expected.  ' +
        'However, the following invalid argument was provided:  ' +
        price +
        ' of type ' +
        typeof price
    )

  const priceConvertedToNumber =
    typeof price === 'number'
      ? price
      : typeof price === 'undefined'
      ? NaN
      : price === null
      ? NaN
      : +price

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  }).format(+priceConvertedToNumber.toFixed(decimals || 0))
}

export const numberFormatter = (number, decimals) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  }).format(number)

/* Function used to validate project inputs and provide default values to allow cashflow calculation */
export const validateProjectInput = (project) => {
  let validatedProjectInputs = {}
  let errors = []

  for (let key in defaultProjectInputValues) {
    if (NUMERIC_PROJECT_INPUTS.includes(key)) {
      if (
        project[key] &&
        project?.[key]?.toString().match(NUMERIC_VALIDATION_REGEX)
      ) {
        errors.push({
          errorLocation: 'projectInputs',
          errorKey: key,
          errorValue: project[key],
          errorMessage: 'Input value contains a non-numeric character.',
        })
      } else if (PERCENT_PROJECT_INPUTS.includes(key)) {
        if (project[key] && Number(project?.[key] > 100)) {
          errors.push({
            errorLocation: 'projectInputs',
            errorKey: key,
            errorValue: project[key],
            errorMessage: 'This percent input only allows numbers up to 100.',
          })
        } else if (
          (PERCENT_NOT_ZERO_PROJECT_INPUTS.includes(key) &&
            project[key] &&
            project?.[key] == 0) ||
          project?.[key] === ''
        ) {
          errors.push({
            errorLocation: 'projectInputs',
            errorKey: key,
            errorValue: project[key],
            errorMessage: 'Input value cannot be zero or empty.',
          })
        } else {
          validatedProjectInputs[key] = !project[key]
            ? defaultProjectInputValues[key]
            : Number(project[key])
        }
      } else {
        validatedProjectInputs[key] = !project[key]
          ? defaultProjectInputValues[key]
          : Number(project[key])
      }
    } else {
      validatedProjectInputs[key] = !project[key]
        ? defaultProjectInputValues[key]
        : project[key]
    }
  }

  if (errors.length > 0) {
    validatedProjectInputs.errors = errors
    validatedProjectInputs.error = true
  }

  return validatedProjectInputs
}

/* Function that validates whether there is a price forecast selected before cashflow execution.
/  Can be used to validate other pricing inputs in the future. - REH 9/24/2020  */
export const validatePricingInput = (pricing) => {
  let validatedPricingInputs = {}
  let errors = []

  for (let key in defaultPricingInputValues) {
    if (key === 'PriceName') {
      if (['Select price type', 'Select Forecast'].includes(pricing[key])) {
        errors.push({
          errorLocation: 'pricingInputs',
          errorKey: key,
          errorValue: pricing[key],
          errorMessage: 'Select a price forecast before calculating cashflow.',
        })
      }
    }
  }

  if (errors.length > 0) {
    validatedPricingInputs.errors = errors
    validatedPricingInputs.error = true
  }
  return validatedPricingInputs
}

/* Function used to validate case inputs and provide default values to allow cashflow calculation */
export const validateCaseInput = (cases) => {
  let validatedCaseInputs = {
    p90: {},
    p50: {},
    p10: {},
    failure: {},
    existing: {},
  }

  let errors = []

  if (process.env.NODE_ENV === 'development') {
    console.log(
      'Object is missing these keys:',
      R.difference(R.keys(defaultCaseInputValues), R.keys(cases.p90))
    )
  }

  for (let key in defaultCaseInputValues) {
    if (key === 'capitalGross') {
      CASE_HEADERS_INPUT_ONLY.forEach((item) => {
        validatedCaseInputs[item][key] = !cases?.[item]?.[key]
          ? defaultCaseInputValues[key]
          : cases?.[item]?.[key]?.map((capitalItem, index) => {
              if (typeof capitalItem === 'string') {
                if (isNaN(Number(capitalItem.replace(/,/g, '')))) {
                  console.log('Not a number!')
                  errors.push({
                    errorLocation: 'cases',
                    errorCase: item,
                    errorKey: 'capitalGross',
                    errorValue: cases?.[item]?.capitalGross[index],
                    errorMessage: `Capital Gross item provided is invalid.`,
                  })
                }

                return Number(capitalItem.replace(/,/g, ''))
              }
              return Number(capitalItem)
            })
      })
    } else if (['prodForecastMethod', 'customProdForecast'].includes(key)) {
      CASE_HEADERS_INPUT_ONLY.forEach((item) => {
        validatedCaseInputs[item][key] = !cases?.[item]?.[key]
          ? defaultCaseInputValues[key]
          : cases[item][key]
        if (
          key === 'prodForecastMethod' &&
          cases?.[item]?.[key] === 'Custom Major Phase Forecast' &&
          (!cases?.[item]['customProdForecast'] ||
            cases?.[item]['customProdForecast']?.length === 0)
        ) {
          errors.push({
            errorLocation: 'cases',
            errorCase: item,
            errorKey: 'customProdForecast',
            errorValue: cases?.[item]?.['customProdForecast'],
            errorMessage: `Custom Forecast not provided. If 'Custom Major Phase Forecast' is selected as Forecast Method, a custom forecast must be provided.`,
          })
        }
      })
    } else if (key === 'hyperbolicExponent') {
      CASE_HEADERS_INPUT_ONLY.forEach((item) => {
        if (cases?.[item]?.prodForecastMethod?.includes('Hyperbolic')) {
          if (Number(cases?.[item]?.[key]) <= 0) {
            errors.push({
              errorLocation: 'cases',
              errorCase: item,
              errorKey: key,
              errorValue: cases[item][key],
              errorMessage: 'Hyperbolic Exponent must be greater than zero.',
            })
          } else if (
            cases?.[item]?.[key]?.toString().match(NUMERIC_VALIDATION_REGEX)
          ) {
            errors.push({
              errorLocation: 'cases',
              errorCase: item,
              errorKey: key,
              errorValue: cases[item][key],
              errorMessage: 'Hyperbolic Exponent must be a number.',
            })
          }
        }
        validatedCaseInputs[item][key] = !cases?.[item]?.[key]
          ? defaultCaseInputValues[key]
          : Number(cases[item][key])
      })
    } else if (key === 'terminalAnnualEffectiveDeclineRatePercent') {
      CASE_HEADERS_INPUT_ONLY.forEach((item) => {
        if (
          cases?.[item]?.prodForecastMethod?.includes(
            'Hyperbolic to Exponential'
          )
        ) {
          if (Number(cases?.[item]?.[key]) <= 0) {
            errors.push({
              errorLocation: 'cases',
              errorCase: item,
              errorKey: key,
              errorValue: cases[item][key],
              errorMessage: 'Terminal decline rate must be greater than zero.',
            })
          } else if (
            cases?.[item]?.[key]?.toString().match(NUMERIC_VALIDATION_REGEX)
          ) {
            errors.push({
              errorLocation: 'cases',
              errorCase: item,
              errorKey: key,
              errorValue: cases[item][key],
              errorMessage: 'Terminal decline rate must be a number.',
            })
          }
        }
        validatedCaseInputs[item][key] = !cases?.[item]?.[key]
          ? defaultCaseInputValues[key]
          : Number(cases[item][key])
      })
    } else {
      CASE_HEADERS_INPUT_ONLY.forEach((inputCase) => {
        if (
          cases?.[inputCase]?.[key]?.toString().match(NUMERIC_VALIDATION_REGEX)
        ) {
          errors.push({
            errorLocation: 'cases',
            errorCase: inputCase,
            errorKey: key,
            errorValue: cases[inputCase][key],
          })
        }
      })

      CASE_HEADERS_INPUT_ONLY.forEach((item) => {
        if(key === 'opexPerWellGross') {
          validatedCaseInputs[item][key] = cases[item][key];
          console.log("Validated gross well fixed cost:", validatedCaseInputs[item][key]);
        }
        else if (cases?.[item]?.[key] !== 0 && cases?.[item]?.[key]) {
          validatedCaseInputs[item][key] = Number(cases[item][key])
        } else if (cases?.[item]?.[key] === 0) {
          validatedCaseInputs[item][key] = cases[item][key]
        } else {
          validatedCaseInputs[item][key] = defaultCaseInputValues[key]
        }
      })
    }
  }

  if (errors.length > 0) {
    validatedCaseInputs.error = true
    validatedCaseInputs.errors = errors
  }

  return validatedCaseInputs
}

/**
 * This functon creates an array of arrays by reaching into the results object and grabbing
 * a particular cashflow monthly data array.
 *
 * The array (for example, the oilGross array), will be used in the creation of the array of
 * objects that will be passed to the PlotLineGraph component.
 *
 * Keith Elliott, 6/14/2020
 */
export const createCashflowItemArray = R.curry(
  (results, cashflowPropName, caseObjectNames) =>
    R.map(
      (caseObjectName) =>
        results?.[caseObjectName]?.cashflow?.[cashflowPropName]
    )(caseObjectNames)
)

export const convertMonthlyToDaily = (monthly) =>
  Array.isArray(monthly)
    ? R.map(convertMonthlyToDaily, monthly)
    : monthly / DAYS_PER_MONTH

/**
 * The PlotLineGraph component expects a graphDataArray property that contains an array of objects
 * that are in the shape created & returned by this function.
 *
 * I map through this createDataObject function with arrays of namePretty, nameCssClass and
 * monthlyDataArray. This creates the array of objects that will be sent to the Plot component.
 *
 * Keith Elliott, 6/14/2020
 */
export const createPlotDataObject = (
  namePretty,
  nameCssClass,
  monthlyDataArray
) => ({
  name: namePretty,
  nameCssClass: nameCssClass,
  monthlyData: monthlyDataArray,
})

/**
 * The caseNamePrettyArray and caseObjectNameArray are both arrays that are used in the
 * creation of the array of objects that will be passed to the PlotLineGraph component.
 *
 * The order of the two arrays should be consistent (i.e. the position of the 'p90' caseObjectName
 * item should jive with the 'P90 Low Case' caseNamePretty item).
 *
 * Keith Elliott, 6/16/2020
 */

// const grossOilArray = createCashflowItemArrayAndConvertToDaily(results, 'oilGross', caseObjectNames)
// console.log('grossOilArray:  ', grossOilArray);
// const grossGasArray = createCashflowItemArrayAndConvertToDaily(results, 'gasGross', caseObjectNames)
// const netBoeArray = createCashflowItemArrayAndConvertToDaily(results, 'boeNet', caseObjectNames)

const mapIndexed = R.addIndex(R.map)

export const createPlotDataObjectArraySpecificCases = R.curry(
  (caseObjectNameArray, caseNamePrettyArray, results, cashflowPropName) => {
    // Go-Do, KTE, 6/26/2020:  Consider the edge case where no monthly data exists.  At least provide an empty monthlyData array.
    const monthlyDataArrayofArrays = createCashflowItemArray(
      results,
      cashflowPropName,
      caseObjectNameArray
    )

    return mapIndexed((monthlyDataArray, i) =>
      createPlotDataObject(
        caseNamePrettyArray[i],
        caseObjectNameArray[i],
        monthlyDataArray
      )
    )(monthlyDataArrayofArrays)
  }
)

// (results, cashflowPropname) -> Array of all of the case objects (p90, p50, etc) that will be fed into PlotLineGraph
export const createPlotDataObjectArrayAllCases = createPlotDataObjectArraySpecificCases(
  caseObjectNameArray,
  caseNamePrettyArray
)

/**
 * @author Keith Elliott, July 12, 2020
 * @sig Object containing properties with array values => length of the longest array in the Object
 */
export const getMaxLengthObjectArrays = R.compose(
  R.reduce(R.max, 0),
  R.map(R.length),
  R.values
)

export const getMaxLengthIndexArray = R.compose(
  R.range(0),
  getMaxLengthObjectArrays
)

/**
 * I created this function to facilitate the common task of sorting then mapping
 * over a list of values.  I do this routinely when transforming raw data into JSX.
 *
 * In this function I perform the following...
 *  - Check to see if the provided array is null or undefined.  If so, it is returned unchanged.
 *  - Sort the array via the sortCallback function
 *  - map over the array with the provided mapCallback function
 *
 * Keith Elliott, 11/2/2020
 *
 * @param {function} sortCallback Callback function that will be used to sort the elements in the array.
 * The argument signature is (a, b).  It is passed to R.sort (see the R.sort docs for details).
 * @param {function} mapCallback Callback function that will be applied to the elements in the array.
 * The argument signature is (element, index).
 * @param {any[]} xs Array of values that will be sorted and mapped-over
 */
export const sortMap = (sortCallback, mapCallback, xs) =>
  R.ifElse(
    R.isNil,
    R.identity,
    R.compose(R.addIndex(R.map)(mapCallback), R.sort(sortCallback))
  )(xs)
