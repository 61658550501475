"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringChopIfNeeded = void 0;
function stringChopIfNeeded(x, maxLength) {
    var stringified = JSON.stringify(x === undefined ? "undefined" : x);
    return stringified.length > maxLength
        ? stringified.substring(0, maxLength) + "..."
        : stringified;
}
exports.stringChopIfNeeded = stringChopIfNeeded;
