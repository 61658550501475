"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trimToEconomicLifeStandard = exports.trimToEconomicLife = void 0;
var R = __importStar(require("ramda"));
var arrayUtil_1 = require("./arrayUtil");
var util_1 = require("./util");
var cumSum_1 = require("./cumSum");
/**
 * Trims the cashflow object to the economic limit.
 *
 * The array values in the cashflow object that are after the economic limit are changed
 * to zero.  (previously I just removed them, but later decided to change to zeros.)
 *
 * The economic limit is defined as the point at which the maximum value is
 * observed in the provided cumArrayForMaxCheck.  The array used for the max check should be
 * thoughtfully considered.  Net operating income is a common choice, in which case the cashflow
 * object will be trimmed when the net operating income reaches a maximum, which is right before
 * the cashflow case begins to loose money.
 *
 * Go-Do, KTE, 3/22/2020:  Consider implementing project settings that define the type of
 * economic limit (like we can do w/ ARIES).
 * Go-Do, KTE, 4/3/2020:  Visit the above Go-Do soon.  Need to implement shutting down marginal project,
 * or letting it run, even though the investment is poor.
 *
 * @param {number} maxLifeYears The total number of years represented in the monthly cashflow arrays.
 * @param {number[]} cumArrayForMaxCheck The array from which a max value will be deteremined.  The
 * index of this max value will be the economic limit.
 * @param {Cashflow} cashflowObject The cashflow object containing arrays of values
 * @param {number[]} arrayForMinCheck The array used to determine a minimum life.  This is typically
 * an array containing the capital, which allows us to ensure that the life lasts through at least
 * the initial capital expenditures.
 *  @example
 *
 *   const cashflow = trimToEconomicLife(cumSum(configMaxYears, cashflowRaw.presentValue10), cashflowRaw.capitalGross, cashflowRaw)
 */
var trimToEconomicLife = function (maxLifeYears, cumArrayForMaxCheck, arrayForMinCheck, cashflowObject) {
    return R.compose((0, arrayUtil_1.appendZerosToMaxLifeYearsForObjectOfArrays)(maxLifeYears), (0, arrayUtil_1.trimObjectOfArraysToIndex)(cashflowObject), R.max((0, arrayUtil_1.findLastNonZeroIndex)(arrayForMinCheck)), arrayUtil_1.findMaxCumIndex)(cumArrayForMaxCheck);
};
exports.trimToEconomicLife = trimToEconomicLife;
var trimToEconomicLifeStandard = function (cashflowObject) {
    return (0, exports.trimToEconomicLife)(util_1.CONFIG_MAX_YEARS, (0, cumSum_1.cumSum)(cashflowObject['netOperatingIncome']), cashflowObject['capitalGross'], cashflowObject);
};
exports.trimToEconomicLifeStandard = trimToEconomicLifeStandard;
