"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.vOperatorWithDefault = void 0;
var R = __importStar(require("ramda"));
var stringChopIfNeeded_1 = require("./stringChopIfNeeded");
var vOperator_1 = require("./vOperator");
/**
 * Vectorized Operator, which handles unequal length arrays by appending the defaultValue to the
 * shorter array.
 *
 * After any array extending occurs, this function calls vOperator.
 *
 * Keith Elliott, 7/6/2020, updated on 12/14/2021
 *
 * Go-Do, KTE, 12/14/2021:  Consider if I need a vOperatorScalar version of this.
 *    Previously the function (sorta) handled scalars.  But, upon my update, I
 *    decided to remove the scalar functionality for now.
 *
 * @seeAlso vOperator
 * @func
 * @param {Function} fn A function that accepts x and y as arguments, in that order, and returns one result
 * @param {Number} defaultValue A value that will be appended to the shorter
 * array, if needed.
 * @param {Array} x An array or scalar value
 * @param {Array} y An array or scalar value
 * @returns {Array} An array containing the element-wise result of the callback function
 *
 * @example
 *
 *   vOperatorWithDefault(R.multiply, 1, [1,2,3,4], [3,4]);  //=> [3,8,3,4]
 *   vOperatorWithDefault(R.multiply, 0, [1,2], 3) //=> [3,6]
 *   vOperatorWithDefault(R.multiply, 0, [1,2,3], [3,4]) //=> [3,8,0]
 */
function vOperatorWithDefault(fn) {
    return function (defaultValue) { return function (x) { return function (y) {
        if (!Array.isArray(x) || !Array.isArray(y)) {
            // Go-Do, KTE, 12/10/2021:  Do I need this check if I'm using TypeScript?
            throw new Error("Unexpected input.  x and y must be arrays.\n      The provided arguments follow:\n      x = ".concat((0, stringChopIfNeeded_1.stringChopIfNeeded)(x, 10), "\n      y = ").concat((0, stringChopIfNeeded_1.stringChopIfNeeded)(y, 10)));
        }
        return x.length < y.length
            ? (0, vOperator_1.vOperator)(fn)(R.concat(x, R.repeat(defaultValue, y.length - x.length)))(y)
            : (0, vOperator_1.vOperator)(fn)(x)(R.concat(y, R.repeat(defaultValue, x.length - y.length)));
    }; }; };
}
exports.vOperatorWithDefault = vOperatorWithDefault;
