"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcAssociatedGasProducts = exports.calcAssociatedOilProducts = exports.prepareProduction = exports.CONDENSATE_YIELD_UNDEFINED_DEFAULT = exports.GAS_OIL_RATIO_UNDEFINED_DEFAULT = void 0;
var R = __importStar(require("ramda"));
var arrayUtil_1 = require("./arrayUtil");
var calcEffectiveDeclineExponential_1 = require("./calcEffectiveDeclineExponential");
var calcLifeExponential_1 = require("./calcLifeExponential");
var calcNominalDeclineExponential_1 = require("./calcNominalDeclineExponential");
var calcNominalDeclineExponentialFromRateReserves_1 = require("./calcNominalDeclineExponentialFromRateReserves");
var calcNominalDeclineSecantMethod_1 = require("./calcNominalDeclineSecantMethod");
var calcRateExponential_1 = require("./calcRateExponential");
var calcRateHyperbolic_1 = require("./calcRateHyperbolic");
var calcRateModifiedArps_1 = require("./calcRateModifiedArps");
var oilAndGas_1 = require("./oilAndGas");
exports.GAS_OIL_RATIO_UNDEFINED_DEFAULT = 0; // For gas wells, GOR is not defined.  It is convenient to define the value as a number, instead of undefined.  So, I set it to a default value here.
exports.CONDENSATE_YIELD_UNDEFINED_DEFAULT = 0; // For gas wells, GOR is not defined.  It is convenient to define the value as a number, instead of undefined.  So, I set it to a default value here.
var prepareProduction = function (configMaxYears, prodMajorPhaseParameters, prodForecastParameters) {
    if (prodMajorPhaseParameters.major === 'Oil') {
        return R.compose((0, exports.calcAssociatedOilProducts)(configMaxYears, prodMajorPhaseParameters.gasOilRatio, prodMajorPhaseParameters.nglYield), (0, arrayUtil_1.appendZerosToMaxLifeYearsIfArray)(configMaxYears))(selectAndCalcProduction(configMaxYears, prodForecastParameters));
    }
    if (prodMajorPhaseParameters.major === 'Gas') {
        return R.compose((0, exports.calcAssociatedGasProducts)(configMaxYears, prodMajorPhaseParameters.condensateYield, prodMajorPhaseParameters.nglYield), (0, arrayUtil_1.appendZerosToMaxLifeYearsIfArray)(configMaxYears))(selectAndCalcProduction(configMaxYears, prodForecastParameters));
    }
    throw new Error("Unexpected input.  Major must be either 'Oil' or 'Gas'.");
};
exports.prepareProduction = prepareProduction;
var calcAssociatedOilProducts = function (configMaxYears, gasOilRatio, nglYield) {
    return function (oilGross) { return ({
        oilGross: oilGross,
        gasGross: (0, oilAndGas_1.calcGasFromGasOilRatio)(gasOilRatio, oilGross),
        gasOilRatio: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(gasOilRatio),
        condensateYield: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(exports.CONDENSATE_YIELD_UNDEFINED_DEFAULT),
        nglYield: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(nglYield), // NGL Yield;  Monthly NGL volume is calculated in calcCashflow
    }); };
};
exports.calcAssociatedOilProducts = calcAssociatedOilProducts;
var calcAssociatedGasProducts = function (configMaxYears, condensateYield, nglYield) {
    return function (gasGross) { return ({
        oilGross: (0, oilAndGas_1.calcOilFromCondensateYield)(condensateYield, gasGross),
        gasGross: gasGross,
        gasOilRatio: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(exports.GAS_OIL_RATIO_UNDEFINED_DEFAULT),
        condensateYield: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(condensateYield),
        nglYield: R.compose((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(configMaxYears), arrayUtil_1.convertToArray)(nglYield), // NGL Yield;  Monthly NGL volume is calculated in calcCashflow
    }); };
};
exports.calcAssociatedGasProducts = calcAssociatedGasProducts;
var selectAndCalcProduction = function (configMaxYears, prodForecastParameters) {
    if (prodForecastParameters.prodForecastMethod ===
        'Exponential: Calc Reserves and Final Rate') {
        return (0, calcRateExponential_1.calcRateExponential)((prodForecastParameters.initialDailyRate * 365) / 12, // daily provided, but monthly is stored in the array
        (0, calcNominalDeclineExponential_1.calcNominalDeclineExponential)(prodForecastParameters.annualEffectiveDeclineRatePercent), R.range(0, Math.min(configMaxYears, prodForecastParameters.maxLifeYears) * 12));
    }
    if (prodForecastParameters.prodForecastMethod ===
        'Exponential: Calc Decline and Life') {
        var nominalDeclineRate = (0, calcNominalDeclineExponentialFromRateReserves_1.calcNominalDeclineExponentialFromRateReserves)(prodForecastParameters.initialDailyRate, prodForecastParameters.finalDailyRate, prodForecastParameters.reserves);
        var calculatedLife = (0, calcLifeExponential_1.calcLifeExponential)(prodForecastParameters.initialDailyRate, prodForecastParameters.finalDailyRate, nominalDeclineRate);
        var calculatedAnnualEffectiveDeclineRatePercent = (0, calcEffectiveDeclineExponential_1.calcEffectiveDeclineExponential)(nominalDeclineRate) * 100;
        return (0, calcRateExponential_1.calcRateExponential)((prodForecastParameters.initialDailyRate * 365) / 12, // daily provided, but monthly is stored in the array
        (0, calcNominalDeclineExponential_1.calcNominalDeclineExponential)(calculatedAnnualEffectiveDeclineRatePercent), R.range(0, Math.min(configMaxYears, calculatedLife) * 12));
    }
    if (prodForecastParameters.prodForecastMethod ===
        'Hyperbolic: Calc Reserves and Final Rate') {
        return (0, calcRateHyperbolic_1.calcRateHyperbolic)((prodForecastParameters.initialDailyRate * 365) / 12, // daily provided, but monthly is stored in the array
        (0, calcNominalDeclineSecantMethod_1.calcNominalDeclineSecantMethod)(prodForecastParameters.annualEffectiveDeclineRatePercent, prodForecastParameters.hyperbolicExponent), prodForecastParameters.hyperbolicExponent, R.range(0, Math.min(configMaxYears, prodForecastParameters.maxLifeYears) * 12));
    }
    if (prodForecastParameters.prodForecastMethod ===
        'Hyperbolic to Exponential: Calc Reserves and Final Rate') {
        return (0, calcRateModifiedArps_1.calcRateModifiedArps)((prodForecastParameters.initialDailyRate * 365) / 12, // daily provided, but monthly is stored in the array
        (0, calcNominalDeclineSecantMethod_1.calcNominalDeclineSecantMethod)(prodForecastParameters.annualEffectiveDeclineRatePercent, prodForecastParameters.hyperbolicExponent), (0, calcNominalDeclineExponential_1.calcNominalDeclineExponential)(prodForecastParameters.terminalAnnualEffectiveDeclineRatePercent), prodForecastParameters.hyperbolicExponent, R.range(0, Math.min(configMaxYears, prodForecastParameters.maxLifeYears) * 12));
    }
    if (prodForecastParameters.prodForecastMethod === 'Custom Major Phase Forecast') {
        return prodForecastParameters.customProdForecast;
    }
    throw new Error("Invalid input.  prodForecastMethod is not recognized.  The\n    selectAndCalcProduction function may need to be updated to handle a new method.\n    Or, provide an appropriate prodForecastMethod value.\n\n    Valid options include:\n      Exponential: Calc Reserves and Final Rate\n      Exponential: Calc Decline and Life\n      Hyperbolic: Calc Reserves and Final Rate\n      Hyperbolic to Exponential: Calc Reserves and Final Rate\n      Custom Major Phase Forecast\n    ");
};
