"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcEffectiveDeclineTangentMethod = void 0;
/**
 * Converts a nominal decline rate to an effective decline rate
 *
 * @func
 * @param {Number} nominalDeclineRate The nominal decline rate in decimal (not percentage) format
 * @returns {Number} The effective decline rate, in decimal (not percentage) format
 * @example
 *
 *   calcEffectiveDeclineTangentMethod(.69314);  //=> .5
 */
var calcEffectiveDeclineTangentMethod = function (nominalDeclineRate) {
    return 1 - Math.exp(-1 * nominalDeclineRate);
};
exports.calcEffectiveDeclineTangentMethod = calcEffectiveDeclineTangentMethod;
