"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcOilFromCondensateYield = exports.calcNgl = exports.calcGasFromGasOilRatio = void 0;
var vDivide_1 = require("./vDivide");
var vMultiply_1 = require("./vMultiply");
function calcGasFromGasOilRatio(gasOilRatio, oilGross) {
    return (0, vMultiply_1.vMultiply)(oilGross)(gasOilRatio);
}
exports.calcGasFromGasOilRatio = calcGasFromGasOilRatio;
function calcNgl(nglYield, gasGross) {
    return (0, vMultiply_1.vMultiply)((0, vDivide_1.vDivide)(gasGross)(1000))(nglYield);
}
exports.calcNgl = calcNgl;
function calcOilFromCondensateYield(condensateYield, gasGross) {
    return (0, vMultiply_1.vMultiply)((0, vDivide_1.vDivide)(gasGross)(1000))(condensateYield);
}
exports.calcOilFromCondensateYield = calcOilFromCondensateYield;
