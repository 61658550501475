import thunk from 'redux-thunk'
// import logger from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux'

import reducer from '../reducers'

const _ = require('lodash')

// Allow for examination of Redux store in development mode
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk)) //, logger)),
)

// Below code subscribes to all store changes in order to save a piece of the app state in localStorage for use by Print View page
store.subscribe(() => {
  const filteredState = _.pick(
    store.getState(),
    'cases',
    'projectInputs',
    'results',
    'pricingInputs',
    'tornado'
  )
  try {
    localStorage?.setItem('localState', JSON.stringify(filteredState))
  } catch (err) {
    console.log('Error while attempting to set localState:  ', err)
  }
})

export default store
