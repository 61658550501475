"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcNominalDeclineSecantMethod = void 0;
function calcNominalDeclineSecantMethod(annualEffectiveDeclineRatePercent, b) {
    if (b <= 0) {
        throw new Error("Invalid input. The hyperbolic exponent, b, must be greater than zero.\n      The provided value equals ".concat(b));
    }
    return (Math.pow((1 - annualEffectiveDeclineRatePercent / 100), (-1 * b)) - 1) / b;
}
exports.calcNominalDeclineSecantMethod = calcNominalDeclineSecantMethod;
