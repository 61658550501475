"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcCashflow = void 0;
var vAdd_1 = require("./vAdd");
var vDivide_1 = require("./vDivide");
var vMultiply_1 = require("./vMultiply");
var vSubtract_1 = require("./vSubtract");
var arrayUtil_1 = require("./arrayUtil");
var calcEconomicMetrics_1 = require("./calcEconomicMetrics");
var oilAndGas_1 = require("./oilAndGas");
var R = __importStar(require("ramda"));
/**
 * Conventions...
 *  - Capital and oil & gas production forecasts are provided to this function
 *  - Production begins at index 1 + delayStart.  The shifting occurs prior to this function.
 *    No shifting occurs in this function.  Go-Do:  Create shiftCapexAndProd function
 *  - The capitalGross, oilGross and gasGross arrays are trimmed to the maxLifeYears prior
 *    to this function.  Go-Do:   Create trimTomaxLifeYears function
 *  - The capitalGross, oilGross and gasGross arrays are equal length, with zeros appended as
 *    needed, with length equal to maxLifeYears
 *  - OPEX only exists when production rate is not equal to zero
 *  - CAPEX is passed in via input array.  Conceptually, capital typically will
 *    begin at time zero.  Hence, most of the time capital should be in the index 0
 *    position in the array.   So, if [100000] is passed in via the capitalGross
 *    array, it will be placed at index zero.
 *  - Monthly values are expected, and therefore calculated.
 *    Note:  I previously included a monthNumber array.  I excluded this.  The index of the array
 *    inherently refers to the month.
 *  - Rounding conventions:
 *      Money values are rounded to hundredths of a dollar (i.e. rounded to pennies)
 *      Oil and BOE values are rounded to tenths of a barrel.
 *      Gas values are rounded to ones (i.e. whole MCF values)
 * @param {Object} input Go-Do:  consider breaking this apart in an object destructuring fashion
 * @param {Object} object containing capitalGross, oilGross and gasGross
 */
var calcCashflow = function (input
//   {
//   priceBaseOil,
//   priceBaseGas,
//   priceDiffOil,
//   priceDiffGas,
//   nglPricePercent,
//   workingInterestPercent,
//   netRevenueInterestPercent,
//   shrinkPercent,
//   capitalGross,
//   oilGross,
//   gasGross,
//   gasOilRatio,
//   condensateYield,
//   nglYield,
//   adValTaxRatePercent,
//   gasSevTaxRatePercent,
//   gasProdTaxRate,
//   oilSevTaxRatePercent,
//   oilProdTaxRate,
//   nglSevTaxRatePercent,
//   nglProdTaxRate,
//   opexPerWellGross,
//   opexVariableOilGross,
//   opexVariableGasGross,
//   overheadGross,
// }
) {
    var priceFieldOil = (0, vAdd_1.vAdd)(input.priceBaseOil)(input.priceDiffOil);
    var priceFieldGas = (0, vAdd_1.vAdd)(input.priceBaseGas)(input.priceDiffGas);
    var priceFieldNgl = (0, vMultiply_1.vMultiply)(input.priceBaseOil)((0, vDivide_1.vDivide)(input.nglPricePercent)(100));
    var capitalNet = (0, vMultiply_1.vMultiply)(input.capitalGross)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var nglGross = (0, oilAndGas_1.calcNgl)(input.nglYield, input.gasGross); // KTE, 9/20/2020:  I confirmed that ARIES and our Reserves LOS Workbooks calculate NGL yield by dividing gross gas production (pre-shrink) by NGL sold volume.
    var boeGross = R.compose((0, vAdd_1.vAdd)(input.oilGross), (0, vAdd_1.vAdd)(nglGross), (0, vMultiply_1.vMultiply)(input.gasGross))(1 / 6);
    var oilNet = (0, vMultiply_1.vMultiply)(input.oilGross)((0, vDivide_1.vDivide)(input.netRevenueInterestPercent)(100));
    var gasGrossLessShrink = (0, vMultiply_1.vMultiply)(input.gasGross)((0, vDivide_1.vDivide)(input.shrinkPercent)(100));
    var gasWorkingInterestShare = (0, vMultiply_1.vMultiply)(gasGrossLessShrink)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var gasNet = (0, vMultiply_1.vMultiply)(gasGrossLessShrink)((0, vDivide_1.vDivide)(input.netRevenueInterestPercent)(100));
    var nglNet = (0, vMultiply_1.vMultiply)(nglGross)((0, vDivide_1.vDivide)(input.netRevenueInterestPercent)(100));
    var boeNet = R.compose((0, vAdd_1.vAdd)(oilNet), (0, vAdd_1.vAdd)(nglNet), (0, vMultiply_1.vMultiply)(gasNet))(1 / 6);
    var revenueOil = (0, vMultiply_1.vMultiply)(oilNet)(priceFieldOil);
    var revenueGas = (0, vMultiply_1.vMultiply)(gasNet)(priceFieldGas);
    var revenueNgl = (0, vMultiply_1.vMultiply)(nglNet)(priceFieldNgl);
    var revenueTotal = R.compose((0, vAdd_1.vAdd)(revenueOil), (0, vAdd_1.vAdd)(revenueGas))(revenueNgl);
    var opexPerWellNet = (0, vMultiply_1.vMultiply)(input.opexPerWellGross)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var opexVariableOilSumGross = (0, vMultiply_1.vMultiply)(input.oilGross)(input.opexVariableOilGross);
    var opexVariableOilSumNet = (0, vMultiply_1.vMultiply)(opexVariableOilSumGross)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var opexVariableGasSumGross = (0, vMultiply_1.vMultiply)(gasGrossLessShrink)(input.opexVariableGasGross);
    var opexVariableGasSumNet = (0, vMultiply_1.vMultiply)(opexVariableGasSumGross)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var overheadNet = (0, vMultiply_1.vMultiply)(input.overheadGross)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var opexTotalGross = R.compose((0, vAdd_1.vAdd)(input.overheadGross), (0, vAdd_1.vAdd)(opexVariableGasSumGross), (0, vAdd_1.vAdd)(opexVariableOilSumGross))(input.opexPerWellGross);
    // // KTE, 8/14/2020:  Note that overhead is included in the above opexTotalGross.
    // // Reed and I compared against legacy HEConomics - it is included in the opex total in the
    // // legacy version.  So, we'll be consistent.
    //
    var opexTotalNet = (0, vMultiply_1.vMultiply)(opexTotalGross)((0, vDivide_1.vDivide)(input.workingInterestPercent)(100));
    var adValTaxNet = (0, vMultiply_1.vMultiply)(revenueTotal)((0, vDivide_1.vDivide)(input.adValTaxRatePercent)(100));
    var gasSevTaxNet = (0, vMultiply_1.vMultiply)(revenueGas)((0, vDivide_1.vDivide)(input.gasSevTaxRatePercent)(100));
    var gasProdTaxNet = (0, vMultiply_1.vMultiply)(gasNet)(input.gasProdTaxRate);
    var oilSevTaxNet = (0, vMultiply_1.vMultiply)(revenueOil)((0, vDivide_1.vDivide)(input.oilSevTaxRatePercent)(100));
    var oilProdTaxNet = (0, vMultiply_1.vMultiply)(oilNet)(input.oilProdTaxRate);
    var nglSevTaxNet = (0, vMultiply_1.vMultiply)(revenueNgl)((0, vDivide_1.vDivide)(input.nglSevTaxRatePercent)(100));
    var nglProdTaxNet = (0, vMultiply_1.vMultiply)(nglNet)(input.nglProdTaxRate);
    var sevTaxNet = R.compose((0, vAdd_1.vAdd)(nglProdTaxNet), (0, vAdd_1.vAdd)(nglSevTaxNet), (0, vAdd_1.vAdd)(oilProdTaxNet), (0, vAdd_1.vAdd)(oilSevTaxNet), (0, vAdd_1.vAdd)(gasProdTaxNet))(gasSevTaxNet);
    var opexOverheadTaxTotalNet = R.compose((0, vAdd_1.vAdd)(sevTaxNet), (0, vAdd_1.vAdd)(adValTaxNet))(opexTotalNet);
    var netOperatingIncome = (0, vSubtract_1.vSubtract)(revenueTotal)(opexOverheadTaxTotalNet); // this is also called operating profit
    var netIncome = (0, vSubtract_1.vSubtract)(revenueTotal)((0, vAdd_1.vAdd)(opexOverheadTaxTotalNet)(capitalNet)); // this is also called net income
    var yearNumber = (0, arrayUtil_1.createYearArray)(boeNet.length);
    var presentValue10 = (0, calcEconomicMetrics_1.calcPresentValue)(10, yearNumber, netIncome);
    var presentValue15 = (0, calcEconomicMetrics_1.calcPresentValue)(15, yearNumber, netIncome);
    var presentValue20 = (0, calcEconomicMetrics_1.calcPresentValue)(20, yearNumber, netIncome);
    var presentValue25 = (0, calcEconomicMetrics_1.calcPresentValue)(25, yearNumber, netIncome);
    var presentValue50 = (0, calcEconomicMetrics_1.calcPresentValue)(50, yearNumber, netIncome);
    var presentValue100 = (0, calcEconomicMetrics_1.calcPresentValue)(100, yearNumber, netIncome);
    var presentValue200 = (0, calcEconomicMetrics_1.calcPresentValue)(200, yearNumber, netIncome);
    var presentValue300 = (0, calcEconomicMetrics_1.calcPresentValue)(300, yearNumber, netIncome);
    var capitalNetDiscounted10 = (0, calcEconomicMetrics_1.calcPresentValue)(10, yearNumber, capitalNet);
    var capitalNetDiscounted15 = (0, calcEconomicMetrics_1.calcPresentValue)(15, yearNumber, capitalNet);
    return {
        priceBaseOil: input.priceBaseOil,
        priceDiffOil: input.priceDiffOil,
        priceFieldOil: priceFieldOil,
        priceBaseGas: input.priceBaseGas,
        priceDiffGas: input.priceDiffGas,
        priceFieldGas: priceFieldGas,
        nglPricePercent: input.nglPricePercent,
        priceFieldNgl: priceFieldNgl,
        workingInterestPercent: input.workingInterestPercent,
        netRevenueInterestPercent: input.netRevenueInterestPercent,
        capitalGross: input.capitalGross,
        capitalNet: capitalNet,
        oilGross: input.oilGross,
        gasGross: input.gasGross,
        gasOilRatio: input.gasOilRatio,
        condensateYield: input.condensateYield,
        nglYield: input.nglYield,
        nglGross: nglGross,
        boeGross: boeGross,
        oilNet: oilNet,
        shrinkPercent: input.shrinkPercent,
        gasGrossLessShrink: gasGrossLessShrink,
        gasWorkingInterestShare: gasWorkingInterestShare,
        gasNet: gasNet,
        nglNet: nglNet,
        boeNet: boeNet,
        revenueOil: revenueOil,
        revenueGas: revenueGas,
        revenueNgl: revenueNgl,
        revenueTotal: revenueTotal,
        opexPerWellGross: input.opexPerWellGross,
        opexPerWellNet: opexPerWellNet,
        opexVariableOilGross: input.opexVariableOilGross,
        opexVariableOilSumGross: opexVariableOilSumGross,
        // Go-Do, KTE, 9/12/2020:  Update HEConomics to handle the variable name change in the output; I added the word Sum
        opexVariableOilSumNet: opexVariableOilSumNet,
        opexVariableGasGross: input.opexVariableGasGross,
        opexVariableGasSumGross: opexVariableGasSumGross,
        opexVariableGasSumNet: opexVariableGasSumNet,
        overheadGross: input.overheadGross,
        overheadNet: overheadNet,
        opexTotalGross: opexTotalGross,
        opexTotalNet: opexTotalNet,
        adValTaxRatePercent: input.adValTaxRatePercent,
        adValTaxNet: adValTaxNet,
        gasSevTaxRatePercent: input.gasSevTaxRatePercent,
        gasSevTaxNet: gasSevTaxNet,
        gasProdTaxRate: input.gasProdTaxRate,
        gasProdTaxNet: gasProdTaxNet,
        oilSevTaxRatePercent: input.oilSevTaxRatePercent,
        oilSevTaxNet: oilSevTaxNet,
        oilProdTaxRate: input.oilProdTaxRate,
        oilProdTaxNet: oilProdTaxNet,
        nglSevTaxRatePercent: input.nglSevTaxRatePercent,
        nglSevTaxNet: nglSevTaxNet,
        nglProdTaxRate: input.nglProdTaxRate,
        nglProdTaxNet: nglProdTaxNet,
        sevTaxNet: sevTaxNet,
        opexOverheadTaxTotalNet: opexOverheadTaxTotalNet,
        netOperatingIncome: netOperatingIncome,
        netIncome: netIncome,
        yearNumber: yearNumber,
        presentValue10: presentValue10,
        presentValue15: presentValue15,
        presentValue20: presentValue20,
        presentValue25: presentValue25,
        presentValue50: presentValue50,
        presentValue100: presentValue100,
        presentValue200: presentValue200,
        presentValue300: presentValue300,
        capitalNetDiscounted10: capitalNetDiscounted10,
        capitalNetDiscounted15: capitalNetDiscounted15,
    };
};
exports.calcCashflow = calcCashflow;
