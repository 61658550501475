"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTornadoPvr10 = exports.calcPvr10GivenCashflow = exports.calcTornado = exports.TornadoRow = exports.alignCashflowInputArrayLength = void 0;
var R = __importStar(require("ramda"));
var arrayUtil_1 = require("./arrayUtil");
var calcCashflow_1 = require("./calcCashflow");
var calcEconomicMetrics_1 = require("./calcEconomicMetrics");
var oilAndGas_1 = require("./oilAndGas");
var round_1 = require("./round");
var trimToEconomicLife_1 = require("./trimToEconomicLife");
var util_1 = require("./util");
var validateInput_1 = require("./validateInput");
/**
 * calcCashflow expects that the input properties are equal length arrays.
 *
 * This function trims (or extends) arrays to the length of oilGross or gasGross.  See the error
 * checking in the validateCashflowInput function.
 *
 * @author Keith Elliott, 9/27/2020, 10/9/2022
 */
var alignCashflowInputArrayLength = function (cashflowInput) {
    /*
    if (!Array.isArray(cashflowInput.oilGross) || !Array.isArray(cashflowInput.gasGross)) {
      throw new Error('Cashflow Input Error:  The oilGross and gasGross properties should be arrays.');
    }
  
    if (cashflowInput.oilGross.length != cashflowInput.gasGross.length) {
      throw new Error('Cashflow Input Error:  The oilGross and gasGross arrays should be the same length.');
    }
    */
    var majorPhaseArrayLength = cashflowInput.oilGross === undefined
        ? cashflowInput.gasGross.length
        : cashflowInput.oilGross.length;
    return R.mapObjIndexed(function (cashflowPropValue, cashflowPropName) {
        if (cashflowPropName === 'oilGross' || cashflowPropName === 'gasGross') {
            return cashflowPropValue; // return oilGross and gasGross unchanged
        }
        // if (!Array.isArray(cashflowPropValue)) {
        //   return cashflowPropValue // return scalars unchanged
        // } // KTE, 10/9/2022:  Refactored CashflowInputAll, ensuring all inputs are number[]
        if (cashflowPropValue.length >= majorPhaseArrayLength) {
            return R.slice(0, majorPhaseArrayLength, cashflowPropValue); // trim long arrays to the length of oilGross
        }
        // Remaining properties are arrays that are shorter than oilGross.length.
        // These will be extended.  capitalGross will be extended with zero monthly values appended.
        // The remaining properties will be extended, with their last value appended.
        if (cashflowPropName === 'capitalGross') {
            return (0, arrayUtil_1.appendValues)(0)(majorPhaseArrayLength - cashflowPropValue.length, cashflowPropValue);
        }
        return (0, arrayUtil_1.appendLastValue)(majorPhaseArrayLength - cashflowPropValue.length, cashflowPropValue);
    })(cashflowInput);
};
exports.alignCashflowInputArrayLength = alignCashflowInputArrayLength;
var TornadoRow = /** @class */ (function () {
    function TornadoRow(rowName, propertyName, propertyValueOptimistic, propertyValuePessimistic, propertyValueBase, reducer // ((x: number[] | number) => number)
    ) {
        this.rowName = rowName;
        this.propertyName = propertyName;
        this.propertyValueOptimistic = propertyValueOptimistic;
        this.propertyValuePessimistic = propertyValuePessimistic;
        this.propertyValueBase = propertyValueBase;
        this.reducedPropertyValueOptimistic = reducer(propertyValueOptimistic);
        this.reducedPropertyValuePessimistic = reducer(propertyValuePessimistic);
        this.reducedPropertyValueBase = reducer(propertyValueBase);
    }
    return TornadoRow;
}());
exports.TornadoRow = TornadoRow;
function calcTornado(metricCalcCallback) {
    return function (major, baseCashflow, tornadoRowArray) {
        return R.map(function (tornadoRow) {
            var _a, _b;
            return __assign(__assign({}, tornadoRow), { metricOptimistic: R.compose(metricCalcCallback, trimToEconomicLife_1.trimToEconomicLifeStandard, calcCashflow_1.calcCashflow, exports.alignCashflowInputArrayLength, validateInput_1.validateCashflowInput
                // )({...setNonMajorPhaseToUndefined(major, baseCashflow), ...tornadoRow.cashflowSubstitutionOptimistic}),
                )(__assign(__assign({}, refreshProductionIfNeeded(major, tornadoRow.propertyName, tornadoRow.propertyValueOptimistic, baseCashflow)), (_a = {}, _a[tornadoRow.propertyName] = Array.isArray(tornadoRow.propertyValueOptimistic)
                    ? tornadoRow.propertyValueOptimistic
                    : [tornadoRow.propertyValueOptimistic], _a))), metricPessimistic: R.compose(metricCalcCallback, trimToEconomicLife_1.trimToEconomicLifeStandard, calcCashflow_1.calcCashflow, exports.alignCashflowInputArrayLength, validateInput_1.validateCashflowInput
                // )({...setNonMajorPhaseToUndefined(major, baseCashflow), ...tornadoRow.cashflowSubstitutionPessimistic}),
                )(__assign(__assign({}, refreshProductionIfNeeded(major, tornadoRow.propertyName, tornadoRow.propertyValuePessimistic, baseCashflow)), (_b = {}, _b[tornadoRow.propertyName] = Array.isArray(tornadoRow.propertyValuePessimistic)
                    ? tornadoRow.propertyValuePessimistic
                    : [tornadoRow.propertyValuePessimistic], _b))) });
        })(tornadoRowArray);
    };
}
exports.calcTornado = calcTornado;
var calcPvr10GivenCashflow = function (cashflow) {
    return (0, round_1.round2)((0, calcEconomicMetrics_1.calcPVR)(R.sum(cashflow.presentValue10), R.sum(cashflow.capitalNetDiscounted10)));
};
exports.calcPvr10GivenCashflow = calcPvr10GivenCashflow;
exports.calcTornadoPvr10 = calcTornado(exports.calcPvr10GivenCashflow);
/**
 * calcCashflow assumes that the major phase and associated phase are both populated.
 *
 * If the variable that is adjusting is the major phase, then the associated phase
 * needs to be adjusted.
 *
 * Likewise, if the gasOilRatio or the condensateYield is adjusted, then the associated
 * phase needs to be adjusted.
 *
 * This function handles the logic noted above.
 */
function refreshProductionIfNeeded(major, tornadoRowPropertyName, tornadoRowValue, baseCashflow) {
    if (tornadoRowPropertyName === 'oilGross') {
        if (major !== 'Oil') {
            throw new Error("Tornado calculation logic error:\n      The major phase should be Oil, because the oilGross property was provided for the\n      tornado calculation.  To fix this error, ensure that you only analyze oilGross\n      when major equals Oil.");
        }
        return __assign(__assign({}, baseCashflow), { oilGross: Array.isArray(tornadoRowValue)
                ? tornadoRowValue
                : [tornadoRowValue], gasGross: (0, oilAndGas_1.calcGasFromGasOilRatio)((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(util_1.CONFIG_MAX_YEARS)(baseCashflow.gasOilRatio), Array.isArray(tornadoRowValue) ? tornadoRowValue : [tornadoRowValue]) });
    }
    else if (tornadoRowPropertyName === 'gasGross') {
        if (major !== 'Gas') {
            throw new Error("Tornado calculation logic error:\n      The major phase should be Gas, because the gasGross property was provided for the\n      tornado calculation.  To fix this error, ensure that you only analyze gasGross\n      when major equals Gas.");
        }
        return __assign(__assign({}, baseCashflow), { gasGross: Array.isArray(tornadoRowValue)
                ? tornadoRowValue
                : [tornadoRowValue], oilGross: (0, oilAndGas_1.calcOilFromCondensateYield)((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(util_1.CONFIG_MAX_YEARS)(baseCashflow.condensateYield), Array.isArray(tornadoRowValue) ? tornadoRowValue : [tornadoRowValue]) });
    }
    else if (tornadoRowPropertyName === 'gasOilRatio') {
        if (major !== 'Oil') {
            throw new Error("Tornado calculation logic error:\n      The major phase should be Oil, because the gasOilRatio property was provided for the\n      tornado calculation.  To fix this error, ensure that you only analyze gasOilRatio\n      when major equals Oil.");
        }
        return __assign(__assign({}, baseCashflow), { gasGross: (0, oilAndGas_1.calcGasFromGasOilRatio)((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(util_1.CONFIG_MAX_YEARS)(Array.isArray(tornadoRowValue) ? tornadoRowValue : [tornadoRowValue]), baseCashflow.oilGross) });
    }
    else if (tornadoRowPropertyName === 'condensateYield') {
        if (major !== 'Gas') {
            throw new Error("Tornado calculation logic error:\n    The major phase should be Gas, because the condensateYield property was provided for the\n    tornado calculation.  To fix this error, ensure that you only analyze condensateYield\n    when major equals Gas.");
        }
        return __assign(__assign({}, baseCashflow), { oilGross: (0, oilAndGas_1.calcOilFromCondensateYield)((0, arrayUtil_1.appendLastValueToMaxLifeYearsIfArray)(util_1.CONFIG_MAX_YEARS)(Array.isArray(tornadoRowValue) ? tornadoRowValue : [tornadoRowValue]), baseCashflow.gasGross) });
    }
    return baseCashflow;
}
