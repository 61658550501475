"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.vOperator = void 0;
var R = __importStar(require("ramda"));
var stringChopIfNeeded_1 = require("./stringChopIfNeeded");
// export function vOperator<T extends number, U extends number[]>(
//   fn: (a: number, b: number) => number
// ): {
//   (x: T): (y: T) => T
//   (x: T): (y: U) => U
//   (x: U): (y: T) => U
//   (x: T): (y: U) => U
// }
// export function vOperator(fn: (a: number, b: number) => number) {
//   return (x: number[] | number) => (y: number[] | number) => {
// export function vOperator(fn: (a: number, b: number) => number) {
//   return function (x: number[] | number) {
//     return function (y: number[] | number) {
function vOperator(fn) {
    return function (x) {
        return function (y) {
            // Go-Do, KTE, 12/10/2021:  Decide if I should use function declarations or function expressions
            // Go-Do, KTE, 12/10/2021:  Consider making this a generic???
            // if (!Array.isArray(x) && !Array.isArray(y)) {
            if (typeof x === 'number' && typeof y === 'number') {
                return fn(x, y);
            }
            if (Array.isArray(x) && Array.isArray(y)) {
                if (x.length <= y.length) {
                    return R.addIndex(R.map)(function (element, i) { return fn(element, y[i]); }, x);
                }
                else {
                    return R.addIndex(R.map)(function (element, i) { return fn(x[i], element); }, y);
                }
            }
            // if (Array.isArray(x) && !Array.isArray(y)) {
            if (Array.isArray(x) && typeof y === 'number') {
                return R.map(function (element) { return fn(element, y); }, x);
            }
            // if (!Array.isArray(x) && Array.isArray(y)) {
            if (typeof x === 'number' && Array.isArray(y)) {
                return R.map(function (element) { return fn(x, element); }, y);
            }
            throw new Error("Unexpected input.  Either x or y (or both) must be a number array (one can be a scalar number).\n        The provided arguments follow:\n        x = ".concat((0, stringChopIfNeeded_1.stringChopIfNeeded)(x, 10), "\n        y = ").concat((0, stringChopIfNeeded_1.stringChopIfNeeded)(y, 10)));
        };
    };
}
exports.vOperator = vOperator;
/*******************************************************************************

code prior to refactoring to Typescript (mostly)....

export const vOperator = R.curry(function (
  // export const vOperator = function (
  fn: (a: number, b: number) => number,
  x: number | number[],
  y: number | number[]
) {
  if (Array.isArray(x) && Array.isArray(y)) {
    if (x.length <= y.length) {
      return mapIndexed((element, i) => fn(element, y[i]), x)
    } else {
      return mapIndexed((element, i) => fn(x[i], element), y)
    }
  }

  if (Array.isArray(x) && isNumeric(y)) {
    return R.map((element) => fn(element, y), x)
  }

  if (Array.isArray(y) && isNumeric(x)) {
    return R.map((element) => fn(x, element), y)
  }

  if (!Array.isArray(x) && isNumeric(x) && !Array.isArray(y) && isNumeric(y)) {
    return fn(x, y)
  }

  throw new Error(`Unexpected input.  x and y must be either arrays or scalar values.
      The provided arguments follow:
        x = ${x}
        y = ${y}`)
})
*/
