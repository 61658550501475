"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCashflowInput = exports.validateProjectInput = exports.validateCaseInput = exports.validateInput = void 0;
var R = __importStar(require("ramda"));
/**
 * @param {String[]} requiredInputPropertyNames An array containing the property names required in the input object
 * @param {Object} input The input object in the form required by the calcCashflow function
 * @returns {Object} The same input object (or the function throws an error if the input object is not valid)
 * @example
 *
 *  valideateInput( ['correct'], {incorrect: 1} ) //=> throws error
 *  valideateInput( ['correct'], {correct: 1} ) //=> returns the object unchanged
 */
function validateInput(requiredInputPropertyNames) {
    return function (input) {
        // (input: { [prop: string]: any }): T => {
        var missingProperties = R.difference(requiredInputPropertyNames, R.keys(input));
        if (missingProperties.length > 0) {
            throw new Error("The input object must contain the following properties: ".concat(requiredInputPropertyNames, "\n      The following properties are missing:  ").concat(missingProperties));
        }
        // Go-Do, KTE, 4/6/2020:  Check for type and arrays...
        // Go-Do, KTE, 6/26/2020:  Pass in a descriptive name into this function.  When viewing the data, 'the input object' is not specific enough to be helpful
        // if ( ! Array.isArray(input.capitalGross) ) {
        //   throw new Error(`The capitalGross property should contain an array of capital numbers.
        //     However, the current value is ${input.capitalGross}`)
        // }
        return input;
    };
}
exports.validateInput = validateInput;
exports.validateCaseInput = validateInput([
    'capitalGross',
    'delayStart',
    'prodForecastMethod',
    'initialDailyRate',
    'finalDailyRate',
    'annualEffectiveDeclineRatePercent',
    'hyperbolicExponent',
    'reserves',
    'gasOilRatio',
    'condensateYield',
    'nglYield',
    'maxLifeYears',
    'opexPerWellGross',
    'opexVariableOilGross',
    'opexVariableGasGross',
    // 'opexYearlyExpenseAFEGross',
    'overheadGross',
]);
exports.validateProjectInput = validateInput([
    'major',
    'workingInterestPercent',
    'netRevenueInterestPercent',
    'shrinkPercent',
    'priceDiffOil',
    'priceDiffGas',
    'nglPricePercent',
    'adValTaxRatePercent',
    'successPs',
    'gasSevTaxRatePercent',
    'gasProdTaxRate',
    'oilSevTaxRatePercent',
    'oilProdTaxRate',
    'nglSevTaxRatePercent',
    'nglProdTaxRate',
    //'sevTaxRatePercent',
]);
var validateCashflowInput = function (cashflowInput) {
    // if (
    //   cashflowInput.oilGross !== undefined &&
    //   cashflowInput.gasGross !== undefined
    // ) {
    //   throw new Error(`The cashflow input object must contain one major phase.  The non-major
    //     phase should be set to undefined; the major phase should contain an array of values.
    //     At the moment, both oilGross and gasGross are defined.
    //     The following oilGross value was provided:  ${cashflowInput.oilGross}
    //     The following gasGross value was provided:  ${cashflowInput.gasGross}`)
    // }
    // if (
    //   cashflowInput.oilGross === undefined &&
    //   cashflowInput.gasGross === undefined
    // ) {
    //   throw new Error(`The cashflow input object must contain one major phase.  The non-major
    //     phase should be set to undefined; the major phase should contain an array of values.
    //     However, both the gasGross and oilGross values are undefined.`)
    // }
    // if (
    //   cashflowInput.gasGross === undefined &&
    //   !Array.isArray(cashflowInput.oilGross)
    // ) {
    //   throw new Error(`The cashflow input object must contain oilGross and gasGross properties that contain
    //     an array of values (if they are not undefined).  Since gasGross is undefined, oilGross must be an array.
    //     The following oilGross value was provided:  ${cashflowInput.oilGross}
    //     The following gasGross value was provided:  ${cashflowInput.gasGross}`)
    // }
    // if (
    //   cashflowInput.oilGross === undefined &&
    //   !Array.isArray(cashflowInput.gasGross)
    // ) {
    //   throw new Error(`The cashflow input object must contain oilGross and gasGross properties that contain
    //     an array of values (if they are not undefined).  Since oilGross is undefined, gasGross must be an array.
    //     The following oilGross value was provided:  ${cashflowInput.oilGross}
    //     The following gasGross value was provided:  ${cashflowInput.gasGross}`)
    // }
    // KTE, 10/1/2022:  The above checks are deprecated.  I now send both oilGross and gasGross as populated
    //   arrays to calcCashflow.  TS will help with the type checking.
    return validateInput([
        'priceBaseOil',
        'priceBaseGas',
        'priceDiffOil',
        'priceDiffGas',
        'nglPricePercent',
        'workingInterestPercent',
        'netRevenueInterestPercent',
        'shrinkPercent',
        'capitalGross',
        'oilGross',
        'gasGross',
        'gasOilRatio',
        'condensateYield',
        'nglYield',
        'adValTaxRatePercent',
        'gasSevTaxRatePercent',
        'gasProdTaxRate',
        'oilSevTaxRatePercent',
        'oilProdTaxRate',
        'nglSevTaxRatePercent',
        'nglProdTaxRate',
        'opexPerWellGross',
        'opexVariableOilGross',
        'opexVariableGasGross',
        'overheadGross',
    ])(cashflowInput);
};
exports.validateCashflowInput = validateCashflowInput;
