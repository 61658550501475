"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcSwansonsMeanForCashflowObject = exports.calcSwansonsMean = void 0;
var objectAddWithDefault_1 = require("./objectAddWithDefault");
var objectMultiplyScalar_1 = require("./objectMultiplyScalar");
var R = __importStar(require("ramda"));
/**
 * Returns the Swanson's mean, which is equal to p90 * 0.3 + p50 * 0.4 + p10 * 0.3
 GO-DO, KTE, 8/28/2020:  Fix bug:  If array input provided, I need to map through the longest
                         array.  Right now, I map over the P10 array, which may not be the longest.
                         Or, just do not handle arrays.  I don't use the fn for arrays at the moment.

                         Update, KTE, 8/25/2022:  I'm converting to TS.  I don't see any cases where
                         I use this function for arrays.  So, the TS version will only accept/handle
                         scalar numbers, not arrays.
 *
 * @param {Number} p90 low P90 estimate in a lognormal distribution
 * @param {Number} p50 median P50 estimate in a lognormal distribution
 * @param {Number} p10 high P10 estimate in a lognormal distribution
 * @returns {Number} The calculated Swanson's mean
 * @example
 *
 *   calcSwansonMean(10, 100, 1000);  //=> 343
 */
var calcSwansonsMean = function (p90, p50, p10) {
    return p90 * 0.3 + p50 * 0.4 + p10 * 0.3;
};
exports.calcSwansonsMean = calcSwansonsMean;
// {  old deprecated code...
//   const isArrayProvided = Array.isArray(p90)
//   const p90Array = Array.isArray(p90) ? p90 : [p90]
//   const p50Array = Array.isArray(p50) ? p50 : [p50]
//   const p10Array = Array.isArray(p10) ? p10 : [p10]
//   const result = mapIndexed(
//     (p10, i) => p90Array[i] * 0.3 + p50Array[i] * 0.4 + p10 * 0.3
//   )(p10Array)
//   return isArrayProvided ? result : result[0]
// }
/**
 * Conventions for mean calcuations...
 *  - Production (both major and minor phases) is averaged monthly.
 *  - Zero production values are included in the monthly average calculations.
 *  - NGL yield is NOT averaged monthly.  In contrast, the yield input scalar is averaged, and that average value is applied to gas per month.
 *  -   Go-DO, KTE, 3/28/2020:  Study NGL yield average in Heconomics.  It's not quite the simple Swanson's mean...
 *  - Note that GOR (or condensate yield) is handled differently than NGL yield.  The GOR (or condensate yield) is used to calculate monthly
 *    non-major phase production forecasts, and these monthly arrays are averaged.
 *  - Opex fixed per well mean value is calculated from input scalars.  The mean scalar value is
 *    applied to the production array to calculate monthly opex.  UPDATE:  This statement is wrong.  I calc the opex mean monthly.
 *  - Zero fixed per well opex values, therefore, are NOT included in the monthly average calculations.
 *    In contrast, the opex for the mean case always equals the scalar mean value.
 *
 * KTE, 8/26/2022:  Renamed to objectMultiplyScalar, from objectMultiplyScalarWithDefault
 *  -
 * @param {ObjectOfNumericArrays} P90Cashflow
 * @param {ObjectOfNumericArrays} P50Cashflow
 * @param {ObjectOfNumericArrays} P10Cashflow
 */
var calcSwansonsMeanForCashflowObject = function (p90Cashflow, p50Cashflow, p10Cashflow) {
    // Return a new cashflow object.  Each array in the object is calculated via the Swansons mean of each respective P90/50/10 value.
    return (R.compose((0, objectAddWithDefault_1.objectAddWithDefault)(0)((0, objectMultiplyScalar_1.objectMultiplyScalar)(0.3, p10Cashflow)), (0, objectAddWithDefault_1.objectAddWithDefault)(0)((0, objectMultiplyScalar_1.objectMultiplyScalar)(0.4, p50Cashflow)))((0, objectMultiplyScalar_1.objectMultiplyScalar)(0.3, p90Cashflow)));
};
exports.calcSwansonsMeanForCashflowObject = calcSwansonsMeanForCashflowObject;
