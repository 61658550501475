"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcRateExponential = void 0;
function calcRateExponential(initialRate, nominalDeclineRate, month) {
    if (Array.isArray(month)) {
        return month.map(function (oneMonth) { return calcRateExponential(initialRate, nominalDeclineRate, oneMonth); });
    }
    return initialRate * Math.exp((-1 * nominalDeclineRate * month) / 12);
}
exports.calcRateExponential = calcRateExponential;
