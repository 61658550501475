"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectAddWithDefault = void 0;
var vOperatorWithDefault_1 = require("./vOperatorWithDefault");
var R = __importStar(require("ramda"));
/**
 * @see objectSubtractWithDefault
 */
function objectAddWithDefault(defaultValue) {
    return function (objectA) {
        return function (objectB) {
            return R.mapObjIndexed(function (valueB, key) {
                return (0, vOperatorWithDefault_1.vOperatorWithDefault)(R.add)(defaultValue)(objectA[key])(valueB);
            }, objectB);
        };
    };
}
exports.objectAddWithDefault = objectAddWithDefault;
// KTE, 8/29/2022:  I've been experimenting with various currying and TS schemes...
//
// export function objectAddWithDefault<T extends ObjectOfNumericArrays, U extends ObjectOfNumericArrays>(defaultValue: number) {
//   return function(objectA: T & { [Property in keyof U]: number[] }) {
//     return function(objectB: U & { [Property in keyof T]: number[] }) {
//       return R.mapObjIndexed(
//         (valueB, key) =>
//         vOperatorWithDefault(R.add)(defaultValue)(objectA[key])(valueB),
//         objectB
//       )
//     }
//   }
// }
//
//
// export function objectAddWithDefault<T extends ObjectOfNumericArrays, U extends T & {[Key in keyof T]: number[]}>(defaultValue: number) {
//   return function(objectA: T) {
//     return function(objectB: U) {
//       return R.mapObjIndexed(
//         (valueB, key) =>
//         vOperatorWithDefault(R.add)(defaultValue)(objectA[key])(valueB),
//         objectB
//       )
//     }
//   }
// }
//
// export function objectAddWithDefaultasdf<
//   T extends ObjectOfNumericArrays,
//   U extends ObjectOfNumericArrays
// >(
//   defaultValue: number,
//   objectA: T & { [Property in keyof U]: number[] },
//   objectB: U & { [Property in keyof T]: number[] }
// ) {
//   return R.mapObjIndexed(
//     (valueB, key) =>
//       vOperatorWithDefault(R.add)(defaultValue)(objectA[key])(valueB),
//     objectB
//   )
// }
//
// export const num2 = R.curry(objectAddWithDefaultasdf)
