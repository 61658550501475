import {combineReducers} from 'redux'

import {PRODUCTION_FORECAST_METHODS} from '../utils'

const projectInputsReducer = (
  state = {
    major: 'Oil',
    // workingInterestPercent: 90,
    // netRevenueInterestPercent: 80,
    shrinkPercent: 90,
    //sevTaxRatePercent: 0,
    adValTaxRatePercent: '',
    gasSevTaxRatePercent: 0,
    gasProdTaxRate: 0,
    oilSevTaxRatePercent: 0,
    oilProdTaxRate: 0,
    nglSevTaxRatePercent: 0,
    nglProdTaxRate: 0,
    // successPs: 90,
    type: "Swanson's Rule",
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_PROJECT_INPUTS':
      return {...state, [action.payload.input]: action.payload.value}
    case 'OVERWRITE_PROJECT_INPUTS':
      return action.payload
    case 'CLEAR_PROJECT_INPUTS':
      return {
        major: 'Oil',
        successPs: '',
        projectName: '',
        description: '',
        formation: '',
        well: '',
        field: 'Select Field',
        state: 'Select State',
        workingInterestPercent: '',
        netRevenueInterestPercent: '',
        shrinkPercent: '',
        sevTaxRatePercent: 0,
        adValTaxRatePercent: '',
        priceDiffGas: '',
        priceDiffOil: '',
        nglPricePercent: '',
      }
    default:
      return state
  }
}

const pricingInputsReducer = (
  state = {
    Gas: [],
    Oil: [],
    PriceName: 'Select price type',
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_PRICE_NAME':
      return {
        ...state,
        PriceName: action.payload,
      }
    // GoDo, KTE, 9/21/2021:  Remove the following after confirming that group is not used.
    // case 'UPDATE_PRICING_INPUTS':
    //   return {
    //     ...state,
    //     [action.payload.name]: [
    //       ...state[action.payload.name].map((item, index) =>
    //         index === action.payload.group ? action.payload.value : item,
    //       ),
    //     ],
    //   }
    case 'UPDATE_PRICING_INPUTS_BULK':
      return {
        ...state,
        ...action.payload,
      }
    case 'UPDATE_CUSTOM_PRICES_BULK':
      return {
        ...state,
        ...action.payload,
      }
    case 'OVERWRITE_PRICING_INPUTS':
      return {...state, ...action.payload}
    case 'DELETE_PRICING_INPUT':
      if (state[action.payload]) {
        let newState = state
        delete newState[action.payload]
        return newState
      } else {
        return state
      }
    case 'CLEAR_PRICING_INPUTS':
      return {
        ...state,
        Gas: state.Gas.map(() => 0),
        Oil: state.Oil.map(() => 0),
      }
    default:
      return state
  }
}

const navigationReducer = (
  state = {
    page: 'Case Data',
    subPage: 'Cases',
    tab: '',
    navigationArray: ['Cases', 'Additional Investments'],
  },
  action,
) => {
  switch (action.type) {
    case 'CHANGE_PAGE':
      return {...state, page: action.payload}
    case 'CHANGE_SUBPAGE':
      return {...state, subPage: action.payload}
    case 'CHANGE_TAB':
      return {...state, tab: action.payload}
    case 'CHANGE_ARRAY':
      return {...state, navigationArray: action.payload}
    default:
      return state
  }
}

const casesReducer = (
  state = {
    p90: {prodForecastMethod: PRODUCTION_FORECAST_METHODS[0], capitalGross: []},
    p50: {prodForecastMethod: PRODUCTION_FORECAST_METHODS[0], capitalGross: []},
    p10: {prodForecastMethod: PRODUCTION_FORECAST_METHODS[0], capitalGross: []},
    failure: {
      prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
      capitalGross: [],
    },
    existing: {
      prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
      capitalGross: [],
    },
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_VALUES':
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          [action.payload.input]: action.payload.value,
        },
      }
    case 'OVERWRITE_PROJECT_CASES':
      return {
        p90: {...state.p90, ...action.payload.p90},
        p50: {...state.p50, ...action.payload.p50},
        p10: {...state.p10, ...action.payload.p10},
        failure: {...state.failure, ...action.payload.failure},
        existing: {...state.existing, ...action.payload.existing},
      }
    case 'LOAD_NEW_PROJECT_VALUES':
      return {
        p90: {
          prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
          capitalGross: [],
        },
        p50: {
          prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
          capitalGross: [],
        },
        p10: {
          prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
          capitalGross: [],
        },
        failure: {
          prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
          capitalGross: [],
        },
        existing: {
          prodForecastMethod: PRODUCTION_FORECAST_METHODS[0],
          capitalGross: [],
        },
      }
    default:
      return state
  }
}

const resultsReducer = (
  state = {
    p90: {economicMetrics: {}},
    p50: {economicMetrics: {}},
    p10: {economicMetrics: {}},
    mean: {economicMetrics: {}},
    failure: {economicMetrics: {}},
    risked: {economicMetrics: {}},
    incrementalRisked: {cashflow: {}, economicMetrics: {}},
    incrementalUnrisked: {economicMetrics: {}},
  },
  action,
) => {
  switch (action.type) {
    case 'CALC_ALL_CASES':
      return action.payload
    case 'OVERWRITE_PROJECT_RESULTS':
      return {...action.payload}
    case 'CLEAR_RESULTS':
      return {
        p90: {economicMetrics: {}},
        p50: {economicMetrics: {}},
        p10: {economicMetrics: {}},
        mean: {economicMetrics: {}},
        failure: {economicMetrics: {}},
        risked: {economicMetrics: {}},
        incrementalRisked: {cashflow: {}, economicMetrics: {}},
        incrementalUnrisked: {economicMetrics: {}},
      }
    default:
      return state
  }
}

const projectIdsReducer = (state = null, action) => {
  switch (action.type) {
    case 'LOAD_PROJECT_IDS':
      return action.payload
    case 'DELETE_PROJECT_ID':
      return state.filter(projectId => {
        if (
          projectId.Owner === action.payload.Owner &&
          projectId.ProjectName === action.payload.ProjectName
        ) {
          return false
        } else {
          return true
        }
      })
    case 'SAVE_PROJECT_ID':
      return [
        ...state.filter(projectId => {
          if (
            projectId.Owner === action.payload.Owner &&
            projectId.ProjectName === action.payload.ProjectName
          ) {
            return false
          } else {
            return true
          }
        }),
        action.payload,
      ]

    default:
      return state
  }
}

const projectsReducer = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_PROJECTS':
      return action.payload
    case 'SAVE_PROJECT':
      return [
        ...state.filter(
          project => project.ProjectName !== action.payload.ProjectName,
        ),
        action.payload,
      ]
    case 'MAKE_PUBLIC':
      return action.payload
    case 'DELETE_PROJECT':
      return state.filter(project => {
        if (
          project.ProjectName === action.payload.ProjectName &&
          project.Owner === action.payload.Owner
        ) {
          return false
        }
        return true
      })
    default:
      return state
  }
}

const defaultsReducer = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_DEFAULTS':
      return action.payload
    default:
      return state
  }
}

const pricesReducer = (state = [], action) => {
  switch (action.type) {
    case 'DELETE_PRICE_NAME':
      return [...state.filter(item => item.PriceName !== action.payload)]
    case 'LOAD_PRICES':
      return [
        ...state.filter(item => item.PriceName !== action.payload.PriceName),
        action.payload,
      ]
    case 'FETCH_PRICES':
      return action.payload
    case 'SUBMIT_PRICE_ROW':
      return [...state, action.payload]
    default:
      return state
  }
}

const taxesReducer = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_TAXES':
      return action.payload
    default:
      return state
  }
}

const capitalInvestmentsReducer = (
  state = {
    p90: [],
    p50: [],
    p10: [],
    failure: [],
    existing: [],
  },
  action,
) => {
  switch (action.type) {
    case 'CLEAR_CAPITAL':
      return {
        p90: [],
        p50: [],
        p10: [],
        failure: [],
        existing: [],
      }
    case 'UPDATE_CAPITAL':
      return {
        ...state,
        [action.payload.group]: action.payload.array,
      }
    case 'ADD_CAPITAL_ROW':
      return {
        ...state,
        [action.payload.caseItem]: [
          ...state[action.payload.caseItem],
          action.payload.newInvestmentObject,
        ],
      }
    case 'REMOVE_CAPITAL_ROW':
      return {
        ...state,
        [action.payload.caseItem]:
          state[action.payload.caseItem].length === 1
            ? []
            : state[action.payload.caseItem].filter(
                (item, index) => index !== action.payload.index,
              ),
      }
    case 'LOAD_CAPITAL':
      return {
        p90: [
          ...action.payload?.p90?.capitalGross
            ?.map((item, index) => ({
              Month: index + 1,
              Investment: item,
            }))
            .filter(
              item =>
                item.Investment !== 0 &&
                item.Investment !== '0' &&
                item.Month !== 1,
            ),
        ],
        p50: [
          ...action.payload?.p50?.capitalGross
            ?.map((item, index) => ({
              Month: index + 1,
              Investment: item,
            }))
            .filter(
              item =>
                item.Investment !== 0 &&
                item.Investment !== '0' &&
                item.Month !== 1,
            ),
        ],
        p10: [
          ...action.payload.p10?.capitalGross
            .map((item, index) => ({
              Month: index + 1,
              Investment: item,
            }))
            .filter(
              item =>
                item.Investment !== 0 &&
                item.Investment !== '0' &&
                item.Month !== 1,
            ),
        ],
        failure: [
          ...action.payload?.failure?.capitalGross
            ?.map((item, index) => ({
              Month: index + 1,
              Investment: item,
            }))
            .filter(
              item =>
                item.Investment !== 0 &&
                item.Investment !== '0' &&
                item.Month !== 1,
            ),
        ],
        existing: [
          ...action.payload?.existing?.capitalGross
            ?.map((item, index) => ({
              Month: index + 1,
              Investment: item,
            }))
            .filter(
              item =>
                item.Investment !== 0 &&
                item.Investment !== '0' &&
                item.Month !== 1,
            ),
        ],
      }
    default:
      return state
  }
}

const errorsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_ERROR':
      return {
        ...state,
        [action.payload.group]: {
          message: action.payload.message,
          title: action.payload.title,
          show: action.payload.show,
        },
      }
    case 'CLOSE_DIALOG':
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          show: false,
        },
      }
    default:
      return state
  }
}

const inputErrorsReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_VALIDATION_ERRORS':
      return [
        ...state.filter(
          item =>
            !action.payload.find(
              payloadItem => payloadItem.errorKey === item.errorKey,
            ),
        ),
        ...action.payload,
      ]
    case 'ADD_INPUT_ERROR':
      return [
        ...state.filter(item => {
          if (
            item.errorLocation === action.payload.errorLocation &&
            item.errorKey === action.payload.errorKey
          ) {
            return false
          }
          return true
        }),
        action.payload,
      ]
    case 'REMOVE_INPUT_ERROR':
      return [
        ...state.filter(
          item =>
            item.errorLocation === 'cases' ||
            (item.errorLocation === 'projectInputs' &&
              item.errorKey !== action.payload),
        ),
      ]
    case 'ADD_CASE_INPUT_ERROR':
      return [
        ...state.filter(item => {
          if (
            item.errorLocation === action.payload.errorLocation &&
            item.errorCase === action.payload.errorCase &&
            item.errorKey === action.payload.errorKey
          ) {
            return false
          }
          return true
        }),
        action.payload,
      ]
    case 'REMOVE_CASE_INPUT_ERROR':
      return [
        ...state.filter(
          item =>
            item.errorLocation === 'projectInputs' ||
            (item.errorLocation === 'cases' &&
              !(
                item.errorCase === action.payload.group &&
                item.errorKey === action.payload.input
              )),
        ),
      ]
    default:
      return state
  }
}

const statusReducer = (
  state = {
    calcExecuted: false,
    calcComplete: true,
    editTaxes: false,
    isLoadingProjectDetails: false,
    isLoadingProjectIds: false,
    getProjectsComplete: false,
    loadingPriceForecast: false,
    loggedIn: false,
    loginCheckComplete: false,
    priceFilter: '',
    showAddPriceRow: false,
    showAdmin: false,
    showCalcSuccess: false,
    showCustomForecast: false,
    customForecastCase: '',
    showNewProjectsModal: false,
    isShowingProjectsModal: false,
    isShowingOPEXModal: false,
    showSaveSuccess: false,
    isOffline: false,
    validBrowser: true,
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_CALC_STATUS':
      return {...state, calcComplete: !state.calcComplete, calcExecuted: true}
    case 'UPDATING_FORECASTS':
      return {...state, loadingPriceForecast: !state.loadingPriceForecast}
    case 'UPDATE_PRICE_FILTER':
      return {...state, priceFilter: action.payload}
    case 'TOGGLE_LOGIN':
      return {...state, loggedIn: action.payload}
    case 'TOGGLE_ADMIN':
      return {...state, showAdmin: !state.showAdmin}
    case 'TOGGLE_ADD_PRICE_ROW':
      return {...state, showAddPriceRow: !state.showAddPriceRow}
    case 'TOGGLE_TAXES_EDIT':
      return {...state, editTaxes: !state.editTaxes}
    case 'LOGIN_CHECK_COMPLETED':
      return {...state, loginCheckComplete: true}
    case 'IS_SHOWING_PROJECTS_MODAL':
      return {...state, isShowingProjectsModal: action.payload}
    case 'IS_SHOWING_OPEX_MODAL':
      return {...state, isShowingOPEXModal: action.payload}
    case 'IS_GETTING_PROJECT_IDS':
      return {...state, isGettingProjectIds: action.payload}
    case 'TOGGLE_NEW_PROJECTS':
      return {...state, showNewProjectsModal: !state.showNewProjectsModal}
    case 'CLEAR_CALC_EXECUTION':
      return {...state, calcExecuted: false}
    case 'TOGGLE_CALC_SUCCESS':
      return {...state, showCalcSuccess: !state.showCalcSuccess}
    case 'TOGGLE_SAVE_SUCCESS':
      return {...state, showSaveSuccess: !state.showSaveSuccess}
    case 'IS_LOADING_PROJECT_DETAILS':
      return {...state, isLoadingProjectDetails: action.payload}
    case 'LOADING_PROJECT_DETAILS_MESSAGE':
      return {...state, loadingProjectDetailsMessage: action.payload}
    case 'IS_LOADING_DATA_FROM_CLOUD':
      return {...state, isLoadingDataFromCloud: action.payload}
    case 'LOADING_DATA_FROM_CLOUD_MESSAGE':
      return {...state, loadingDataFromCloudMessage: action.payload}
    case 'COMPLETE_PROJECTS_LOAD':
      return {...state, getProjectsComplete: true}
    case 'GO_OFFLINE':
      return {...state, isOffline: true}
    case 'GO_ONLINE':
      return {...state, isOffline: false}
    case 'SHOW_CUSTOM_FORECAST':
      return {...state, showCustomForecast: action.payload}
    case 'SET_CUSTOM_FORECAST_CASE':
      return {...state, customForecastCase: action.payload}
    case 'UPDATE_BROWSER_VALIDITY':
      return {...state, validBrowser: action.payload}
    default:
      return state
  }
}

const userReducer = (
  state = {
    name: '',
    userName: '',
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return action.payload
    default:
      return state
  }
}

const settingsReducer = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_SETTINGS':
      return action.payload
    case 'UPDATE_SETTINGS':
      return state.map(item => {
        if (item.User === action.payload.user) {
          return {...item, [action.payload.key]: action.payload.value}
        }
        return item
      })
    default:
      return state
  }
}

const tornadoReducer = (state = [], action) => {
  switch (action.type) {
    case 'CALC_TORNADO':
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  navigation: navigationReducer,
  projectInputs: projectInputsReducer,
  pricingInputs: pricingInputsReducer,
  cases: casesReducer,
  status: statusReducer,
  inputErrors: inputErrorsReducer,
  results: resultsReducer,
  capitalInvestments: capitalInvestmentsReducer,
  projectIds: projectIdsReducer,
  projects: projectsReducer,
  errors: errorsReducer,
  user: userReducer,
  defaults: defaultsReducer,
  taxes: taxesReducer,
  prices: pricesReducer,
  settings: settingsReducer,
  tornado: tornadoReducer,
})
