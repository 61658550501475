"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcEconomicMetrics = exports.countLeadingZeros = exports.calcAnnualEffectiveDeclinePercentSecantMethod = exports.readEconomicLife = exports.calcInitialDailyRateFromMonthly = exports.getInitialNonZeroValue = exports.calcInitialDailyRate = exports.calcLastDailyRateFromMonthly = exports.calcLastDailyRate = exports.getLastNonZeroValue = exports.convMcfToMmcf = exports.convMcfToBcf = exports.convBoToMbo = exports.estimateROR = exports.calcPresentValue = exports.calcPVR = exports.calcPayout = exports.calcDollarsPerBOE = void 0;
var R = __importStar(require("ramda"));
var round_1 = require("./round");
var cumSum_1 = require("./cumSum");
var vOperator_1 = require("./vOperator");
var calcDollarsPerBOE = function (capitalNet, boeNet) {
    return capitalNet / boeNet;
};
exports.calcDollarsPerBOE = calcDollarsPerBOE;
/**
 * Returns the index that represents the point at which cumulative cashflow becomes positive
 * for the first time.
 *
 * Returns -1 if there is no payout, or if the input is null/undefined/not an array.
 *
 * @param {number[]} monthlyNetIncome Monthly net income values (not arranged in cumulative sum format, just raw monthly values)
 * @sig [netIncome] -> index
 */
var calcPayout = function (monthlyNetIncome) {
    if (!monthlyNetIncome)
        return -1;
    if (!Array.isArray(monthlyNetIncome))
        return -1;
    return R.compose(R.findIndex(function (monthlyNetIncome) { return monthlyNetIncome > 0; }), cumSum_1.cumSum)(monthlyNetIncome);
    // Go-Do, KTE, 9/15/2020:  update to calc relative to first month of capital spending (i.e., handle delay start date)
};
exports.calcPayout = calcPayout;
var calcPVR = function (pv, capitalDiscounted) {
    return (pv + capitalDiscounted) / capitalDiscounted;
};
exports.calcPVR = calcPVR;
function calcPresentValue(discountRatePercent, year, undiscountedValue) {
    if (Array.isArray(year) && Array.isArray(undiscountedValue))
        return (0, vOperator_1.vOperator)(calcPresentValueCurryDiscountRate(discountRatePercent))(year)(undiscountedValue);
    if (!Array.isArray(year) && !Array.isArray(undiscountedValue))
        return calcPresentValueCurryDiscountRate(discountRatePercent)(year, undiscountedValue);
    throw new Error('Invalid input.  year and undiscountedValue must be both arrays or scalar values, but not a mix of the two');
}
exports.calcPresentValue = calcPresentValue;
function calcPresentValueCurryDiscountRate(discountRatePercent) {
    return function (year, undiscountedValue) {
        return undiscountedValue / Math.pow((1 + discountRatePercent / 100), year);
    };
}
function estimateROR(netIncome, year, dr1, dr2, pv1, pv2, iteration) {
    if (dr1 === void 0) { dr1 = 0; }
    if (dr2 === void 0) { dr2 = 50; }
    if (pv1 === void 0) { pv1 = undefined; }
    if (pv2 === void 0) { pv2 = undefined; }
    if (iteration === void 0) { iteration = 1; }
    if (!netIncome || !year) {
        console.log('Returning undefined in estimateROR, because netIncome or year are not truthy.');
        return undefined;
    }
    // const DISCOUNT_RATE_INITIAL_GUESS = 50; // where should I put this constant?
    var ERROR_TOLERANCE_DOLLARS = 0.01; // represents a dollar amount of PV at the certain discount rate.  It should be close to zero.
    var MAX_ALLOWABLE_ITERATIONS = 20;
    pv1 = pv1 || R.sum(calcPresentValue(dr1, year, netIncome));
    if (dr1 === 0 && pv1 < 0) {
        // console.log(
        //   'Returning undefined in estimateROR, because undiscounted PV is less than zero.'
        // )
        return undefined; // I don't calculate the ROR for projects w/ negative cumulative netIncome
    }
    pv2 = pv2 || R.sum(calcPresentValue(dr2, year, netIncome));
    var slope = calcSlope(dr1, pv1, dr2, pv2);
    var rorEstimate = calcLinearRoot(slope, dr1, pv1);
    var pvAtEstimate = R.sum(calcPresentValue(rorEstimate, year, netIncome));
    // console.log(`in estimateROR,
    //  iteration:  ${iteration},
    //  rorEstimate:  ${rorEstimate},
    //  pvAtEstimate:  ${pvAtEstimate},
    //  dr1:  ${dr1},
    //  pv1:  ${pv1},
    //  dr2:  ${dr2},
    //  pv2:  ${pv2},
    //  year:  ${year},
    //  netIncome:  ${netIncome}`)
    // if (calcErrorPercent(pv2, pvAtEstimate) < ERROR_TOLERANCE_PERCENT) { // KTE, 9/15/2020:  Changed the tolerance check to a raw PV value, since I observed failure to converge w/ the tolerance check.
    if (Math.abs(pvAtEstimate) < ERROR_TOLERANCE_DOLLARS) {
        return returnNumberOrGtString(rorEstimate);
    }
    if (iteration < MAX_ALLOWABLE_ITERATIONS) {
        return estimateROR(netIncome, year, dr2, rorEstimate, pv2, pvAtEstimate, iteration + 1);
    }
    return returnUndefinedOrGtString(rorEstimate);
}
exports.estimateROR = estimateROR;
var returnNumberOrGtString = function (rorEstimate) {
    return rorEstimate > 300 ? '>300%' : rorEstimate;
};
var returnUndefinedOrGtString = function (rorEstimate) {
    return rorEstimate > 300 ? '>300%' : undefined;
};
// const calcErrorPercent = (x1: number, x2: number) => Math.abs(((x1 - x2) / x1) * 100)
var calcSlope = function (x1, y1, x2, y2) {
    return (y2 - y1) / (x2 - x1);
};
var calcLinearRoot = function (slope, x1, y1) {
    return (slope * x1 - y1) / slope;
};
var convBoToMbo = function (bo) { return bo / 1000; };
exports.convBoToMbo = convBoToMbo;
var convMcfToBcf = function (mcf) { return mcf / 1000000; };
exports.convMcfToBcf = convMcfToBcf;
var convMcfToMmcf = function (mcf) { return mcf / 1000; };
exports.convMcfToMmcf = convMcfToMmcf;
/**
 * Returns the last non-zero value in the provided array.
 *
 * If no non-zero values exist, then zero is returned.
 *
 * Note that the Ramda findLast function returns undefined if no values are found.  However, in the context of
 * economic analysis, I typically desire zero over undefined.
 *
 * @sig [values] -> lastNonZeroValue | 0
 */
exports.getLastNonZeroValue = R.compose(R.ifElse(function (x) { return x === undefined; }, function (_x) { return 0; }, function (x) { return x; }), // change value to zero (not undefined) if no positive volumes found
R.findLast(function (x) { return x != 0; }));
/**
 * Returns the last non-zero element of the array, divided by the passed-in divisor.
 *
 * If the array only contains zeros, then zero is returned.
 *
 * @func
 * @sig (divisorVolumeToDaily, [volumeArray]) -> dailyRate
 * @param {Number} divisorVolumeToDaily The divisor used to convert the volume to daily rate.
 * If the volume array represents monthly amounts, divisorVolumeToDaily should equal (365/12)
 * @returns {Number[]} The array of volumes.
 * @example
 *
 *   calcLastDailyRate(365/12, [0, 0, 0, 3000, 2900, 2800, 2700, 0, 0]);  //=> 88.77
 */
var calcLastDailyRate = function (divisorVolumeToDaily) { return function (volumeArray) {
    return R.compose(R.divide(R.__, divisorVolumeToDaily), exports.getLastNonZeroValue)(volumeArray);
}; };
exports.calcLastDailyRate = calcLastDailyRate;
/**
 * Partially applied version of calcLastDailyRate, prepared to find the last daily rate
 * from an array of monthly volumes.
 *
 * @sig [monthlyVolumes] -> dailyRate
 */
exports.calcLastDailyRateFromMonthly = (0, exports.calcLastDailyRate)(365 / 12);
/**
 * Returns the first non-zero element of the array, divided by the passed-in divisor.
 *
 * If the array only contains zeros or is empty/null/undefined, then zero is returned.
 *
 * @func
 * @sig (divisorVolumeToDaily, [volumeArray]) -> dailyRate
 * @param {Number} divisorVolumeToDaily The divisor used to convert the volume to daily rate.
 * If the volume array represents monthly amounts, divisorVolumeToDaily should equal (365/12)
 * @returns {Number[]} The array of volumes.
 * @example
 *
 *   calcInitialDailyRate(365/12, [0, 0, 0, 3000, 2900, 2800]);  //=> 98.63
 */
var calcInitialDailyRate = function (divisorVolumeToDaily) { return function (volumeArray) {
    if (!volumeArray)
        return 0;
    return R.compose(R.divide(R.__, divisorVolumeToDaily), exports.getInitialNonZeroValue)(volumeArray);
}; };
exports.calcInitialDailyRate = calcInitialDailyRate;
/**
 * Returns the first non-zero value in the provided array.
 *
 * If no non-zero values exist, then zero is returned.
 *
 * Note that the Ramda find function returns undefined if no values are found.  However, in the context of
 * economic analysis, I typically desire zero over undefined.
 *
 * @sig [values] -> firstNonZeroValue | 0
 */
var getInitialNonZeroValue = function (x) {
    if (!x)
        return 0;
    return R.compose(R.ifElse(function (x) { return x === undefined; }, function (_x) { return 0; }, function (x) { return x; }), // change value to zero (not undefined) if no positive volumes found
    R.find(function (x) { return x != 0; }))(x);
};
exports.getInitialNonZeroValue = getInitialNonZeroValue;
/**
 * Partially applied version of calcInitialDailyRate, prepared to find the initial daily rate
 * from an array of monthly volumes.
 *
 * @sig [monthlyVolumes] -> dailyRate
 */
exports.calcInitialDailyRateFromMonthly = (0, exports.calcInitialDailyRate)(365 / 12);
/**
 * The yearNumber array of a cashflow object is expected.  This array contains numbers
 * for each month of the economic life of the project, then reverts zeros to maxAnalysis years.
 *
 * I add 1/12 to the result, to convert the number into a more normal-feeling answer.  For example,
 * if a well lasts until month 12, then one year is expected.  However, in our zero-based indexing,
 * the yearNumber would be 11/12 = 0.9167.  But, 1 is the better answer in this situation.
 *
 * @param {Number[]} yearNumber
 * @sig [yearNumber] -> last non zero year value
 */
var readEconomicLife = function (yearNumber) {
    if (!yearNumber)
        return undefined;
    if (!Array.isArray(yearNumber))
        return undefined;
    var econLifeBeforeCorrection = R.findLast(function (year) { return year != 0; }, yearNumber);
    return econLifeBeforeCorrection === undefined
        ? undefined
        : R.add(1 / 12, econLifeBeforeCorrection);
};
exports.readEconomicLife = readEconomicLife;
/**
 * Returns the annual effective decline rate, as calculated by the secant method.  This method
 * simply uses in initial rate and 1 year rates, as shown:  De = (q_initial - q_1_year) / q_initial
 *
 * @param {Number[]}  volumeArray The major phase volume array
 * @returns {Number} The annual effective decline rate.
 */
var calcAnnualEffectiveDeclinePercentSecantMethod = function (volumeArray) {
    if (!Array.isArray(volumeArray))
        return 'NA';
    var firstNonZeroValueIndex = R.findIndex(function (x) { return x !== 0; }, volumeArray);
    if (firstNonZeroValueIndex === -1)
        return 'NA';
    var volumeAtTwelveMonths = volumeArray[firstNonZeroValueIndex + 12];
    if (volumeAtTwelveMonths === undefined || volumeAtTwelveMonths === 0)
        return 'NA';
    return (((volumeArray[firstNonZeroValueIndex] - volumeAtTwelveMonths) /
        volumeArray[firstNonZeroValueIndex]) *
        100);
};
exports.calcAnnualEffectiveDeclinePercentSecantMethod = calcAnnualEffectiveDeclinePercentSecantMethod;
var countLeadingZeros = function (x) {
    // if (!x) return undefined
    // if (!Array.isArray(x)) return undefined
    return R.findIndex(function (x) { return x != 0; })(x);
};
exports.countLeadingZeros = countLeadingZeros;
var calcEconomicMetrics = function (major, cashflow, oneline) {
    if (!major || !['Oil', 'oil', 'Gas', 'gas'].includes(major))
        throw new Error("Invalid input.  major must be Oil or Gas.\n      The provided arguments follow: ".concat(major));
    var initialDailyBoeNet = (0, exports.calcInitialDailyRateFromMonthly)(cashflow.boeNet);
    return {
        initialDailyBoeNet: (0, round_1.round0)(initialDailyBoeNet),
        initialDailyOilGross: (0, round_1.round0)((0, exports.calcInitialDailyRateFromMonthly)(cashflow.oilGross)),
        initialDailyGasGross: (0, round_1.round0)((0, exports.calcInitialDailyRateFromMonthly)(cashflow.gasGross)),
        initialDailyNglGross: (0, round_1.round0)((0, exports.calcInitialDailyRateFromMonthly)(cashflow.nglGross)),
        finalDailyOilGross: (0, round_1.round0)((0, exports.calcLastDailyRateFromMonthly)(cashflow.oilGross)),
        finalDailyGasGross: (0, round_1.round0)((0, exports.calcLastDailyRateFromMonthly)(cashflow.gasGross)),
        initialNetOperatingIncome: (0, round_1.round0)((0, exports.getInitialNonZeroValue)(cashflow.netOperatingIncome)),
        pvr0: (0, round_1.round2)((0, exports.calcPVR)(oneline.netIncome, oneline.capitalNet)),
        pvr10: (0, round_1.round2)((0, exports.calcPVR)(oneline.presentValue10, oneline.capitalNetDiscounted10)),
        pvr15: (0, round_1.round2)((0, exports.calcPVR)(oneline.presentValue15, oneline.capitalNetDiscounted15)),
        ror: (0, round_1.round0UndefinedNaCheck)(estimateROR(cashflow.netIncome, cashflow.yearNumber)),
        payout: (0, round_1.round2)((0, exports.calcPayout)(cashflow.netIncome)),
        dollarsPerBOEPD: (0, round_1.round0)(oneline.capitalNet / initialDailyBoeNet),
        dollarsPerBOE: (0, round_1.round2)(oneline.capitalNet / oneline.boeNet),
        economicLifeYears: (0, round_1.round1UndefinedNaCheck)((0, exports.readEconomicLife)(cashflow.yearNumber)),
        gasGrossBcf: (0, round_1.round3)((0, exports.convMcfToBcf)(oneline.gasGross)),
        oilGrossMbo: (0, round_1.round3)((0, exports.convBoToMbo)(oneline.oilGross)),
        boeGrossMboe: (0, round_1.round3)((0, exports.convBoToMbo)(oneline.oilGross + oneline.gasGross / 6 + oneline.nglGross)),
        gasNetBcf: (0, round_1.round3)((0, exports.convMcfToBcf)(oneline.gasNet)),
        oilNetMbo: (0, round_1.round3)((0, exports.convBoToMbo)(oneline.oilNet)),
        boeNetMboe: (0, round_1.round3)((0, exports.convBoToMbo)(oneline.boeNet)),
        // The following properties are provided for the input cases, like P90, P50, etc.
        // I also calculate them here, from the results, to make them available for calculated
        // cases, like incremental risked.
        annualEffectiveDeclineRatePercent: (0, round_1.round1UndefinedNaCheck)((0, exports.calcAnnualEffectiveDeclinePercentSecantMethod)(major === 'Oil' ? cashflow.oilGross : cashflow.gasGross)),
        initialDailyRate: (0, round_1.round0)((0, exports.calcInitialDailyRateFromMonthly)(major === 'Oil' ? cashflow.oilGross : cashflow.gasGross)),
        finalDailyRate: (0, round_1.round0)((0, exports.calcLastDailyRateFromMonthly)(major === 'Oil' ? cashflow.oilGross : cashflow.gasGross)),
        reserves: (0, round_1.round3)(
        // reserves input is in MBO
        major === 'Oil'
            ? (0, exports.convBoToMbo)(oneline.oilGross)
            : (0, exports.convMcfToBcf)(oneline.gasGross)),
        condensateYield: (0, round_1.round1)(oneline.oilGross / (0, exports.convMcfToMmcf)(oneline.gasGross)),
        nglYield: (0, round_1.round1)(oneline.nglGross / (0, exports.convMcfToMmcf)(oneline.gasGross)),
        gasOilRatio: (0, round_1.round1)(oneline.gasGross / oneline.oilGross),
        opexVariableGasGross: (0, round_1.round2)(oneline.opexVariableGasSumGross / oneline.gasGross),
        opexVariableOilGross: (0, round_1.round2)(oneline.opexVariableOilSumGross / oneline.oilGross),
        opexPerWellGross: (0, round_1.round0)((0, exports.getInitialNonZeroValue)(cashflow.opexPerWellGross)),
        overheadGross: (0, round_1.round0)((0, exports.getInitialNonZeroValue)(cashflow.overheadGross)),
        initialOpexTotalGross: (0, round_1.round0)((0, exports.getInitialNonZeroValue)(cashflow.opexTotalGross)),
        overheadNet: (0, round_1.round0)((0, exports.getInitialNonZeroValue)(cashflow.overheadNet)),
        initialOpexOverheadTaxTotalNet: (0, round_1.round0)((0, exports.getInitialNonZeroValue)(cashflow.opexOverheadTaxTotalNet)),
        delayStart: (0, round_1.round0)((0, exports.countLeadingZeros)(cashflow.boeNet)),
    };
};
exports.calcEconomicMetrics = calcEconomicMetrics;
