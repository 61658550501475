"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcRateModifiedArps = void 0;
var calcRateExponential_1 = require("./calcRateExponential");
var calcRateHyperbolic_1 = require("./calcRateHyperbolic");
function calcRateModifiedArps(initialRate, nominalDeclineRate, nominalTerminalDeclineRate, b, month) {
    if (b <= 0) {
        throw new Error("Invalid input. The hyperbolic exponent, b, must be greater than zero.\n      The provided value equals ".concat(b));
    }
    if (nominalDeclineRate <= 0) {
        throw new Error("Invalid input. The initial nominal decline rate, Di, must be greater than zero.\n      The provided value equals ".concat(nominalDeclineRate, "."));
    }
    if (nominalTerminalDeclineRate <= 0) {
        throw new Error("Invalid input. The initial terminal nominal decline rate, Dlim, must be greater than zero.\n      The provided value equals ".concat(nominalTerminalDeclineRate, "."));
    }
    if (nominalDeclineRate < nominalTerminalDeclineRate) {
        throw new Error("Invalid input. The initial nominal decline rate, Di, must be greater than the\n      terminal nominal decline rate, Dlim. Di was provided as ".concat(nominalDeclineRate, " and Dlim was provided as\n      ").concat(nominalTerminalDeclineRate));
    }
    if (Array.isArray(month)) {
        return month.map(function (oneMonth) {
            return calcRateModifiedArps(initialRate, nominalDeclineRate, nominalTerminalDeclineRate, b, oneMonth);
        });
    }
    var tSwitch = ((nominalDeclineRate / nominalTerminalDeclineRate - 1) /
        (b * nominalDeclineRate)) *
        12;
    if (month < tSwitch) {
        return (0, calcRateHyperbolic_1.calcRateHyperbolic)(initialRate, nominalDeclineRate, b, month);
    }
    var qSwitch = (0, calcRateHyperbolic_1.calcRateHyperbolic)(initialRate, nominalDeclineRate, b, tSwitch);
    return (0, calcRateExponential_1.calcRateExponential)(qSwitch, nominalTerminalDeclineRate, month - tSwitch);
}
exports.calcRateModifiedArps = calcRateModifiedArps;
