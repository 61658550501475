"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcEconomicsProbRisked = void 0;
var R = __importStar(require("ramda"));
var arrayUtil_1 = require("./arrayUtil");
var calcEconomicsDeterministic_1 = require("./calcEconomicsDeterministic");
var calcEconomicsIncremental_1 = require("./calcEconomicsIncremental");
var calcEconomicsRisked_1 = require("./calcEconomicsRisked");
var calcEconomicsSwansonsMean_1 = require("./calcEconomicsSwansonsMean");
var findLastIndexNonZero_1 = require("./findLastIndexNonZero");
var prepareCashflowInput_1 = require("./prepareCashflowInput");
var round_1 = require("./round");
var util_1 = require("./util");
var validateInput_1 = require("./validateInput");
var calcEconomicsProbRisked = function (priceBaseOil, priceBaseGas, project, _a) {
    var p90 = _a.p90, p50 = _a.p50, p10 = _a.p10, failure = _a.failure, existing = _a.existing;
    console.log('Running calcEconomicsProbRisked, TS style...  Version 2.0.3');
    // const CONFIG_MAX_YEARS = 50; // Integer expected
    var CONFIG_STANDARD_PROD_SHIFT = 1; // Integer expected
    (0, validateInput_1.validateProjectInput)(project);
    R.forEachObjIndexed(validateInput_1.validateCaseInput, { p90: p90, p50: p50, p10: p10, failure: failure, existing: existing });
    // const priceFieldOil = vAdd(priceBaseOil, project.priceDiffOil);
    // const priceFieldGas = vAdd(priceBaseGas, project.priceDiffGas);
    // const priceFieldNgl = vMultiply(priceBaseOil, project.nglPricePercent / 100);
    // const p90output = calcEconomicsDeterministic(CONFIG_MAX_YEARS, priceFieldOil, priceFieldGas, priceFieldNgl, project, p90, prepareCapitalProductionObject(CONFIG_MAX_YEARS, project, p90));
    console.log('Calculating p90 output.  Here is the case input...');
    console.log(p90);
    var p90output = __assign(__assign({}, (0, calcEconomicsDeterministic_1.calcEconomicsDeterministic)(util_1.CONFIG_MAX_YEARS, project.major, 
    // p90.delayStart,
    (0, prepareCashflowInput_1.prepareCashflowInput)(util_1.CONFIG_MAX_YEARS, CONFIG_STANDARD_PROD_SHIFT, (0, prepareCashflowInput_1.shapeRawCashflowParameters)(priceBaseOil, priceBaseGas, project, p90)))), { project: project, caseInput: p90 });
    // const p50output = calcEconomicsDeterministic(CONFIG_MAX_YEARS, priceFieldOil, priceFieldGas, priceFieldNgl, project, p50, prepareCapitalProductionObject(CONFIG_MAX_YEARS, project, p50));
    var p50output = __assign(__assign({}, (0, calcEconomicsDeterministic_1.calcEconomicsDeterministic)(util_1.CONFIG_MAX_YEARS, project.major, 
    // p50.delayStart,
    (0, prepareCashflowInput_1.prepareCashflowInput)(util_1.CONFIG_MAX_YEARS, CONFIG_STANDARD_PROD_SHIFT, (0, prepareCashflowInput_1.shapeRawCashflowParameters)(priceBaseOil, priceBaseGas, project, p50)))), { project: project, caseInput: p50 });
    // const p10output = calcEconomicsDeterministic(CONFIG_MAX_YEARS, priceFieldOil, priceFieldGas, priceFieldNgl, project, p10, prepareCapitalProductionObject(CONFIG_MAX_YEARS, project, p10));
    var p10output = __assign(__assign({}, (0, calcEconomicsDeterministic_1.calcEconomicsDeterministic)(util_1.CONFIG_MAX_YEARS, project.major, 
    // p10.delayStart,
    (0, prepareCashflowInput_1.prepareCashflowInput)(util_1.CONFIG_MAX_YEARS, CONFIG_STANDARD_PROD_SHIFT, (0, prepareCashflowInput_1.shapeRawCashflowParameters)(priceBaseOil, priceBaseGas, project, p10)))), { project: project, caseInput: p10 });
    var meanOutput = (0, calcEconomicsSwansonsMean_1.calcEconomicsSwansonsMean)(util_1.CONFIG_MAX_YEARS, project.major, p90output.cashflow, p50output.cashflow, p10output.cashflow);
    // const failureOutput = calcEconomicsDeterministic(CONFIG_MAX_YEARS, priceFieldOil, priceFieldGas, priceFieldNgl, project, failure, prepareCapitalProductionObject(CONFIG_MAX_YEARS, project, failure));
    var failureOutput = __assign(__assign({}, (0, calcEconomicsDeterministic_1.calcEconomicsDeterministic)(util_1.CONFIG_MAX_YEARS, project.major, 
    // failure.delayStart,
    (0, prepareCashflowInput_1.prepareCashflowInput)(util_1.CONFIG_MAX_YEARS, CONFIG_STANDARD_PROD_SHIFT, (0, prepareCashflowInput_1.shapeRawCashflowParameters)(priceBaseOil, priceBaseGas, project, failure)))), { project: project, caseInput: failure });
    var riskedOutput = (0, calcEconomicsRisked_1.calcEconomicsRisked)(util_1.CONFIG_MAX_YEARS, project.major, project.successPs, meanOutput.cashflow, failureOutput.cashflow);
    // const existingOutput = calcEconomicsDeterministic(CONFIG_MAX_YEARS, priceFieldOil, priceFieldGas, priceFieldNgl, project, existing, prepareCapitalProductionObject(CONFIG_MAX_YEARS, project, existing));
    var existingOutput = __assign(__assign({}, (0, calcEconomicsDeterministic_1.calcEconomicsDeterministic)(util_1.CONFIG_MAX_YEARS, project.major, 
    // existing.delayStart,
    (0, prepareCashflowInput_1.prepareCashflowInput)(util_1.CONFIG_MAX_YEARS, CONFIG_STANDARD_PROD_SHIFT, (0, prepareCashflowInput_1.shapeRawCashflowParameters)(priceBaseOil, priceBaseGas, project, existing)))), { project: project, caseInput: existing });
    var incrementalRiskedOutput = (0, calcEconomicsIncremental_1.calcEconomicsIncremental)(util_1.CONFIG_MAX_YEARS, project.major, riskedOutput.cashflow, existingOutput.cashflow);
    var incrementalUnriskedOutput = (0, calcEconomicsIncremental_1.calcEconomicsIncremental)(util_1.CONFIG_MAX_YEARS, project.major, meanOutput.cashflow, existingOutput.cashflow);
    // const indexOfLongestCashflowArray = findLastIndexNonZero([
    //   p90output.cashflow.boeNet,
    //   p50output.cashflow.boeNet,
    //   p10output.cashflow.boeNet,
    //   meanOutput.cashflow.boeNet,
    //   failureOutput.cashflow.boeNet,
    //   riskedOutput.cashflow.boeNet,
    //   existingOutput.cashflow.boeNet,
    //   incrementalRiskedOutput.cashflow.boeNet,
    //   incrementalUnriskedOutput.cashflow.boeNet,
    // ])
    return {
        p90: __assign(__assign({}, p90output), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(p90output.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([p90output.cashflow.boeNet])) }),
        p50: __assign(__assign({}, p50output), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(p50output.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([p50output.cashflow.boeNet])) }),
        p10: __assign(__assign({}, p10output), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(p10output.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([p10output.cashflow.boeNet])) }),
        mean: __assign(__assign({}, meanOutput), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(meanOutput.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([meanOutput.cashflow.boeNet])) }),
        failure: __assign(__assign({}, failureOutput), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(failureOutput.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([failureOutput.cashflow.boeNet])) }),
        risked: __assign(__assign({}, riskedOutput), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(riskedOutput.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([riskedOutput.cashflow.boeNet])) }),
        existing: __assign(__assign({}, existingOutput), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(existingOutput.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([existingOutput.cashflow.boeNet])) }),
        incrementalRisked: __assign(__assign({}, incrementalRiskedOutput), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(incrementalRiskedOutput.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([incrementalRiskedOutput.cashflow.boeNet])) }),
        incrementalUnrisked: __assign(__assign({}, incrementalUnriskedOutput), { cashflow: (0, arrayUtil_1.trimObjectOfArraysToIndex)(incrementalUnriskedOutput.cashflow)((0, findLastIndexNonZero_1.findLastIndexNonZero)([incrementalUnriskedOutput.cashflow.boeNet])) }),
        ratioP10P90: (0, round_1.round2)(p10output.economicMetrics.boeGrossMboe /
            p90output.economicMetrics.boeGrossMboe),
    };
};
exports.calcEconomicsProbRisked = calcEconomicsProbRisked;
