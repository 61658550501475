"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDailyPropsForCashflow = exports.createDailyProps = exports.createCumSumPropsForCashflow = exports.createCumSumProps = exports.reduceToOneline = exports.addDailyAndCumSumPropsToCashflow = exports.createResultsObject = exports.removeCumAndDailyProps = exports.DAILY_PROP_NAMES = exports.CUM_SUM_PROP_NAMES = void 0;
var R = __importStar(require("ramda"));
var calcEconomicMetrics_1 = require("./calcEconomicMetrics");
var convertMonthlyToDaily_1 = require("./convertMonthlyToDaily");
var cumSum_1 = require("./cumSum");
var round_1 = require("./round");
exports.CUM_SUM_PROP_NAMES = [
    'capitalGross',
    'capitalNet',
    'oilGross',
    'oilNet',
    'gasGross',
    'gasGrossLessShrink',
    'gasWorkingInterestShare',
    'gasNet',
    'nglGross',
    'nglNet',
    'boeNet',
    'netOperatingIncome',
    'netIncome',
    'presentValue10',
    'presentValue15',
];
exports.DAILY_PROP_NAMES = [
    'oilGross',
    'oilNet',
    'gasGross',
    'gasGrossLessShrink',
    'gasWorkingInterestShare',
    'gasNet',
    'nglGross',
    'nglNet',
    'boeNet',
];
var doesNotContainCumOrDaily = function (_val, key) {
    return !R.test(/Cum|cum|Daily|daily/, typeof key === 'string' ? key : Number(key).toString());
};
exports.removeCumAndDailyProps = R.pickBy(doesNotContainCumOrDaily);
var createResultsObject = function (major, cashflow) {
    var cashflowNoCumNoDaily = (0, exports.removeCumAndDailyProps)(cashflow);
    var oneline = R.map(round_1.round2, (0, exports.reduceToOneline)(cashflowNoCumNoDaily));
    var economicMetrics = (0, calcEconomicMetrics_1.calcEconomicMetrics)(major, cashflowNoCumNoDaily, oneline);
    return {
        cashflow: R.compose(R.map(round_1.round2), exports.addDailyAndCumSumPropsToCashflow)(cashflowNoCumNoDaily),
        oneline: oneline,
        economicMetrics: economicMetrics,
    };
};
exports.createResultsObject = createResultsObject;
var addDailyAndCumSumPropsToCashflow = function (cashflow) { return (__assign(__assign(__assign({}, cashflow), createCumSumPropsForCashflow(cashflow)), createDailyPropsForCashflow(cashflow))); };
exports.addDailyAndCumSumPropsToCashflow = addDailyAndCumSumPropsToCashflow;
exports.reduceToOneline = R.map(R.sum); // eventually update this to handle fields that don't reduce via sum
/**
 * Returns a new object that contains cumulative sum versions of the propertyNames.
 * 'Cum' is appended to the property names.
 */
var createCumSumProps = function (propertyNames) { return function (object) {
    return R.compose((R.fromPairs), R.map(function (pair) { return [pair[0] + 'Cum', pair[1]]; }), (R.toPairs), 
    // R.map(round0(cumSum)), // KTE, 9/27/2022:  I previously rolled round0 and cumSum together, but I broke out today to simplify types
    R.map(round_1.round0), R.map(cumSum_1.cumSum), R.pick(propertyNames))(object);
}; };
exports.createCumSumProps = createCumSumProps;
// export const createCumSumPropsForCashflow = createCumSumProps(CUM_SUM_PROP_NAMES)
function createCumSumPropsForCashflow(cashflow) {
    return (0, exports.createCumSumProps)(exports.CUM_SUM_PROP_NAMES)(cashflow);
}
exports.createCumSumPropsForCashflow = createCumSumPropsForCashflow;
/**
 * Returns a new object that contains daily versions of the propertyNames.
 *
 * 'Daily' is appended to the property names.
 *
 * To convert to daily, the convertyMonthlyToDaily function is used.
 *
 * @author Keith Elliott, 9/13/2020
 */
var createDailyProps = function (propertyNames) { return function (object) {
    return R.compose((R.fromPairs), R.map(function (pair) { return [pair[0] + 'Daily', pair[1]]; }), (R.toPairs), R.map(convertMonthlyToDaily_1.convertMonthlyToDaily), R.pick(propertyNames))(object);
}; };
exports.createDailyProps = createDailyProps;
function createDailyPropsForCashflow(cashflow) {
    return (0, exports.createDailyProps)(exports.DAILY_PROP_NAMES)(cashflow);
}
exports.createDailyPropsForCashflow = createDailyPropsForCashflow;
