"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcNominalDeclineTangentMethod = void 0;
/**
 * Converts an annual effective decline rate to a nominal decline rate
 *
 * @func
 * @param {Number} annualEffectiveDeclineRatePercent
 * @returns {Number} The nominal decline rate, in decimal (not percentage) format
 * @example

 *   calcNominalDeclineTangentMethod(20);  //=> 0.2231
 */
function calcNominalDeclineTangentMethod(annualEffectiveDeclineRatePercent) {
    return -1 * Math.log(1 - annualEffectiveDeclineRatePercent / 100);
}
exports.calcNominalDeclineTangentMethod = calcNominalDeclineTangentMethod;
